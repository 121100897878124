import Api from '..'
import { z } from 'zod'
import type { Job } from './jobs'
import { sequenceParser, sequenceStepParser } from './sequences'
import { candidateJobExpandedParser } from './candidate_jobs'
import { isNil } from 'lodash'

export const manualTaskSerializer = z.object({
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  orgId: z.string().uuid(),
  userId: z.string().uuid(),
  candidateId: z.string().uuid(),
  sequenceId: z.string().uuid(),
  sequenceStepId: z.string().uuid(),
  candidateSequenceId: z.string().uuid(),
  candidateSequenceStepId: z.string().uuid().nullable(),
  subject: z.string().optional(),
  body: z.string().optional(),
  dirty: z.boolean()
})

export type ManualTask = z.infer<typeof manualTaskSerializer>

export async function markManualTaskComplete (
  jobId: Job['id'],
  manualTaskId: ManualTask['id'],
  noteBody?: string | undefined,
  responded?: boolean | undefined
): Promise<ManualTask> {
  noteBody = (!isNil(noteBody) && noteBody.length > 0)
    ? noteBody
    : undefined

  const { data } = await Api.put(`/jobs/${jobId}/manual_task/${manualTaskId}/mark_completed`, null, {
    responded: responded ?? false,
    noteBody
  })

  return manualTaskSerializer.parse(data)
}

export const manualTaskExpandedSerializer = manualTaskSerializer.extend({
  sequence: sequenceParser,
  candidateJob: candidateJobExpandedParser,
  sequenceStep: sequenceStepParser
})

export type ManualTaskExpanded = z.infer<typeof manualTaskExpandedSerializer>
