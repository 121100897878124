import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import * as RadixDropdown from '@radix-ui/react-dropdown-menu'
import { Icon } from '../icon'
import { Spacer } from '../spacer'
import type { IconName } from '../icon'
import type { Color, FontWeight } from 'src/styles/theme/types'
import { isNil } from 'lodash'
import * as S from './dropdown.styled'
import { detectIconType } from 'src/utils/detect-icon-type'
import { Signal } from '../signal'
import { Tooltip } from '../tooltip'
import type { TooltipProps } from '../tooltip'
import { When } from 'src/components/blocks/when'
import { Caption } from '../typography'
import { REGEX_EMAIL } from 'src/libs/regex'

export type Variant = 'neutral' | 'muted' | 'tint' | 'positive' | 'negative' | 'warning' | 'aiSolid'
export type Size = 'normal' | 'small' | 'withSubTitle'
export type ItemLayout = 'inline' | 'block'

interface SharedProps {
  size?: Size
  fontWeight?: FontWeight
  layout?: ItemLayout
  selectType?: 'single' | 'multi'
}

interface SharedMenuItemProps extends SharedProps {
  variant?: Variant
  title: string | React.ReactNode
  subTitle?: string
  icon?: IconName | React.ReactNode
  content?: React.ReactNode
  trailingIcon?: IconName | React.ReactNode | Color
  trailingTooltip?: Omit<TooltipProps, 'children' | 'trigger'> & { text?: string }
  itemTooltip?: Omit<TooltipProps, 'children' | 'trigger'> & { text?: string }
}

interface MenuItemInnerProps extends SharedMenuItemProps {
  isActive?: boolean
}

export interface MenuItemContentProps extends SharedMenuItemProps {
  id?: string
  type?: 'item' | 'label' | 'sublabel' | 'separator' | 'spacer' | 'non-selectable' | 'description' | 'node' | string
  isDisabled?: boolean
  isSelectable?: boolean
  isSelected?: boolean
  href?: string
  target?: string
  onSelect?: () => void | Promise<void>
  closeOnSelect?: boolean
  value?: string
  activeId?: string | string[]
}

export interface MenuItemProps extends MenuItemContentProps {
  subitems?: MenuItemContentProps[]
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void
}

export interface MenuItemLabelProps {
  title: MenuItemContentProps['title']
  trailingIcon?: IconName | React.ReactNode | Color
  onClick?: () => void
}

interface DropdownProps extends SharedProps {
  trigger: React.ReactNode
  items: MenuItemProps[]
  stickyHeader?: MenuItemProps
  stickyItem?: MenuItemProps
  sideOffset?: number
  alignOffset?: number
  menuPosition?: 'start' | 'center' | 'end'
  $menuWidth?: 'auto' | 'full' | number
  $maxHeight?: string
  $minWidth?: string
  $maxWidth?: string
  modal?: boolean
  allowFilter?: boolean
  filterItemsPlaceholder?: string
  allowTopLevelSelect?: boolean
  topLevelSelectText?: string
  onTopLevelSelect?: (selected: string[]) => void
  selectedValue?: string | string[]
  triggerFullWidth?: boolean
  onCloseAutoFocus?: (event: Event) => void
  disabled?: boolean
  side?: 'top' | 'right' | 'bottom' | 'left'
  onContentIsOverflowing?: (isOverflowing: boolean) => void
}

const MenuItemIcon = ({
  variant,
  icon,
  size
}: { variant: Variant, icon: IconName, size: MenuItemContentProps['size'] }): JSX.Element | null => {
  let ICON_COLOR: Color = 'fgPrimary'
  if (variant === 'tint') {
    ICON_COLOR = 'tintBg'
  } else if (variant === 'muted') {
    ICON_COLOR = 'fgSecondary'
  } else if (variant === 'positive') {
    ICON_COLOR = 'positiveBg'
  } else if (variant === 'negative') {
    ICON_COLOR = 'negativeBg'
  } else if (variant === 'warning') {
    ICON_COLOR = 'warningBg'
  } else if (variant === 'aiSolid') {
    ICON_COLOR = 'aiSolidFg'
  }

  return (
    <S.ItemIcon>
      <Icon name={icon} size={size === 'small' ? 12 : 16} color={ICON_COLOR} />
    </S.ItemIcon>
  )
}

const MenuItemText = ({
  variant = 'neutral',
  title,
  subTitle,
  size = 'normal',
  fontWeight,
  isSelected
}: Pick<MenuItemProps, 'variant' | 'title' | 'subTitle' | 'size' | 'fontWeight' | 'isSelected'>): JSX.Element => {
  const subTitleIsEmailAddress = REGEX_EMAIL.test(subTitle ?? '')
  const itemSize = subTitleIsEmailAddress ? size : (!subTitleIsEmailAddress && subTitle) ? 'withSubTitle' : size
  return (
    <S.ItemText $variant={variant} $size={itemSize} $fontWeight={fontWeight} $hasOnlySubTitle={isNil(title)} $isActive={isSelected}>
      {title && <span>{title}</span>}
      {subTitle && <span>{subTitle}</span>}
    </S.ItemText>
  )
}

const renderedIcon = (
  icon: IconName | React.ReactNode,
  variant: Variant,
  size: MenuItemContentProps['size']
): JSX.Element | React.ReactNode => {
  const iconType = detectIconType(icon)

  switch (iconType) {
    case 'Icon': {
      return <MenuItemIcon icon={icon as IconName} variant={variant} size={size} />
    }
    case 'Color': {
      return (
        <S.ItemIcon>
          <Signal $color={icon as Color} />
        </S.ItemIcon>
      )
    }
    default: {
      return icon
    }
  }
}

const MenuItemInner = ({
  variant = 'neutral',
  size = 'normal',
  fontWeight,
  layout,
  title,
  subTitle,
  icon,
  trailingIcon,
  trailingTooltip,
  itemTooltip,
  isActive
}: MenuItemInnerProps): JSX.Element => {
  const subTitleIsEmailAddress = REGEX_EMAIL.test(subTitle ?? '')
  const itemSize =
    subTitleIsEmailAddress
      ? size
      : (!subTitleIsEmailAddress && !isNil(title) && subTitle)
          ? 'withSubTitle'
          : (!subTitleIsEmailAddress && subTitle && isNil(title))
              ? 'small'
              : size

  const menuItem = (
    <S.MenuItemInner $variant={variant} $size={itemSize} $layout={layout} $isActive={isActive}>
      <S.MenuItemInnerContent>
        <S.MenuItemTitle>
          {!isNil(icon) && <>{renderedIcon(icon, variant, size)}</>}
          <MenuItemText variant={variant} title={title} subTitle={subTitle} size={size} fontWeight={fontWeight} isSelected={isActive} />
        </S.MenuItemTitle>
      </S.MenuItemInnerContent>
      {trailingIcon && (
        <Tooltip
          disabled={trailingTooltip?.disabled ?? !trailingTooltip?.text}
          position={trailingTooltip?.position}
          trigger={<S.TrailingIconContainer>{renderedIcon(trailingIcon, variant, size)}</S.TrailingIconContainer>}
        >
          {trailingTooltip?.text}
        </Tooltip>
      )}
    </S.MenuItemInner>
  )
  if (itemTooltip) {
    return (
      <Tooltip
        disabled={!itemTooltip?.text}
        position={itemTooltip?.position}
        align={itemTooltip?.align}
        trigger={menuItem}
      >
        {itemTooltip?.text}
      </Tooltip>
    )
  }
  return menuItem
}

const MenuItemTrigger = ({
  type = 'item',
  icon,
  trailingIcon,
  title,
  subTitle,
  onSelect,
  variant = 'neutral',
  isDisabled = false,
  isSelectable = true,
  isSelected,
  size = 'normal',
  fontWeight,
  layout,
  trailingTooltip,
  itemTooltip,
  closeOnSelect,
  id,
  activeId
}: MenuItemProps): JSX.Element => {
  const handleClick = useCallback((event: Event): void => {
    if (!closeOnSelect) {
      event.preventDefault()
      event.stopPropagation()
    }
    if (onSelect) {
      void onSelect()
    }
  }, [closeOnSelect, onSelect])

  return (
    <S.DropdownMenuItem
      disabled={isDisabled}
      $isSelectable={isSelectable}
      onSelect={handleClick}
      $variant={variant}
      $layout={layout}
    >
      {(type === 'item' || type === 'non-selectable') && (
        <>
          <MenuItemInner
            icon={icon}
            trailingIcon={trailingIcon}
            trailingTooltip={trailingTooltip}
            itemTooltip={itemTooltip}
            title={title}
            subTitle={subTitle}
            variant={variant}
            size={size}
            fontWeight={fontWeight}
            layout={layout}
            isActive={isSelected ?? (!!activeId && !!id && activeId?.includes(id))}
          />
        </>
      )}
    </S.DropdownMenuItem>
  )
}

const MenuItemSeparator = ({ title }: { title?: MenuItemLabelProps['title'] }): JSX.Element => {
  return <S.DropdownMenuSeparator data-title={title} />
}

const MenuItemSpacer = (): JSX.Element => {
  return <S.DropdownMenuSeparator />
}

const MenuItemDescription = ({ title, trailingIcon, onClick }: MenuItemLabelProps): JSX.Element => {
  return (
    <S.DropdownMenuItemDescription onClick={onClick} $selectable={!!onClick}>
      {title}
      {trailingIcon && <span>{renderedIcon(trailingIcon, 'muted', 'small')}</span>}
    </S.DropdownMenuItemDescription>
  )
}

const MenuItemLabel = ({ title }: MenuItemLabelProps): JSX.Element => {
  if (typeof title === 'string') {
    return (
      <S.DropdownMenuLabel>
        <span>{title}</span>
      </S.DropdownMenuLabel>
    )
  }
  return (
    <S.DropdownMenuLabel>
      {title}
      <Spacer $size={6} />
    </S.DropdownMenuLabel>
  )
}

const MenuItemSublabel = ({ title }: MenuItemLabelProps): JSX.Element => {
  if (typeof title === 'string') {
    return (
      <S.DropdownMenuSublabel>
        <span>{title}</span>
      </S.DropdownMenuSublabel>
    )
  }
  return (
    <S.DropdownMenuSublabel>
      {title}
      <Spacer $size={6} />
    </S.DropdownMenuSublabel>
  )
}

const MenuItem = ({
  id,
  type = 'item',
  icon,
  trailingIcon,
  title,
  subTitle,
  content,
  trailingTooltip,
  itemTooltip,
  href,
  target = '_self',
  onSelect,
  selectType,
  closeOnSelect = true,
  variant = 'neutral',
  isDisabled = false,
  isSelectable = true,
  isSelected,
  size = 'normal',
  fontWeight,
  layout,
  subitems,
  activeId,
  onKeyDown
}: MenuItemProps): JSX.Element => {
  if (type === 'separator') {
    return <MenuItemSeparator title={title} />
  }

  if (type === 'spacer') {
    return <MenuItemSpacer />
  }

  if (type === 'description') {
    return <MenuItemDescription title={title} trailingIcon={trailingIcon} onClick={onSelect} />
  }

  if (type === 'label') {
    return <MenuItemLabel title={title} />
  }

  if (type === 'sublabel') {
    return <MenuItemSublabel title={title} />
  }

  if (type === 'node') {
    return <>{content ?? title ?? undefined}</>
  }

  if (!isNil(href)) {
    return (
      <NavLink to={href} target={target} rel='noopener noreferrer'>
        <S.DropdownMenuItem
          $variant={variant}
          $isSelectable={isSelectable}
          onClick={() => {
            if (onSelect) {
              void onSelect()
            }
          }}
          onKeyDown={onKeyDown}
        >
          <MenuItemInner
            icon={icon}
            trailingIcon={trailingIcon}
            trailingTooltip={trailingTooltip}
            itemTooltip={itemTooltip}
            title={title}
            subTitle={subTitle}
            variant={variant}
            size={size}
            fontWeight={fontWeight}
            layout={layout}
            isActive={activeId === id}
          />
        </S.DropdownMenuItem>
      </NavLink>
    )
  }

  if ((type === 'item' || type === 'non-selectable') && subitems?.length) {
    return (
      <RadixDropdown.Sub>
        <S.DropdownMenuItemSubTrigger disabled={isDisabled} $isSelectable={isSelectable} $variant={variant}>
          <MenuItemInner
            icon={icon}
            trailingIcon={trailingIcon}
            trailingTooltip={trailingTooltip}
            itemTooltip={itemTooltip}
            title={title}
            subTitle={subTitle}
            variant={variant}
            size={size}
            fontWeight={fontWeight}
            isActive={!!activeId && !!id && activeId === id}
          />
        </S.DropdownMenuItemSubTrigger>
        <RadixDropdown.Portal>
          <S.DropdownMenuSubContent sideOffset={6} alignOffset={-8}>
            {subitems?.map((subitem) => (
              <Fragment key={subitem.id}>
                {subitem.type === 'label' && <MenuItemLabel title={subitem.title} />}
                {subitem.type === 'separator' && <MenuItemSeparator />}
                {subitem.type === 'item' && (
                  <MenuItemTrigger
                    id={subitem.id}
                    type={subitem.type}
                    isDisabled={subitem.isDisabled}
                    onSelect={subitem.onSelect}
                    variant={subitem.variant}
                    icon={subitem.icon}
                    trailingIcon={subitem.trailingIcon}
                    size={subitem.size}
                    fontWeight={subitem.fontWeight ?? fontWeight}
                    title={subitem.title}
                    subTitle={subitem.subTitle}
                    trailingTooltip={trailingTooltip}
                    closeOnSelect={subitem.closeOnSelect}
                    itemTooltip={subitem.itemTooltip}
                    activeId={activeId}
                  />
                )}
              </Fragment>
            ))}
          </S.DropdownMenuSubContent>
        </RadixDropdown.Portal>
      </RadixDropdown.Sub>
    )
  }

  return (
    <MenuItemTrigger
      id={id}
      type={type}
      isDisabled={isDisabled}
      isSelectable={isSelectable}
      isSelected={isSelected}
      onSelect={onSelect}
      closeOnSelect={selectType === 'multi' ? false : closeOnSelect}
      variant={variant}
      icon={icon}
      trailingIcon={trailingIcon}
      size={size}
      fontWeight={fontWeight}
      layout={layout}
      title={title}
      subTitle={subTitle}
      trailingTooltip={trailingTooltip}
      itemTooltip={itemTooltip}
      activeId={activeId}
    />
  )
}

export const Dropdown = ({
  trigger,
  items,
  stickyHeader,
  stickyItem,
  menuPosition = 'start',
  sideOffset = 6,
  alignOffset = 0,
  $menuWidth = 'auto',
  size = 'normal',
  fontWeight = 500,
  layout = 'inline',
  modal = true, // Recommend false if item button opens a dialog https://github.com/radix-ui/primitives/issues/1241
  $maxHeight,
  $maxWidth,
  $minWidth,
  selectType = 'single',
  selectedValue,
  triggerFullWidth,
  allowFilter = false,
  filterItemsPlaceholder,
  allowTopLevelSelect = false,
  topLevelSelectText,
  onTopLevelSelect,
  onCloseAutoFocus,
  disabled,
  side = 'bottom',
  onContentIsOverflowing
}: DropdownProps): JSX.Element => {
  const [renderItems, setRenderItems] = useState<MenuItemProps[]>([])
  const [filterValue, setFilterValue] = useState('')
  const [isOpen, setOpen] = useState(false)
  const [stickyHeaderHeight, setStickyHeaderHeight] = useState(0)
  const location = useLocation()

  const filterInputEl = useRef<HTMLInputElement>(null)
  const dropdownContentEl = useRef<HTMLDivElement>(null)
  const stickyHeaderEl = useRef<HTMLDivElement>(null)

  const [selectedItems, setSelectedItems] = useState<string | string[]>([])

  useEffect(() => {
    if (selectedValue) {
      setSelectedItems(selectedValue)
    }
  }, [selectedValue])

  useEffect(() => {
    if (isOpen) {
      const timeout = setTimeout(() => {
        const observer = new ResizeObserver(entries => {
          for (const entry of entries) {
            const height = entry.contentRect.height
            setStickyHeaderHeight(height)
          }
        })

        if (stickyHeaderEl.current) {
          observer.observe(stickyHeaderEl.current)
        }
      }, 0)

      return () => {
        clearTimeout(timeout)
      }
    } else {
      setStickyHeaderHeight(0)
    }
  }, [isOpen, stickyHeader])

  // We want to close the dropdown
  // as soon as the routes changes
  useEffect(() => {
    setOpen(false)
  }, [location])

  useEffect(() => {
    if (filterValue.trim().length >= 2) {
      const filteredItems: MenuItemProps[] = []
      let includeCurrentLabelOrSeparator = false

      items.forEach(item => {
        if (item.id === 'archived-jobs' || item.id === 'create-new-job' || item.title === 'NewJobSeparator') {
          return
        }

        if (item.type === 'label' || item.type === 'separator') {
          // We want to check if the current label (or separator) has some
          // matching items "attached" to it
          includeCurrentLabelOrSeparator = false

          const index = items.indexOf(item)
          for (let i = index + 1; i < items.length; i++) {
            const nextItem = items[i]
            if (nextItem.type === 'label' || nextItem.type === 'separator') {
              break
            }
            if (nextItem?.title?.toString().toLowerCase().includes(filterValue.toLowerCase())) {
              includeCurrentLabelOrSeparator = true
              break
            }
          }
          if (includeCurrentLabelOrSeparator) {
            filteredItems.push(item)
          }
        } else if (item?.title?.toString().toLowerCase().includes(filterValue.toLowerCase())) {
          filteredItems.push(item)
        }
      })
      setRenderItems(filteredItems)
    } else {
      const itemsWithId = items?.map((item) => {
        return {
          ...item,
          id: item.id ?? item.value ?? crypto.randomUUID()
        }
      })
      setRenderItems(itemsWithId)
    }
  }, [filterValue, items])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault()
    setFilterValue(event.target.value)
  }

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    event.stopPropagation()
    if (event.key === 'ArrowDown' && renderItems.length > 0) {
      event.preventDefault()
      const firstItem = dropdownContentEl?.current?.querySelector('[data-radix-collection-item]')
      if (firstItem) {
        (firstItem as HTMLElement).focus()
      }
    }
  }

  const handleItemKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    event.stopPropagation()
    if (event.key === 'Backspace' && filterInputEl.current) {
      event.preventDefault()
      filterInputEl.current.focus()
    }
  }

  useEffect(() => {
    if (isOpen && allowFilter) {
      // I don't really like this but it seems Radix focus control is
      // pretty aggressive so we have to manually reset the focus
      setTimeout(() => {
        if (filterInputEl.current) {
          filterInputEl.current.focus()
        }
      }, 100)
    }
  }, [isOpen, allowFilter])

  const pureItems = useMemo(() => renderItems?.filter(item => !item.type || item.type === 'item'), [renderItems])

  const getTopLevelIsSelected = useCallback((): boolean => {
    if (isNil(selectedItems)) {
      return false
    }
    if (typeof selectedItems === 'string') {
      return false
    }
    return selectedItems?.filter(item => item !== '')?.length === pureItems?.length
  }, [pureItems?.length, selectedItems])

  const handleTopLevelSelect = useCallback((): void => {
    const allItemValues = pureItems.map(item => item.value).filter((value): value is string => value !== undefined)
    const allItemsAreSelected = selectedItems?.length === pureItems.length
    const atLeastOneItemIsSelected = (selectedItems?.length ?? 0) >= 1
    const noItemIsSelected = !selectedItems?.length

    if (atLeastOneItemIsSelected) {
      setSelectedItems(allItemValues)
      onTopLevelSelect?.(allItemValues)
    }
    if (allItemsAreSelected) {
      setSelectedItems([])
      onTopLevelSelect?.([])
    }
    if (noItemIsSelected) {
      setSelectedItems(allItemValues)
      onTopLevelSelect?.(allItemValues)
    }
  }, [onTopLevelSelect, pureItems, selectedItems?.length])

  useEffect(() => {
    if (isOpen && onContentIsOverflowing) {
      const timeout = setTimeout(() => {
        const observer = new ResizeObserver(entries => {
          for (const entry of entries) {
            const contentHeight = entry.contentRect.height
            const maxHeight = parseInt($maxHeight ?? '0', 10)
            onContentIsOverflowing(contentHeight >= maxHeight)
          }
        })

        if (dropdownContentEl.current) {
          observer.observe(dropdownContentEl.current)
        }

        return () => {
          observer.disconnect()
        }
      }, 0)

      return () => {
        clearTimeout(timeout)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, $maxHeight])

  return (
    <RadixDropdown.Root
      open={isOpen}
      onOpenChange={(isOpen) => {
        setOpen(isOpen)
        if (!isOpen) {
          setFilterValue('')
        }
      }}
      modal={modal}
    >
      <S.Trigger
        $fullWidth={triggerFullWidth}
        disabled={disabled}
        onPointerDown={(e) => {
          if (disabled) {
            e.preventDefault()
            e.stopPropagation()
          }
        }}
      >
        <>{trigger}</>
      </S.Trigger>
      <RadixDropdown.Portal>
        <S.DropdownMenuContent
          ref={dropdownContentEl}
          align={menuPosition}
          sideOffset={sideOffset}
          alignOffset={alignOffset}
          $menuWidth={$menuWidth}
          $maxHeight={$maxHeight}
          $minWidth={$minWidth}
          $maxWidth={$maxWidth}
          $hasStickyItem={!isNil(stickyItem)}
          $hasStickyHeader={!isNil(stickyHeader)}
          $layout={layout}
          onCloseAutoFocus={(event) => {
            onCloseAutoFocus?.(event)
          }}
          side={side}
        >
          <When condition={!!stickyHeader}>
            <S.StickyHeader ref={stickyHeaderEl}>
              {stickyHeader?.content}
            </S.StickyHeader>
          </When>
          <When condition={allowFilter}>
            <S.Searchbar $stickyTopPosition={stickyHeaderHeight}>
              <Icon name="search" size={16} color="fgSecondary" />
              <input
                ref={filterInputEl}
                placeholder={filterItemsPlaceholder ?? 'Filter'}
                value={filterValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
              />
            </S.Searchbar>
          </When>
          <When condition={!!(allowTopLevelSelect && topLevelSelectText)}>
            <S.TopLevelSelect>
              <MenuItem
                title={topLevelSelectText}
                isSelected={getTopLevelIsSelected()}
                closeOnSelect={false}
                onSelect={() => { handleTopLevelSelect() }}
              />
            </S.TopLevelSelect>
          </When>
          <When condition={renderItems?.length >= 1}>
            {renderItems?.map((item) => (
              <MenuItem
                key={item.id}
                {...item}
                size={size}
                fontWeight={item.fontWeight ?? fontWeight}
                activeId={selectedValue}
                selectType={selectType}
                onKeyDown={handleItemKeyDown}
                layout={layout}
              />
            ))}
          </When>
          <When condition={!renderItems.length}>
            <S.NoFilterResult>
              <Icon name="binoculars" color="fgTertiary" />
              <Caption size="XS" $color="fgTertiary">No jobs found</Caption>
            </S.NoFilterResult>
          </When>
          <When condition={!isNil(stickyItem)}>
            <S.StickyAction>
              <MenuItem
                {...stickyItem}
                title={stickyItem?.title ?? ''}
                size={size}
                fontWeight={stickyItem?.fontWeight ?? fontWeight}
                selectType={selectType}
                onKeyDown={handleItemKeyDown}
              />
            </S.StickyAction>
          </When>
        </S.DropdownMenuContent>
      </RadixDropdown.Portal>
    </RadixDropdown.Root>
  )
}
