import { Icons } from 'src/components/primitives/icon'
import { CriteriaKey, DEFAULT_BOOLEAN_CRITERIA, DEFAULT_CUSTOM_REQUIREMENT_CRITERIA, DEFAULT_DEGREE_CRITERIA, DEFAULT_MIN_MAX_CRITERIA } from 'src/libs/api/backend/candidate_search'
import type { CriteriaValue } from 'src/libs/api/backend/candidate_search'

export type CriteriaType = 'location' | 'company' | 'school' | 'boolean-value' | 'degree-major'

export const FUNDING_ROUND_CRITERIA = new Map<number, string>([
  [1, 'Pre-Seed'],
  [2, 'Seed'],
  [3, 'Series A'],
  [4, 'Series B'],
  [5, 'Series C'],
  [6, 'Series D'],
  [7, 'Series E'],
  [8, 'Series F+'],
  [9, 'IPO']
])

export const DEGREE_OPTIONS = new Map<number, string>([
  [1, 'Associate'],
  [2, 'Bachelors'],
  [3, 'Masters'],
  [4, 'Doctorate']
])

export const TIMEZONE_OPTIONS = new Map<string, string>([
  ['alaska_time', 'Alaska Time'],
  ['atlantic_time', 'Atlantic Time'],
  ['central_time', 'Central Time'],
  ['eastern_time', 'Eastern Time'],
  ['hawaii_aleutian_time', 'Hawaii Aleutian Time'],
  ['mountain_time', 'Mountain Time'],
  ['newfoundland_time', 'Newfoundland Time'],
  ['pacific_time', 'Pacific Time'],
  ['western_european_time', 'Western European Time'],
  ['central_european_time', 'Central European Time'],
  ['eastern_european_time', 'Eastern European Time'],
  ['further_eastern_european_time', 'Further Eastern European Time']
])

export const RACE_OPTIONS = new Map<string, string>([
  ['asian', 'Asian'],
  ['black_or_african_american', 'Black or African American'],
  ['hispanic_or_latino', 'Hispanic or Latinx']
])

export const SEX_OPTIONS = new Map<string, string>([
  ['female', 'Female'],
  ['male', 'Male']
])

export const DEFAULT_CRITERIA = {
  [CriteriaKey.CURRENT_JOB_TITLES]: []
}

export const CriteriaProperties = new Map<string, {
  label: string
  defaultValue: CriteriaValue
  options?: Map<number | string, string>
  type?: CriteriaType
  tooltip?: {
    text: string
    position?: 'top' | 'bottom' | 'left' | 'right'
    align?: 'start' | 'center' | 'end'
  }
}>([
  [CriteriaKey.CURRENT_JOB_TITLES, {
    label: 'Current Job Titles',
    defaultValue: []
  }],
  [CriteriaKey.PREVIOUS_JOB_TITLES, {
    label: 'Previous Job Titles',
    defaultValue: []
  }],
  [CriteriaKey.LOCATIONS, {
    label: 'Candidate Location',
    defaultValue: [],
    type: 'location'
  }],
  [CriteriaKey.PREVIOUS_LOCATIONS, {
    label: 'Previous Locations',
    defaultValue: [],
    type: 'location'
  }],
  [CriteriaKey.MONTHS_IN_CURRENT_PROFESSION, {
    label: 'Years in Current Profession',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    tooltip: {
      text: 'Counts only years in the candidate’s current seniority and profession. For total work history across their entire career, use ‘Total Years of Experience’',
      position: 'top',
      align: 'start'
    }
  }],
  [CriteriaKey.MONTHS_OF_PROFESSIONAL_EXPERIENCE, {
    label: 'Total Years of Experience',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.MONTHS_AT_CURRENT_COMPANY, {
    label: 'Years at Current Company',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.CURRENT_COMPANY_HC, {
    label: "Company's Current Headcount",
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.CURRENT_COMPANY_JOINED_AT_HEADCOUNT, {
    label: "Company's Headcount at Time of Hire",
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.CURRENT_COMPANY_CURRENT_FUNDING_ROUND, {
    label: "Company's Current Funding Stage",
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    options: FUNDING_ROUND_CRITERIA
  }],
  [CriteriaKey.CURRENT_COMPANY_JOINED_AT_FUNDING_ROUND, {
    label: "Company's Funding Stage at Time of Hire",
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    options: FUNDING_ROUND_CRITERIA
  }],
  [CriteriaKey.AVERAGE_TENURE_IN_MONTHS, {
    label: 'Average Tenure at Companies',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.COMPANIES, {
    label: 'Companies Worked At',
    defaultValue: [],
    type: 'company'
  }],
  [CriteriaKey.CURRENT_COMPANY, {
    label: 'Current Company',
    defaultValue: [],
    type: 'company'
  }],
  [CriteriaKey.ANY_COMPANY_JOINED_AT_HEADCOUNT, {
    label: 'Headcount When Hired at Any Company',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.ANY_COMPANY_JOINED_AT_FUNDING_ROUND, {
    label: 'Funding Stage When Hired at Any Company',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    options: FUNDING_ROUND_CRITERIA
  }],
  [CriteriaKey.SCHOOLS, {
    label: 'Universities Attended',
    defaultValue: [],
    type: 'school'
  }],
  [CriteriaKey.ATTENDED_IVY_LEAGUE, {
    label: 'Attended Ivy League',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA
  }],
  [CriteriaKey.GRADUATION_YEAR, {
    label: 'Graduation Year',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.DEGREE, {
    label: 'Highest Degree',
    defaultValue: DEFAULT_DEGREE_CRITERIA,
    options: DEGREE_OPTIONS
  }],
  [CriteriaKey.DEGREE_MAJOR_PAIRS, {
    label: 'Degree and Major',
    defaultValue: [],
    type: 'degree-major'
  }],
  [CriteriaKey.CURRENT_COMPANY_HAS_NOTABLE_INVESTOR, {
    label: 'Works at a Company with a Notable Investor',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA
  }],
  [CriteriaKey.WORKED_AT_COMPANY_WITH_NOTABLE_INVESTOR, {
    label: 'Worked at a Company with a Notable Investor',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA
  }],
  [CriteriaKey.WORKS_AT_YC_COMPANY, {
    label: 'Works at a YC-backed Company',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA
  }],
  [CriteriaKey.WORKED_AT_YC_COMPANY, {
    label: 'Worked at a YC-backed Company',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA
  }],
  [CriteriaKey.CUSTOM_REQUIREMENTS, {
    label: 'AI Custom Criteria',
    defaultValue: [DEFAULT_CUSTOM_REQUIREMENT_CRITERIA]
  }],
  [CriteriaKey.KNOWN_LANGUAGES, {
    label: 'Known Languages',
    defaultValue: []
  }],
  [CriteriaKey.ASSIGNED_TIMEZONES, {
    label: 'Timezones',
    defaultValue: [],
    options: TIMEZONE_OPTIONS,
    type: 'location'
  }],
  [CriteriaKey.SEX, {
    label: 'Gender',
    defaultValue: [],
    options: SEX_OPTIONS
  }],
  [CriteriaKey.RACE_CATEGORY, {
    label: 'Ethnicity',
    defaultValue: [],
    options: RACE_OPTIONS
  }],
  [CriteriaKey.CURRENTLY_EMPLOYED, {
    label: 'Currently Employed',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA,
    type: 'boolean-value'
  }],
  [CriteriaKey.HAS_VISA_SPONSORSHIP, {
    label: 'Needs Visa Sponsorship',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA,
    type: 'boolean-value'
  }],
  [CriteriaKey.EXPERIENCE_IN_FUNDING_ROUNDS, {
    label: 'Experience in Funding Rounds',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    options: FUNDING_ROUND_CRITERIA
  }]
])

export enum CriteriaGroup {
  COMMON_CRITERIA = 'Common Criteria',
  CANDIDATE_DETAILS = 'Candidate Details',
  CURRENT_POSITION = 'Current Position',
  OVERALL_EXPERIENCE = 'Overall Experience',
  EDUCATION = 'Education',
  CUSTOM_CRITERIA = 'Custom Criteria'
}

export const CriteriaGroupOrder = [
  CriteriaGroup.COMMON_CRITERIA,
  CriteriaGroup.CANDIDATE_DETAILS,
  CriteriaGroup.CURRENT_POSITION,
  CriteriaGroup.OVERALL_EXPERIENCE,
  CriteriaGroup.EDUCATION,
  CriteriaGroup.CUSTOM_CRITERIA
]

export const CriteriaToGroupMap = new Map<CriteriaKey, CriteriaGroup>([
  [CriteriaKey.CURRENT_JOB_TITLES, CriteriaGroup.COMMON_CRITERIA],
  [CriteriaKey.LOCATIONS, CriteriaGroup.COMMON_CRITERIA],
  [CriteriaKey.MONTHS_IN_CURRENT_PROFESSION, CriteriaGroup.COMMON_CRITERIA],
  [CriteriaKey.PREVIOUS_LOCATIONS, CriteriaGroup.CANDIDATE_DETAILS],
  [CriteriaKey.SEX, CriteriaGroup.CANDIDATE_DETAILS],
  [CriteriaKey.ASSIGNED_TIMEZONES, CriteriaGroup.CANDIDATE_DETAILS],
  [CriteriaKey.HAS_VISA_SPONSORSHIP, CriteriaGroup.CANDIDATE_DETAILS],
  [CriteriaKey.KNOWN_LANGUAGES, CriteriaGroup.CANDIDATE_DETAILS],
  [CriteriaKey.CURRENTLY_EMPLOYED, CriteriaGroup.CANDIDATE_DETAILS],
  [CriteriaKey.RACE_CATEGORY, CriteriaGroup.CANDIDATE_DETAILS],
  [CriteriaKey.CURRENT_COMPANY, CriteriaGroup.CURRENT_POSITION],
  [CriteriaKey.MONTHS_AT_CURRENT_COMPANY, CriteriaGroup.CURRENT_POSITION],
  [CriteriaKey.CURRENT_COMPANY_HC, CriteriaGroup.CURRENT_POSITION],
  [CriteriaKey.CURRENT_COMPANY_JOINED_AT_HEADCOUNT, CriteriaGroup.CURRENT_POSITION],
  [CriteriaKey.CURRENT_COMPANY_CURRENT_FUNDING_ROUND, CriteriaGroup.CURRENT_POSITION],
  [CriteriaKey.CURRENT_COMPANY_JOINED_AT_FUNDING_ROUND, CriteriaGroup.CURRENT_POSITION],
  [CriteriaKey.COMPANIES, CriteriaGroup.OVERALL_EXPERIENCE],
  [CriteriaKey.PREVIOUS_JOB_TITLES, CriteriaGroup.OVERALL_EXPERIENCE],
  [CriteriaKey.AVERAGE_TENURE_IN_MONTHS, CriteriaGroup.OVERALL_EXPERIENCE],
  [CriteriaKey.MONTHS_OF_PROFESSIONAL_EXPERIENCE, CriteriaGroup.OVERALL_EXPERIENCE],
  [CriteriaKey.CURRENT_COMPANY_HAS_NOTABLE_INVESTOR, CriteriaGroup.OVERALL_EXPERIENCE],
  [CriteriaKey.WORKED_AT_COMPANY_WITH_NOTABLE_INVESTOR, CriteriaGroup.OVERALL_EXPERIENCE],
  [CriteriaKey.WORKS_AT_YC_COMPANY, CriteriaGroup.OVERALL_EXPERIENCE],
  [CriteriaKey.WORKED_AT_YC_COMPANY, CriteriaGroup.OVERALL_EXPERIENCE],
  [CriteriaKey.ANY_COMPANY_JOINED_AT_HEADCOUNT, CriteriaGroup.OVERALL_EXPERIENCE],
  [CriteriaKey.ANY_COMPANY_JOINED_AT_FUNDING_ROUND, CriteriaGroup.OVERALL_EXPERIENCE],
  [CriteriaKey.SCHOOLS, CriteriaGroup.EDUCATION],
  [CriteriaKey.ATTENDED_IVY_LEAGUE, CriteriaGroup.EDUCATION],
  [CriteriaKey.GRADUATION_YEAR, CriteriaGroup.EDUCATION],
  [CriteriaKey.DEGREE, CriteriaGroup.EDUCATION],
  [CriteriaKey.DEGREE_MAJOR_PAIRS, CriteriaGroup.EDUCATION],
  [CriteriaKey.EXPERIENCE_IN_FUNDING_ROUNDS, CriteriaGroup.OVERALL_EXPERIENCE],
  [CriteriaKey.CUSTOM_REQUIREMENTS, CriteriaGroup.CUSTOM_CRITERIA]
])

export const GroupToCriteriaMap = new Map<CriteriaGroup, CriteriaKey[]>([
  [CriteriaGroup.COMMON_CRITERIA, [
    CriteriaKey.CURRENT_JOB_TITLES,
    CriteriaKey.LOCATIONS,
    CriteriaKey.MONTHS_IN_CURRENT_PROFESSION
  ]],
  [CriteriaGroup.CANDIDATE_DETAILS, [
    CriteriaKey.PREVIOUS_LOCATIONS,
    CriteriaKey.SEX,
    CriteriaKey.ASSIGNED_TIMEZONES,
    CriteriaKey.HAS_VISA_SPONSORSHIP,
    CriteriaKey.KNOWN_LANGUAGES,
    CriteriaKey.CURRENTLY_EMPLOYED,
    CriteriaKey.RACE_CATEGORY
  ]],
  [CriteriaGroup.CURRENT_POSITION, [
    CriteriaKey.CURRENT_COMPANY,
    CriteriaKey.MONTHS_AT_CURRENT_COMPANY,
    CriteriaKey.CURRENT_COMPANY_HC,
    CriteriaKey.CURRENT_COMPANY_JOINED_AT_HEADCOUNT,
    CriteriaKey.CURRENT_COMPANY_CURRENT_FUNDING_ROUND,
    CriteriaKey.CURRENT_COMPANY_JOINED_AT_FUNDING_ROUND
  ]],
  [CriteriaGroup.OVERALL_EXPERIENCE, [
    CriteriaKey.COMPANIES,
    CriteriaKey.PREVIOUS_JOB_TITLES,
    CriteriaKey.AVERAGE_TENURE_IN_MONTHS,
    CriteriaKey.MONTHS_OF_PROFESSIONAL_EXPERIENCE,
    CriteriaKey.CURRENT_COMPANY_HAS_NOTABLE_INVESTOR,
    CriteriaKey.WORKED_AT_COMPANY_WITH_NOTABLE_INVESTOR,
    CriteriaKey.WORKS_AT_YC_COMPANY,
    CriteriaKey.WORKED_AT_YC_COMPANY,
    CriteriaKey.ANY_COMPANY_JOINED_AT_HEADCOUNT,
    CriteriaKey.ANY_COMPANY_JOINED_AT_FUNDING_ROUND,
    CriteriaKey.EXPERIENCE_IN_FUNDING_ROUNDS
  ]],
  [CriteriaGroup.EDUCATION, [
    CriteriaKey.SCHOOLS,
    CriteriaKey.ATTENDED_IVY_LEAGUE,
    CriteriaKey.GRADUATION_YEAR,
    CriteriaKey.DEGREE,
    CriteriaKey.DEGREE_MAJOR_PAIRS
  ]],
  [CriteriaGroup.CUSTOM_CRITERIA, [
    CriteriaKey.CUSTOM_REQUIREMENTS
  ]]
])

export const NewCriteriaList = [
  {
    criteriaKey: CriteriaKey.CURRENT_JOB_TITLES,
    icon: Icons.briefcase
  },
  {
    criteriaKey: CriteriaKey.LOCATIONS,
    icon: Icons.mapPin
  },
  {
    criteriaKey: CriteriaKey.MONTHS_IN_CURRENT_PROFESSION,
    icon: Icons.calendarDays
  },
  {
    groupTitle: CriteriaGroup.CANDIDATE_DETAILS,
    icon: Icons.userCircle,
    items: GroupToCriteriaMap.get(CriteriaGroup.CANDIDATE_DETAILS)?.map((criteriaKey) => ({
      criteriaKey
    })) ?? []
  },
  {
    groupTitle: CriteriaGroup.CURRENT_POSITION,
    icon: Icons.briefcase,
    items: GroupToCriteriaMap.get(CriteriaGroup.CURRENT_POSITION)?.map((criteriaKey) => ({
      criteriaKey
    })) ?? []
  },
  {
    groupTitle: CriteriaGroup.OVERALL_EXPERIENCE,
    icon: Icons.experienceClock,
    items: GroupToCriteriaMap.get(CriteriaGroup.OVERALL_EXPERIENCE)?.map((criteriaKey) => ({
      criteriaKey
    })) ?? []
  },
  {
    groupTitle: CriteriaGroup.EDUCATION,
    icon: Icons.graduationCap,
    items: GroupToCriteriaMap.get(CriteriaGroup.EDUCATION)?.map((criteriaKey) => ({
      criteriaKey
    })) ?? []
  }
]
