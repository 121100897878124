import { Button } from 'src/components/primitives/button'
import { Flex } from 'src/components/primitives/flex'
import { useRejectCandidate } from 'src/hooks/mutations/use-reject-candidate'
import { CandidateJobStage, sourcingRejectionReasons } from 'src/libs/api/backend/candidate_jobs'
import type {
  CandidateJobRejectionReason,
  CandidateJobExpanded
} from 'src/libs/api/backend/candidate_jobs'
import { Dropdown } from 'src/components/primitives/dropdown'
import { useToggleCandidateFavoriteStatus } from 'src/hooks/mutations/use-toggle-candidate-favorite-status'
import { map } from 'lodash'
import { Icons } from 'src/components/primitives/icon'
import { useCallback } from 'react'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { useSession } from 'src/hooks/use-session'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { sleep } from 'src/libs/sleep'
import { When } from '../when'
import { AvailableCandidateActions } from './types'
import { useSetAtom } from 'jotai'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useCanTriggerEmailSequence } from 'src/hooks/use-available-credits'
import { DoubleButton } from 'src/components/primitives/double-button'
import { ButtonGroup } from 'src/components/primitives/button-group'
import { ProductTourStep } from 'src/libs/product-tour'

interface CandidateActionsProps {
  candidateJob: CandidateJobExpanded
  onAddToSequenceClick: () => void
  actions?: AvailableCandidateActions[]
  addToSequenceActionDisplayVariant?: 'normal' | 'compact'
  rejectActionDisplayVariant?: 'normal' | 'compact'
}

export const CandidateActions = ({
  candidateJob,
  onAddToSequenceClick,
  actions = [AvailableCandidateActions.SHORTLIST, AvailableCandidateActions.ADD_TO_SEQUENCE, AvailableCandidateActions.REJECT, AvailableCandidateActions.SHARE],
  addToSequenceActionDisplayVariant = 'normal',
  rejectActionDisplayVariant = 'normal'
}: CandidateActionsProps): JSX.Element => {
  const { rejectCandidate } = useRejectCandidate()
  const { toggleFavoriteStatus } = useToggleCandidateFavoriteStatus()
  const openDialog = useSetAtom(openDialogAtom)
  const canTriggerAddToOureach = useCanTriggerEmailSequence()
  const { userHasViewerRole } = useSession()
  const { data: emailSequence, isLoading: isJobSequenceLoading } = useJobSequenceQuery()

  const { featureFlags } = useSession()
  const { favorite: isFavorited, id } = candidateJob

  const handleAddToSequence = useCallback(() => {
    if (!canTriggerAddToOureach) {
      openDialog({ id: DialogId.INSUFFICIENT_CREDITS })
      return
    }
    onAddToSequenceClick()
  }, [canTriggerAddToOureach, openDialog, onAddToSequenceClick])

  const handleReject = useCallback((rejectionReason?: CandidateJobRejectionReason): void => {
    rejectCandidate({ candidateJobIds: [id], rejectionReason })
  }, [rejectCandidate, id])

  const handleToggleFavoriteStatus = useCallback((newStatus: boolean): void => {
    toggleFavoriteStatus({
      candidateJobId: id,
      newFavoriteStatus: newStatus,
      showNotification: true
    })
  }, [toggleFavoriteStatus, id])

  const onMoveCandidatesClick = useCallback(() => {
    const payload = {
      candidateJobs: [candidateJob],
      requiredReason: candidateJob.stage !== CandidateJobStage.SOURCED
    }
    openDialog({ id: DialogId.MOVE_TO_ANOTHER_JOB, payload })
  }, [candidateJob, openDialog])

  const onCustomizeOutreachClick = useCallback(async () => {
    if (isSequenceStepsEmpty(emailSequence)) {
      openDialog({ id: DialogId.CREATE_SEQUENCE })
    } else {
      await sleep(50)
      openDialog({ id: DialogId.WRITE_CUSTOM_OUTREACH, payload: candidateJob })
    }
  }, [emailSequence, openDialog, candidateJob])

  return (
    <Flex $align="center" $justify="flex-end" $gap={12} id={ProductTourStep.CANDIDATE_ACTIONS}>
      <When condition={actions.includes(AvailableCandidateActions.SHARE)}>
        <Button
          ariaLabel="Move candidate to another job"
          $variant="raised"
          $colorTheme="muted"
          $fontSize={12}
          $height={48}
          $direction="column"
          // $width={32}
          leadingIcon={Icons.shareBox}
          iconSize={14}
          // TODO: Hook up this up to the actual current candidate status
          onClick={() => {
            onMoveCandidatesClick()
          }}
          disabled={userHasViewerRole}
        >
          Move
        </Button>
      </When>
      <When condition={actions.includes(AvailableCandidateActions.SHORTLIST)}>
        <Button
          ariaLabel="Toggle favorite status"
          $variant="raised"
          $colorTheme={isFavorited ? 'warning' : 'muted'}
          $fontSize={12}
          $height={48}
          $direction="column"
          // $width={32}
          leadingIcon={isFavorited ? Icons.starFill : Icons.star}
          iconSize={14}
          // TODO: Hook up this up to the actual current candidate status
          onClick={() => {
            handleToggleFavoriteStatus(!isFavorited)
          }}
          disabled={userHasViewerRole}
        >
          Shortlist
        </Button>
      </When>
      <When condition={actions.includes(AvailableCandidateActions.ADD_TO_SEQUENCE)}>
        <When condition={addToSequenceActionDisplayVariant === 'normal'}>
          <DoubleButton
            $variant="raised"
            $colorTheme="tint"
            icon={Icons.mailPlus}
            onPrimaryClick={handleAddToSequence}
            secondaryItems={
              featureFlags?.includes(FeatureFlags.CUSTOM_OUTREACH) && !isSequenceStepsEmpty(emailSequence)
                ? [
                    {
                      id: '0',
                      title: 'Add to Outreach',
                      subTitle: 'Automated outreach sequence',
                      onSelect: handleAddToSequence
                    },
                    {
                      id: '1',
                      title: 'Customize Outreach',
                      subTitle: 'Customize 1st outreach message',
                      isDisabled: isJobSequenceLoading,
                      onSelect: onCustomizeOutreachClick
                    }
                  ]
                : undefined
            }
            primaryDisabled={userHasViewerRole}
            secondaryDisabled={userHasViewerRole}
          >
            Add to Outreach
          </DoubleButton>
        </When>
        <When condition={addToSequenceActionDisplayVariant === 'compact'}>
          <ButtonGroup $variant="raised" $gap={0}>
            <Button
              $variant="raised"
              $colorTheme="tint"
              $fontSize={12}
              $height={24}
              leadingIcon={Icons.mailPlus}
              onClick={handleAddToSequence}
              disabled={userHasViewerRole}
            >
              Add to Outreach
            </Button>
            {featureFlags?.includes(FeatureFlags.CUSTOM_OUTREACH) && !isSequenceStepsEmpty(emailSequence) &&
              <Dropdown
                trigger={
                  <Button
                    nested
                    $fontSize={12}
                    $width={24}
                    $variant="raised"
                    $colorTheme="tint"
                    $height={24}
                    leadingIcon={Icons.chevronDown}
                    disabled={userHasViewerRole}
                  />
                }
                items={
                  [
                    {
                      id: '0',
                      title: 'Add to Outreach',
                      subTitle: 'Automated outreach sequence',
                      onSelect: handleAddToSequence
                    },
                    {
                      id: '1',
                      title: 'Customize Outreach',
                      subTitle: 'Customize 1st outreach message',
                      isDisabled: isJobSequenceLoading,
                      onSelect: onCustomizeOutreachClick
                    }
                  ]
                }
                size="small"
                menuPosition="end"
                disabled={userHasViewerRole}
              />}
          </ButtonGroup>
        </When>
      </When>
      <When condition={actions.includes(AvailableCandidateActions.REJECT)}>
        <When condition={rejectActionDisplayVariant === 'normal'}>
          <DoubleButton
            $variant="raised"
            $colorTheme="negative"
            icon={Icons.xOctagon}
            onPrimaryClick={() => { handleReject() }}
            secondaryItems={
              map(sourcingRejectionReasons, (reason, key) => ({
                id: key,
                title: reason,
                onSelect: () => {
                  handleReject(key as CandidateJobRejectionReason)
                }
              }))
            }
            primaryDisabled={userHasViewerRole}
            secondaryDisabled={userHasViewerRole}
          >
            Decline
          </DoubleButton>
        </When>
        <When condition={rejectActionDisplayVariant === 'compact'}>
          <Dropdown
            trigger={
              <Button
                nested
                $fontSize={12}
                $width={24}
                $variant="raised"
                $colorTheme="negative"
                $height={24}
                leadingIcon={Icons.archive}
                disabled={userHasViewerRole}
              />
            }
            items={
              map(sourcingRejectionReasons, (reason, key) => ({
                id: key,
                title: reason,
                onSelect: () => {
                  handleReject(key as CandidateJobRejectionReason)
                }
              }))
            }
            size="small"
            menuPosition="end"
            disabled={userHasViewerRole}
          />
        </When>
        {/* <ButtonGroup $variant="raised" $gap={0}>
          <When condition={rejectActionDisplayVariant === 'normal'}>
            <Button
              $variant="raised"
              $colorTheme="negative"
              $fontSize={12}
              $height={24}
              leadingIcon={Icons.xOctagon}
              onClick={() => {
                handleReject()
              }}
            >
              Decline
            </Button>
          </When>
          <Dropdown
            trigger={
              <Button
                nested
                $fontSize={12}
                $width={24}
                $variant="raised"
                $colorTheme="negative"
                $height={24}
                leadingIcon={rejectActionDisplayVariant === 'normal' ? Icons.chevronDown : Icons.archive}
              />
            }
            items={
              map(sourcingRejectionReasons, (reason, key) => ({
                id: key,
                title: reason,
                onSelect: () => {
                  handleReject(key as CandidateJobRejectionReason)
                }
              }))
            }
            size="small"
            menuPosition="end"
          />
        </ButtonGroup> */}
      </When>
    </Flex>
  )
}
