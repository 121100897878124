import { isNil } from 'lodash'
import type { ReactNode } from 'react'
import { Flex } from 'src/components/primitives'
import { Caption } from 'src/components/primitives/typography'
import { CriteriaKey, LOCATION_CRITERIA_KEYS, COMPANY_CRITERIA_KEYS, SCHOOL_CRITERIA_KEYS, JOB_TITLES_CRITERIA_KEYS } from 'src/libs/api/backend/candidate_search'
import type { CriteriaValue, CustomRequirementCriteria, SchoolCompanyCriteria, LocationCriteria, MinMaxCriteria, JobTitleCriteria, BooleanCriteria, LanguageCriteria, StandardCriteria, DegreeMajorPairCriteria } from 'src/libs/api/backend/candidate_search'
import { CriteriaProperties } from '../constants'
import * as S from './index.styled'
import { MinMaxRow } from './min-max-row'
import { CustomRequirementRow } from './custom-criteria'
import { SchoolRow } from './school-row'
import { LocationRow } from './location-row'
import { CompanyRow } from './company-row'
import { JobRow } from './job-row'
import { BooleanRow } from './boolean-row'
import { LanguageRow } from './language-row'
import { StandardSelectionArrayRow } from './standard-selection-array-row'
import { DegreeMajorRow } from './degree-major-row'
import { CheckboxField } from './appendix'
import { Icons } from 'src/components/primitives/icon'
interface CriteriaRowProps {
  criteriaKey: CriteriaKey
  criteriaValue: CriteriaValue | undefined
  onCriteriaUpdate: (criteriaKey: CriteriaKey, criteriaValue: CriteriaValue) => void
  onCriteriaRemove: (criteriaKey: CriteriaKey, index?: number) => void
  updated: boolean
  disabled: boolean
  onRowClick?: (criteriaKey: CriteriaKey) => void
  criteriaLimitations?: {
    blockedCriteria: string[] | null | undefined
    criteriaImprovementToReplace: string | null | undefined
  }
}

const isCriteriaBlocked = (blockedCriteria: string[], criteriaValue: string): boolean => {
  return blockedCriteria.some((blockedString: string) => blockedString === criteriaValue)
}

export const CriteriaRow = ({
  criteriaKey,
  criteriaValue,
  onCriteriaUpdate,
  onCriteriaRemove,
  updated,
  disabled,
  onRowClick,
  criteriaLimitations
}: CriteriaRowProps): JSX.Element => {
  let children: ReactNode | null = null
  if (!isNil(criteriaValue)) {
    if (Array.isArray(criteriaValue)) {
      if (JOB_TITLES_CRITERIA_KEYS.includes(criteriaKey)) {
        children = (
          <JobRow
            criteriaKey={criteriaKey}
            criteriaValue={criteriaValue as JobTitleCriteria[]}
            onCriteriaUpdate={onCriteriaUpdate}
            onCriteriaRemove={onCriteriaRemove}
            disabled={disabled}
          />
        )
      } else if (criteriaKey === CriteriaKey.KNOWN_LANGUAGES) {
        children = (
          <LanguageRow
            criteriaKey={criteriaKey}
            criteriaValue={criteriaValue as LanguageCriteria[]}
            onCriteriaUpdate={onCriteriaUpdate}
            onCriteriaRemove={onCriteriaRemove}
            disabled={disabled}
          />
        )
      } else if (criteriaKey === CriteriaKey.DEGREE_MAJOR_PAIRS) {
        children = (
          <DegreeMajorRow
            criteriaKey={criteriaKey}
            criteriaValue={criteriaValue as DegreeMajorPairCriteria[]}
            onCriteriaUpdate={onCriteriaUpdate}
            onCriteriaRemove={onCriteriaRemove}
            disabled={disabled}
          />
        )
      } else if (criteriaKey === CriteriaKey.CUSTOM_REQUIREMENTS) {
        // Custom requirement row
        children = (
          <Flex $direction='column' $gap={8}>
            {criteriaValue.map((customCriteria, index) => {
              const value = customCriteria as CustomRequirementCriteria
              return (
                <CustomRequirementRow
                  key={index}
                  criteriaValue={value}
                  onUpdate={(key: string, value: number | string | boolean) => {
                    const updatedCriteriaValue = [...criteriaValue]
                    updatedCriteriaValue[index] = {
                      ...updatedCriteriaValue[index],
                      [key]: value
                    }
                    onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
                  }}
                  onRemove={() => {
                    onCriteriaRemove(criteriaKey, index)
                  }}
                  disabled={disabled}
                  errored={isCriteriaBlocked(criteriaLimitations?.blockedCriteria ?? [], value.requirement)}
                  limitedResults={criteriaLimitations?.criteriaImprovementToReplace === value.requirement}
                />
              )
            })}
          </Flex>
        )
      } else if (SCHOOL_CRITERIA_KEYS.includes(criteriaKey)) {
        // School/Company row
        children = (
          <SchoolRow
            criteriaKey={criteriaKey}
            criteriaValue={criteriaValue as SchoolCompanyCriteria[]}
            onCriteriaUpdate={onCriteriaUpdate}
            onCriteriaRemove={onCriteriaRemove}
            disabled={disabled}
          />
        )
      } else if (COMPANY_CRITERIA_KEYS.includes(criteriaKey)) {
        children = (
          <CompanyRow
            criteriaKey={criteriaKey}
            criteriaValue={criteriaValue as SchoolCompanyCriteria[]}
            onCriteriaUpdate={onCriteriaUpdate}
            onCriteriaRemove={onCriteriaRemove}
            disabled={disabled}
          />
        )
      } else if (LOCATION_CRITERIA_KEYS.includes(criteriaKey)) {
        children = (
          <LocationRow
            criteriaKey={criteriaKey}
            criteriaValue={criteriaValue as LocationCriteria[]}
            onCriteriaUpdate={onCriteriaUpdate}
            onCriteriaRemove={onCriteriaRemove}
            disabled={disabled}
          />
        )
      } else {
        children = (
          <StandardSelectionArrayRow
            criteriaKey={criteriaKey}
            criteriaValue={criteriaValue as StandardCriteria[]}
            onCriteriaUpdate={onCriteriaUpdate}
            onCriteriaRemove={onCriteriaRemove}
            disabled={disabled}
          />
        )
      }
    } else if ('value' in criteriaValue) {
      children = (
        <BooleanRow
          criteriaKey={criteriaKey}
          criteriaValue={criteriaValue as BooleanCriteria}
          onUpdate={onCriteriaUpdate}
          onRemove={() => {
            onCriteriaRemove(criteriaKey)
          }}
          disabled={disabled}
        />
      )
    } else {
      if ('min' in criteriaValue || 'max' in criteriaValue) {
        children = (
          <Flex $direction='column' $gap={8}>
            <MinMaxRow
              criteriaKey={criteriaKey}
              criteriaValue={criteriaValue}
              onUpdate={(value: Partial<MinMaxCriteria>) => {
                onCriteriaUpdate(criteriaKey, {
                  ...criteriaValue,
                  ...value
                })
              }}
              onRemove={() => {
                onCriteriaRemove(criteriaKey)
              }}
              disabled={disabled}
            />
            {criteriaKey === CriteriaKey.DEGREE && (
              <CheckboxField
                criteriaKey={criteriaKey}
                criteriaValue={criteriaValue.equivalent_work_experience ?? false}
                label='or relevant experience in the field'
                onUpdate={(checked: boolean) => {
                  onCriteriaUpdate(criteriaKey, {
                    ...criteriaValue,
                    equivalent_work_experience: checked
                  })
                }}
                disabled={disabled}
                tooltip={{
                  icon: Icons.helpCircle,
                  text: 'Candidate has an equivalent work experience',
                  position: 'right'
                }}
              />
            )}
          </Flex>
        )
      }
    }
  }

  return (
    <S.RowWrapper>
      <S.CriteriaRowWrapper
        $updated={updated}
        onClick={() => {
          if (updated) {
            onRowClick?.(criteriaKey)
          }
        }}
      >
        <S.LabelContainer>
          <Caption size='XS' $fontWeight={400}>{CriteriaProperties.get(criteriaKey)?.label}</Caption>
        </S.LabelContainer>
        {children}
      </S.CriteriaRowWrapper>
    </S.RowWrapper>
  )
}
