import { Fragment, useRef, useEffect, useState, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

// Components
import { Button } from 'src/components/primitives/button'
import type { ButtonProps } from 'src/components/primitives/button'
import { SequenceStep } from './sequence-step'
import { Paragraph } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import { When } from 'src/components/blocks/when'
import { Spacer } from 'src/components/primitives/spacer'

// Hooks, libs, styles
import type { EmailSequence, EmailSequenceStep } from 'src/models/sequence'
import type { SequenceInstructionsForm } from './instructions'
import { isNil } from 'lodash'
import { useUpsertJobSequence } from 'src/hooks/mutations/use-upsert-job-sequence'
import {
  generateInitialSequence,
  parseInitialState,
  useEmailSequenceEditor
} from 'src/hooks/use-email-sequence-editor'
import * as S from './sequence-editor.styled'
import { useAlertOnRouteChange } from 'src/hooks/use-alert-on-route-change'
import { SequenceStepGenerationState, SequenceStepType } from 'src/libs/api/backend/sequences'
import { Icon, Icons } from 'src/components/primitives/icon'
import { useIsSafari } from 'src/hooks/use-is-browser'
import { EmailSequenceSuggestion } from '../email-sequence-suggestion/email-sequence-suggestion'
import { Editor } from '../editor'
import { LoadingSkeleton } from '../loading-skeleton'
import { Box } from 'src/components/primitives/box'
import { Dropdown } from 'src/components/primitives/dropdown'
import { AutoArchiveAfterDays } from 'src/libs/api/backend/jobs'
import { useSendTestEmail } from 'src/hooks/mutations/use-send-test-email'
import { closeDialogAtom, DialogId, openAlertAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import { BrandIcon } from 'src/components/primitives/brand-icon'
import { convertSubjectToText, parseVariableToComponent } from '../editor/extensions/variable-parser'
import { closestCorners, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import type { DragEndEvent } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { StepSelector } from './step-selector'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { useSession } from 'src/hooks/use-session'

type Action = ButtonProps

const MAX_NUMBER_OF_STEPS = 10

export interface SequenceStepsEditorProps {
  initialState?: EmailSequence
  sequenceInstructions?: SequenceInstructionsForm
  shouldAutoGenerateEmails?: boolean
  isEditable?: boolean
  onBackClick?: () => void
  actions?: {
    save?: Action
    skip?: Action
    toggleActive?: () => void
  }
}

export const SequenceStepsEditor = ({
  initialState,
  shouldAutoGenerateEmails = true,
  // actions = {}
  isEditable = true,
  onBackClick
}: SequenceStepsEditorProps): JSX.Element => {
  const { userHasViewerRole } = useSession()
  const closeDialog = useSetAtom(closeDialogAtom)
  const openAlert = useSetAtom(openAlertAtom)
  const [currentStep, setCurrentStep] = useState(0)
  const [animationDone, setAnimationDone] = useState(true)
  const [isSendingTestEmail, setIsSendingTestEmail] = useState(false)
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { jobId } = useParams()
  const scrollableStepsSectionEl = useRef<HTMLDivElement>(null)
  const {
    isLoading,
    sequenceState,
    emailsBeingGenerated,
    generatedEmails,
    getStepSubjectAndPlaceholder,
    getNewPosition,
    handleAddStep,
    isReadyForNextStep,
    handleGenerateStepSuggestion,
    handleConfirmStepSuggestion,
    handleDataChange,
    handleRemoveStep,
    handleUpdateReplyType,
    handleUpdateSequencePreferences,
    handleReorderSteps,
    validateSequenceState,
    hasEmptySequenceStep,
    hasUnsavedChanges,
    setHasUnsavedChanges
  } = useEmailSequenceEditor({
    initialState: parseInitialState(initialState ?? generateInitialSequence(3)),
    autoGenerateEmails: shouldAutoGenerateEmails,
    sequenceSize: initialState?.sequenceSteps?.length ?? 3
  })
  const { sendTestEmail } = useSendTestEmail()
  const [isReordering, setIsReordering] = useState(false)
  const [reorderKey, setReorderKey] = useState(0)

  const isSafari = useIsSafari()

  const atLeastOneStepIsBeingGenerated = useMemo(
    () => sequenceState.sequenceSteps?.some((step) => step.generationState === SequenceStepGenerationState.IN_PROGRESS),
    [sequenceState.sequenceSteps]
  )

  useAlertOnRouteChange(hasUnsavedChanges)

  const sensors = useSensors(
    useSensor(PointerSensor)
  )

  const handleReorderEnd = (event: DragEndEvent): void => {
    const { active, over } = event
    if (active.id !== over?.id) {
      const oldIndex = sequenceState.sequenceSteps?.findIndex((step) => step.id === active.id)
      const newIndex = sequenceState.sequenceSteps?.findIndex((step) => step.id === over?.id)

      if (oldIndex !== undefined && newIndex !== undefined) {
        const updated = arrayMove(sequenceState.sequenceSteps ?? [], oldIndex, newIndex)
        handleReorderSteps(updated)
        setCurrentStep(newIndex)
        setReorderKey(prev => prev + 1)
      }
    }
    document.body.style.removeProperty('user-select')
  }

  useEffect(() => {
    if (isReordering) {
      document.body.style.userSelect = 'none'
    } else {
      document.body.style.removeProperty('user-select')
    }
  }, [isReordering])

  const { upsertJobSequence, isPending } = useUpsertJobSequence()

  const initialStepsAreGenerating = useMemo(() => {
    return !isNil(sequenceState.sequenceSteps) && sequenceState.sequenceSteps?.every((step) => step.generationState === SequenceStepGenerationState.IN_PROGRESS)
  }, [sequenceState.sequenceSteps])

  const stepIsGenerating = useCallback(
    (stepPosition: number): boolean => {
      const isNewSequenceStepBeingGenerated = emailsBeingGenerated?.includes(stepPosition) ?? false
      const isInitialSequenceStepsBeingGenerated =
        sequenceState.sequenceSteps?.at(stepPosition)?.generationState === SequenceStepGenerationState.IN_PROGRESS
      return isNewSequenceStepBeingGenerated || isInitialSequenceStepsBeingGenerated
    },
    [emailsBeingGenerated, sequenceState.sequenceSteps]
  )

  const editorIsEditable = useMemo(() => {
    return !initialStepsAreGenerating && isEditable
  }, [initialStepsAreGenerating, isEditable])

  const isSequenceGenerating = useMemo((): boolean => {
    // NOTE: we return true in this case because if `sequenceState.sequenceSteps` is null, then the initial sequence is being generated
    if (isNil(sequenceState.sequenceSteps)) {
      return true
    }

    if (isNil(emailsBeingGenerated)) {
      return false
    }

    return emailsBeingGenerated.length > 0
  }, [emailsBeingGenerated, sequenceState.sequenceSteps])

  const reachedMaxStepCount = useMemo(() => {
    return Array.isArray(sequenceState.sequenceSteps) && sequenceState.sequenceSteps.length === MAX_NUMBER_OF_STEPS
  }, [sequenceState])

  const saveSequence = useCallback(async ({ closeAfterSaving = true, onSuccess }: { closeAfterSaving?: boolean, onSuccess?: () => void } = {}) => {
    if (isNil(jobId)) {
      return
    }
    const validated = await validateSequenceState(sequenceState)

    if (isNil(validated)) {
      return
    }

    upsertJobSequence({
      jobId,
      sequenceSteps: validated.sequenceSteps,
      active: validated.active ?? true,
      autoArchiveAfterDays: validated.autoArchiveAfterDays,
      dailyEmailLimit: validated.dailyEmailLimit,
      onSuccess: () => {
        if (closeAfterSaving) {
          closeDialog(DialogId.CREATE_SEQUENCE)
        }
        onSuccess?.()
      }
    })
  }, [closeDialog, jobId, sequenceState, upsertJobSequence, validateSequenceState])

  const scrollToCurrentStep = useCallback((stepPosition: number, animatedScroll = true) => {
    if (scrollableStepsSectionEl.current) {
      const stepElement = document.getElementById(`step-${stepPosition}`)
      if (stepElement) {
        scrollableStepsSectionEl.current.onscrollend = () => {
          setAnimationDone(true)
        }
        scrollableStepsSectionEl.current.scrollTo({
          top: stepElement.offsetTop,
          behavior: animatedScroll ? 'smooth' : 'instant'
        })
        if (isSafari) {
          if (scrollTimeoutRef.current) {
            clearTimeout(scrollTimeoutRef.current)
          }
          // THANKS SAFARI FOR NOT SUPPORTING scrollend event
          // This will break the smooth animation on Chrome so don't enable it for Chrome powered browser or FF
          // Remove this when Safari supports https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollend_event#browser_compatibility likes an adult
          scrollTimeoutRef.current = setTimeout(() => {
            setAnimationDone(true)
          }, animatedScroll ? 500 : 0)
        }
      }
    }
  }, [isSafari])

  useEffect(() => {
    if (isReordering) {
      scrollToCurrentStep(currentStep, false)
    } else {
      scrollToCurrentStep(currentStep, true)
    }
  }, [currentStep, scrollToCurrentStep, isReordering])

  const onStepClick = useCallback((stepPosition: number) => {
    setIsReordering(false)
    setAnimationDone(false)
    setCurrentStep(stepPosition)
  }, [])

  const handleSendTestEmail = async (step: EmailSequenceStep, recipients: string[], candidateId?: string | undefined): Promise<void> => {
    setIsSendingTestEmail(true)
    if (hasUnsavedChanges) {
      openAlert({
        message: 'Unsaved changes',
        description: 'You have unsaved changes in your sequence. You need to save your changes before being able to send a preview email.',
        confirmText: 'Save and send preview',
        variant: 'tint',
        onCancel: () => {
          setIsSendingTestEmail(false)
        },
        onConfirm: () => {
          void saveSequence({
            closeAfterSaving: false,
            onSuccess: () => {
              sendTestEmail({
                emails: recipients ?? [],
                sequenceStep: step,
                candidateId,
                onSuccess: () => {
                  closeDialog(DialogId.ALERT)
                  setHasUnsavedChanges(false)
                  setIsSendingTestEmail(false)
                }
              })
            }
          })
        }
      })
    } else {
      sendTestEmail({
        emails: recipients ?? [],
        sequenceStep: step,
        candidateId,
        onSuccess: () => {
          setIsSendingTestEmail(false)
        }
      })
    }
  }

  const renderEditorFooter = (stepIndex: number, stepType: SequenceStepType | undefined): JSX.Element | null => {
    if (isNil(sequenceState) || isNil(sequenceState.sequenceSteps)) {
      return null
    }

    const suggestion = sequenceState.sequenceSteps[stepIndex].emailBodySuggestion
    const body = sequenceState.sequenceSteps[stepIndex].body
    if (isNil(body)) {
      return null
    }

    const hasShortBody = body.length < 50
    const hasSuggestion = !isNil(suggestion)

    const showFooter = hasSuggestion || hasShortBody
    if (!showFooter) {
      return null
    }

    if (stepType === SequenceStepType.MANUAL_TASK) {
      return null
    }

    return (
      <EmailSequenceSuggestion
        isGeneratingSuggestion={isSequenceGenerating}
        startCollpased={!isSequenceGenerating}
        // startCollpased={true}
        onOpenSuggestion={async () => {
          await handleGenerateStepSuggestion(stepIndex)
        }}
        onCloseSuggestion={async () => { }}
        onApplySuggestion={() => {
          handleConfirmStepSuggestion(true, stepIndex)
        }}
      >
        <When condition={isSequenceGenerating}>
          <Box $padding={{ top: 12, right: 12, bottom: 12, left: 12 }} $width='100%'>
            <LoadingSkeleton $variant="Text" />
          </Box>
        </When>
        <When condition={!isSequenceGenerating}>
          <Editor
            isEditable={false}
            initialContent={parseVariableToComponent(suggestion) ?? undefined}
            onDataChanged={() => {}}
            $minHeight="3rem"
            $editorHeight='fit-content'
          />
        </When>
      </EmailSequenceSuggestion>
    )
  }

  if (isLoading) {
    return <></>
  }

  return (
    <S.SequenceEditor key={reorderKey}>
      <DndContext
        // key={sequenceState.sequenceSteps?.map(step => `${step.id}-${step.position}`).join('-')}
        key={reorderKey}
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={() => { setIsReordering(true) }}
        onDragEnd={handleReorderEnd}
        modifiers={[restrictToParentElement]}
      >
        <S.Sidebar>
          <Flex $direction="column">
              <SortableContext items={sequenceState.sequenceSteps?.map(step => ({ id: step.id ?? step.position.toString() })) ?? []} strategy={verticalListSortingStrategy}>
                <S.StepSelectors $isEditable={editorIsEditable}>
                    {sequenceState.sequenceSteps?.map((step: EmailSequenceStep) => {
                      const { subject, placeholder } = getStepSubjectAndPlaceholder(step.position)

                      const stepTypeDisplay = step.type === SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE
                        ? <BrandIcon name='linkedinOriginal' size={12} color="fgSecondary" />
                        : step.type === SequenceStepType.MANUAL_TASK
                          ? <Icon name={Icons.penLine} size={12} color="fgSecondary" />
                          : <Icon name={Icons.mail} size={12} color="fgSecondary" />

                      const stepDescriptionText = (): string => {
                        if (step.type === SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE) {
                          return 'New LinkedIn Message'
                        }
                        if (step.type === SequenceStepType.MANUAL_TASK) {
                          return 'Manual Task'
                        }
                        if (step.position === 0) {
                          return 'New Email Thread'
                        }
                        return `${step.subject !== null ? 'New Email Thread' : 'Email Reply'} after ${step.waitDays} days`
                      }

                      return (
                        <Fragment key={`${step.id ?? step.position}-${reorderKey}`}>
                          <StepSelector
                            currentStep={currentStep}
                            step={step}
                            stepDescriptionText={stepDescriptionText()}
                            stepTypeDisplay={stepTypeDisplay}
                            subject={isNil(subject) ? `RE: ${convertSubjectToText(subject ?? placeholder)}` : convertSubjectToText(subject ?? placeholder)}
                            placeholder={placeholder}
                            onStepClick={onStepClick}
                            initialStepsAreGenerating={initialStepsAreGenerating}
                            reorderingDisabled={initialStepsAreGenerating || step.generationState === SequenceStepGenerationState.IN_PROGRESS || stepIsGenerating(step.position)}
                          />
                        </Fragment>
                      )
                    })}
                </S.StepSelectors>
              </SortableContext>
            <Flex $align='center' $justify='center'>
              <S.AddStepButton $isEditable={editorIsEditable}>
                <Button
                  $variant="outline"
                  $colorTheme="tint"
                  leadingIcon={Icons.plus}
                  $height={40}
                  $width='full'
                  $align='center'
                  $fontSize={12}
                  $borderRadius={6}
                  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                  disabled={isLoading || reachedMaxStepCount || !isReadyForNextStep() || atLeastOneStepIsBeingGenerated || userHasViewerRole}
                  onClick={async () => {
                    onStepClick(getNewPosition())

                    if (shouldAutoGenerateEmails) {
                      await handleGenerateStepSuggestion()
                    } else {
                      handleAddStep()
                    }
                  }}
                >
                  Add step
                </Button>
              </S.AddStepButton>
            </Flex>
            <S.SettingsAndOptionsBlock $isEditable={editorIsEditable}>
              <S.SettingsAndOptionsContent>
                <Paragraph size='XS'>Auto-archive if no reply</Paragraph>
                <Dropdown
                  trigger={
                    <Button
                      nested
                      disabled={userHasViewerRole}
                      $variant='outline'
                      $colorTheme='normal'
                      $fontSize={12}
                      $height={24}
                      $width='full'
                      trailingIcon={Icons.chevronsUpDownSmall}
                      $align='space-between'
                    >
                      After {sequenceState.autoArchiveAfterDays} days
                    </Button>
                  }
                  items={AutoArchiveAfterDays.map((days) => ({
                    id: days.toString(),
                    title: `${days} days`,
                    type: 'item',
                    onSelect: () => {
                      handleUpdateSequencePreferences({ dailyEmailLimit: sequenceState.dailyEmailLimit ?? 100, autoArchiveAfterDays: days })
                    }
                  }))}
                  selectedValue={sequenceState.autoArchiveAfterDays?.toString()}
                  menuPosition='start'
                  size='small'
                  disabled={userHasViewerRole}
                />
              </S.SettingsAndOptionsContent>
            </S.SettingsAndOptionsBlock>
          </Flex>
          <Flex $direction="column" $gap={16}>
            <Spacer $size={24} />
            <Paragraph size="XS" $color="fgTertiary">
              Candidates are auto-archived {sequenceState.autoArchiveAfterDays} days after the last email in the outreach sequence if no
              response.
            </Paragraph>
            <Flex $align="center" $gap={16}>
              {onBackClick && (
                <Button
                  leadingIcon={Icons.chevronLeft}
                  $variant="outline"
                  $colorTheme="muted"
                  $height={40}
                  $width="84px"
                  $minWidth="84px"
                  onClick={onBackClick}
                >
                  Back
                </Button>
              )}
              <Button
                // disabled={!editorIsEditable || isSequenceGenerating || isPending || Boolean(emailsBeingGenerated?.length) || !isReadyForNextStep() || hasEmptySequenceStep()}
                disabled={!editorIsEditable || isPending || !isReadyForNextStep() || hasEmptySequenceStep() || userHasViewerRole}
                $variant="fill"
                $colorTheme="tint"
                $height={40}
                $width="full"
                $align="center"
                onClick={async () => {
                  // if (pathname.includes('settings/job') && sequenceState.active) {
                  //   notify({
                  //     type: 'toast',
                  //     variant: 'negative',
                  //     position: 'bottom-right',
                  //     icon: 'x-octagon',
                  //     message: 'Active outreach sequences cannot be edited'
                  //   })
                  // } else {
                  await saveSequence({ closeAfterSaving: false })
                  setHasUnsavedChanges(false)
                  // }
                }}
              >
                Save outreach sequence
              </Button>
            </Flex>
          </Flex>
        </S.Sidebar>
        <S.StepsSection
          key={reorderKey}
          ref={scrollableStepsSectionEl}
          $isEditable={editorIsEditable}
          onClick={() => { setIsReordering(false) }}
        >
          {sequenceState.sequenceSteps?.map((step: EmailSequenceStep, index: number) => {
            return (
              <SequenceStep
                key={`${reorderKey}-${step.id}-${step.position}`}
                reorderKey={reorderKey}
                step={step}
                steps={sequenceState.sequenceSteps}
                getStepSubjectAndPlaceholder={getStepSubjectAndPlaceholder}
                initialStepBody={generatedEmails?.[step.position]?.body ?? undefined}
                totalSteps={
                  Array.isArray(sequenceState.sequenceSteps)
                    ? sequenceState.sequenceSteps.length - 1
                    : 3
                }
                isGenerating={
                  step.generationState === SequenceStepGenerationState.IN_PROGRESS || stepIsGenerating(step.position)
                }
                onDataChanged={(updatedStep) => {
                  if (isReordering) return
                  handleDataChange(updatedStep)
                }}
                onSendTestEmail={(step, recipients, candidateId) => {
                  void handleSendTestEmail(step, recipients, candidateId)
                }}
                isSendingTestEmail={isSendingTestEmail}
                onRemoveStep={() => {
                  onStepClick(step.position - 1)
                  handleRemoveStep(step)
                }}
                onReplyTypeUpdate={(value) => {
                  handleUpdateReplyType(step, value)
                }}
                onReorder={(newPosition) => {
                  console.log('reorder: ', newPosition)
                }}
                forceEditorFocus={step.position === currentStep && animationDone}
                emailSuggestionFooter={renderEditorFooter(index, step.type)}
              />
            )
          })}
        </S.StepsSection>
      </DndContext>
    </S.SequenceEditor>
  )
}
