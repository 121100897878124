import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { useParams } from 'react-router-dom'
import { createCandidatesFromCsvUpload } from 'src/libs/api/backend/candidate_jobs'
import type { CreateCandidatesFromCsvUploadArgs } from 'src/libs/api/backend/candidate_jobs'
import { queryKeys } from 'src/libs/query-keys'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'

interface Args extends CreateCandidatesFromCsvUploadArgs {
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  createCandidatesFromCsv: (args: Args) => void
}

export const useCreateCandidatesFromCsv = (): Res => {
  const queryClient = useQueryClient()
  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)
  const { jobId } = useParams()

  const mutation = useMutation({
    mutationFn: async (args: Args) => await createCandidatesFromCsvUpload(args),
    onError: (err, variables) => {
      console.error(err)
      notifyError({
        autoClose: false,
        message: `An error occurred while creating your candidate: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (_, variables) => {
      notifySuccess({
        message: 'Candidate successfully added'
      })
      if (variables.onSuccess) {
        variables.onSuccess()
      }
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobs, jobId]
      })
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobCounts, jobId]
      })
    }
  })

  const createCandidatesFromCsv = (args: Args): void => {
    mutation.mutate(args)
  }

  return { createCandidatesFromCsv }
}
