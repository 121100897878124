import { updateUserTrackingAndStatuses as updateUserTrackingAndStatusesHandler } from 'src/libs/api/backend/me'
import type { UpdateUserTrackingAndStatusesArgs } from 'src/libs/api/backend/me'
import { useMutation } from '@tanstack/react-query'
import { useSetSessionQueryCache } from '../use-session'

interface MutateArgs extends UpdateUserTrackingAndStatusesArgs {
}

interface Res {
  updateUserTrackingAndStatuses: (args: MutateArgs) => void
}

export const useUpdateUserTrackingAndStatuses = (): Res => {
  const setSessionQueryCache = useSetSessionQueryCache()

  const mutation = useMutation({
    mutationFn: async ({ productTourStatus, lastCollectedNpsFeedbackAt }: MutateArgs) => {
      await updateUserTrackingAndStatusesHandler({ productTourStatus, lastCollectedNpsFeedbackAt })
    },
    onSuccess: (_, { productTourStatus, lastCollectedNpsFeedbackAt }) => {
      setSessionQueryCache((old) => ({
        ...old,
        user: {
          ...old.user,
          productTourStatus,
          lastCollectedNpsFeedbackAt
        }
      }))
    }
  })

  const updateUserTrackingAndStatuses = (args: MutateArgs): void => {
    mutation.mutate(args)
  }

  return { updateUserTrackingAndStatuses }
}
