import { useEffect, useState } from 'react'
import { EditorContent, useEditor } from '@tiptap/react'
import type { Editor as TiptapEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import { mergeAttributes } from '@tiptap/core'
import * as S from './subject-editor.styled'
import { deserializeEditorContent } from './serializers'
import { useTheme } from 'styled-components'
import VariableComponentExtension from './extensions/variable-component-extension'
import { useSession } from 'src/hooks/use-session'

export interface SubjectEditorProps {
  isEditable?: boolean
  initialContent?: string | null
  onDataChanged: (data: string) => void
  forceEditorFocus?: boolean
  setEditor?: (editor: TiptapEditor | null) => void
  onBlur?: () => void
  content: string | null | undefined
  onFocusChange?: (isFocused: boolean) => void,
  placeholder?: string | null | undefined
}

export const SubjectEditor = ({
  isEditable = true,
  initialContent,
  onDataChanged,
  forceEditorFocus = false,
  setEditor,
  onBlur,
  content,
  onFocusChange,
  placeholder
}: SubjectEditorProps): JSX.Element => {
  const { colors } = useTheme()
  const [initialized, setInitialized] = useState(false)
  const { userHasViewerRole } = useSession()

  const editor = useEditor({
    onUpdate: ({ editor }) => {
      const output = editor.getHTML()
      onDataChanged(output)
    },
    onBlur: () => {
      onBlur?.()
      onFocusChange?.(false)
    },
    onFocus: () => {
      onFocusChange?.(true)
    },
    extensions: [
      StarterKit.configure({
        paragraph: false,
        dropcursor: {
          width: 3,
          color: colors.tintBg
        }
      }),
      // This converts using <div> instead of <p> for paragraphs
      Paragraph.extend({
        parseHTML () {
          return [{ tag: 'p' }, { tag: 'div' }]
        },
        renderHTML ({ HTMLAttributes }) {
          return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0] // eslint-disable-line
        }
      }),
      Link.configure({
        openOnClick: false,
        linkOnPaste: true,
        HTMLAttributes: {
          class: 'editor-link'
        }
      }),
      Placeholder.configure({
        placeholder: placeholder ?? 'Your subject line...'
      }),
      VariableComponentExtension
    ],
    editable: isEditable && !userHasViewerRole,
    content,
    onCreate: ({ editor }) => {
      setEditor?.(editor)
    }
  }, [isEditable])

  useEffect(() => {
    if (!editor) {
      return
    }

    if (((isEditable && !initialized) || !isEditable) && initialContent && initialContent?.length >= 1) {
      setInitialized(true)
      editor.commands.setContent(deserializeEditorContent(initialContent))
    }
  }, [editor, initialContent, initialized, isEditable])

  useEffect(() => {
    if (forceEditorFocus) {
      setTimeout(() => {
        editor?.commands.focus('end')
      }, 100)
    }
  }, [forceEditorFocus, editor])

  useEffect(() => {
    return () => {
      // Not sure why onDestroy often gets called after onCreate.
      // This makes sure it's destroyed when editor is unmounted
      setEditor?.(null)
    }
  }, [setEditor])

  return (
    <S.Editor
      $isEditable={isEditable}
      onClick={() => {
        if (!editor?.isFocused) {
          editor?.commands.focus()
        }
      }}
    >
      <S.EditorContent
        $padding='6px 0px'
        $editorHeight='100%'
        $isEditable={isEditable}
        $isEmpty={!!editor?.isEmpty}
      >
        <EditorContent editor={editor} />
      </S.EditorContent>
    </S.Editor>
  )
}
