import { createBrowserRouter, Navigate } from 'react-router-dom'
import RouteBuilder from 'src/libs/route-builder'
import Sentry from './utils/sentry'

import {
  Root,
  ErrorPage,
  Login,
  LoginRedirect,
  Invite,
  InviteRedirect,
  OnboardingCompanyProfile,
  OnboardingJoinTeam,
  OnboardingJobPosition,
  OnboardingConnectedAccounts,
  OnboardingInviteTeam,
  JobCandidatesPage,
  JobCandidatesSourcingPage,
  JobCandidatesInSequencePage,
  JobCandidatesArchivedPage,
  JobInboxPage,
  JobInboxCandidatePage,
  ReportsCandidatesPage,
  ReportsCommunicationsPage,
  JobSettingsSequencePage,
  // SettingsProfilePage,
  // SettingsTeamDetailsPage,
  // SettingsTeamMembersPage,
  // SettingsConnectedAccountsPage,
  // SettingsUserNotifications,
  JobSettingsDetailsPage,
  JobCandidatesShortlistedPage,
  JobSettingsCompaniesRatingsPage,
  AutomationSettingsPage,
  NewJobSearchRefinementPage,
  OnboardingExtension,
  GenerateErrorPage,
  JobCandidatesOutreachErrorsPage,
  OpenSettingsDialogPage,
  JobSettingsCalendarPreferencesPage,
  JobSettingsEmailPreferencesPage,
  TrialExpiredPage,
  ConnectToLinkedInPage,
  ApplicantsCandidatesPage,
  ReadyToSendPage
} from 'src/pages/index'
import {
  OnboardingLayout,
  DashboardLayout,
  SettingsLayout,
  InboxLayout,
  DefaultLayout
} from 'src/components/layouts'

import {
  rootLoader,
  dashboardLoader,
  jobLoader,
  automationLoader,
  emailPreferencesLoader,
  sourcingLoader,
  applicantsCandidatesLoader,
  shortlistedLoader
} from 'src/libs/loaders'
import LoginRedirectClose from './pages/login-redirect-close'
import JobCandidatesShortlistedTablePage from './pages/job/candidates/shortlisted-table'
import { SubscriptionStatus } from './components/blocks/subscription-status/subscription-status'
import { SubscriptionReturnPage } from './components/layouts/subscription-return-page'
import { inOutreachErrorsLoader, inOutreachLoader, readyToSendLoader } from './libs/loaders/in-outreach'
import Logout from './pages/logout'
import OnboardingWelcome from './pages/onboarding/welcome'
import OnboardingScheduleDemo from './pages/onboarding/schedule-demo'
import OnboardingSuccess from './pages/onboarding/success'
import OnboardingLocationNotice from './pages/onboarding/location-notice'
import MeetingBooked from './pages/meeting-booked'
import { trialExpiredLoader } from './libs/loaders/trial-expired'
import JobCandidatesCreateSequencePage from './pages/job/candidates/create-sequence'
import AllJobsInboxPage from './pages/all/inbox'

const routes = [
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    loader: rootLoader,
    children: [
      { path: '/login', element: <Login />, index: true },
      { path: '/login/redirect', element: <LoginRedirect /> },
      { path: '/login/redirect/close', element: <LoginRedirectClose /> },
      { path: '/logout', element: <Logout /> },
      { path: '/invite/:inviteId/:token', element: <Invite /> },
      { path: '/invite-redirect/:inviteId/:token', element: <InviteRedirect /> },
      { path: '/meeting-booked', element: <MeetingBooked /> },
      {
        path: '/onboarding',
        element: <OnboardingLayout />,
        children: [
          { path: 'location-notice', element: <OnboardingLocationNotice />, index: true },
          { path: 'company-profile', element: <OnboardingCompanyProfile />, index: true },
          { path: 'join-team', element: <OnboardingJoinTeam />, index: true },
          { path: 'job-position', element: <OnboardingJobPosition /> },
          { path: 'connected-accounts', element: <OnboardingConnectedAccounts /> },
          { path: 'invite-team', element: <OnboardingInviteTeam /> },
          { path: 'browser-extension', element: <OnboardingExtension /> },
          { path: 'schedule-demo', element: <OnboardingScheduleDemo /> },
          { path: 'success', element: <OnboardingSuccess /> },
          // Mobile only routes:
          { path: 'welcome', element: <OnboardingWelcome /> }
        ]
      },
      {
        path: '/generate_error',
        element: <GenerateErrorPage />
      },
      {
        path: '/expired_trial',
        loader: trialExpiredLoader,
        element: <TrialExpiredPage />
      },
      {
        path: '/all',
        element: <DashboardLayout />,
        children: [
          {
            path: 'reports',
            // element: <AllJobsReportsPage />,
            element: <DefaultLayout />,
            children: [
              {
                path: '',
                index: true,
                element: <Navigate to="candidates" />
              },
              {
                path: 'candidates',
                element: <ReportsCandidatesPage />
              },
              {
                path: 'communications',
                element: <ReportsCommunicationsPage />
              }
            ]
          },
          {
            path: 'inbox',
            element: <DefaultLayout />,
            children: [
              {
                path: '',
                index: true,
                element: <AllJobsInboxPage />
              },
              {
                path: 'candidate_jobs',
                children: [
                  {
                    path: '',
                    index: true,
                    element: <AllJobsInboxPage />
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: '/jobs',
        loader: dashboardLoader,
        element: <DashboardLayout />,
        children: [
          {
            path: ':jobId',
            loader: jobLoader,
            children: [
              {
                path: '',
                index: true,
                element: <Navigate to="candidates" />
              },
              {
                path: 'applicants',
                index: true,
                loader: applicantsCandidatesLoader,
                element: <ApplicantsCandidatesPage />
              },
              {
                path: 'candidates',
                children: [
                  { path: '', index: true, element: <Navigate to="sourcing" /> },
                  {
                    path: 'sourcing',
                    children: [
                      {
                        path: '',
                        index: true,
                        loader: sourcingLoader
                      },
                      {
                        path: 'manual',
                        index: true,
                        element: <JobCandidatesSourcingPage isManualSourceView={true} />
                      },
                      {
                        path: 'shortlisted',
                        loader: shortlistedLoader,
                        element: <JobCandidatesShortlistedPage />
                      },
                      {
                        path: 'search',
                        children: [
                          {
                            path: '',
                            index: true,
                            element: <NewJobSearchRefinementPage />
                          }
                        ]
                      },
                      {
                        path: ':jobSearchRefinementId',
                        children: [
                          {
                            path: '',
                            index: true,
                            element: <JobCandidatesSourcingPage />
                          }
                        ]
                      }
                    ]
                  },
                  {
                    path: 'in-sequence',
                    children: [
                      {
                        path: 'errors',
                        index: true,
                        loader: inOutreachErrorsLoader,
                        element: <JobCandidatesOutreachErrorsPage />
                      },
                      {
                        path: 'ready-to-send',
                        index: true,
                        loader: readyToSendLoader,
                        element: <ReadyToSendPage />
                      },
                      {
                        path: '',
                        index: true,
                        loader: inOutreachLoader,
                        element: <JobCandidatesInSequencePage />
                      },
                      {
                        path: 'create-sequence',
                        index: true,
                        element: <JobCandidatesCreateSequencePage />
                      }
                    ]
                  },
                  {
                    path: 'all',
                    children: [
                      {
                        path: '',
                        index: true,
                        element: <JobCandidatesPage heading="All Candidates" />
                      },
                      {
                        path: 'shortlisted',
                        element: <JobCandidatesShortlistedTablePage />
                      },
                      {
                        path: 'archived',
                        element: <JobCandidatesArchivedPage />
                      }
                    ]
                  }
                ]
              },
              {
                path: 'inbox',
                children: [
                  {
                    path: '',
                    index: true,
                    element: <JobInboxPage />
                  },
                  {
                    path: 'candidate_jobs',
                    children: [
                      {
                        path: '',
                        // index: true,
                        element: <JobInboxPage />
                      },
                      {
                        path: ':candidateJobId',
                        element: <InboxLayout />,
                        children: [
                          {
                            path: '',
                            index: true,
                            element: <JobInboxCandidatePage />
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                path: 'reports',
                element: <DefaultLayout />,
                children: [
                  {
                    path: '',
                    index: true,
                    element: <Navigate to="candidates" />
                  },
                  {
                    path: 'candidates',
                    element: <ReportsCandidatesPage />
                  },
                  {
                    path: 'communications',
                    element: <ReportsCommunicationsPage />
                  }
                ]
              },
              {
                path: 'automation',
                element: <DefaultLayout />,
                children: [
                  {
                    path: '',
                    index: true,
                    element: <AutomationSettingsPage />
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: '/automation',
        loader: automationLoader
      },
      {
        path: '/settings',
        loader: dashboardLoader,
        element: <DashboardLayout />,
        children: [
          {
            path: 'job',
            children: [
              {
                path: ':jobId',
                element: <SettingsLayout />,
                children: [
                  {
                    path: 'details',
                    element: <JobSettingsDetailsPage />
                  },
                  {
                    path: 'email-sequence',
                    element: <JobSettingsSequencePage />
                  },
                  {
                    path: 'companies-ratings',
                    element: <JobSettingsCompaniesRatingsPage />
                  },
                  {
                    path: 'email-preferences',
                    element: <JobSettingsEmailPreferencesPage />
                  },
                  {
                    path: 'calendar-preferences',
                    element: <JobSettingsCalendarPreferencesPage />
                  }
                ]
              }
            ]
          },
          {
            path: 'email-preferences',
            loader: emailPreferencesLoader
          },
          {
            path: 'account/:settings',
            element: <OpenSettingsDialogPage />
          },
          {
            path: 'team/members', // this is a legacy route that is sent via emails
            element: <OpenSettingsDialogPage settings="team_members" />
          }
        ]
      },
      {
        path: 'subscribe',
        element: <SubscriptionReturnPage />,
        children: [
          {
            path: 'success',
            element: <SubscriptionStatus variant="success" />
          }
        ]
      },
      {
        path: 'integrations',
        children: [
          {
            path: 'linkedin',
            children: [
              {
                path: 'install-chrome-extension',
                element: <ConnectToLinkedInPage step="CHROME_EXTENSION" />
              },
              {
                path: 'connect',
                element: <ConnectToLinkedInPage step="CONNECT_TO_LINKEDIN" />
              }
            ]
          }
        ]
      }
    ]
  }
]

RouteBuilder.create(routes)
console.log('[Route Table]', RouteBuilder.routeTable)

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter
)

const router = sentryCreateBrowserRouter(routes, {
  future: {
    // Normalize `useNavigation()`/`useFetcher()` `formMethod` to uppercase
    v7_normalizeFormMethod: true
  }
})

export default router
