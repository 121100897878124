import * as Dialog from 'src/components/primitives/dialog'
import { MessageComposer } from 'src/components/blocks/message-composer'
import type { EmailData } from 'src/components/blocks/message-composer'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import type { EmailAccount } from 'src/libs/api/backend/users'
import { useSession } from 'src/hooks/queries/use-session'
import { Flex } from 'src/components/primitives/flex'
import { Button } from 'src/components/primitives/button'
import { useEffect, useMemo, useState } from 'react'
import { INVALID_CANDIDATE_JOB_MESSAGEABLE_STATUSES } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
// import type { OutboundEmail } from 'src/libs/api/backend/sequences'
// import { useSendOutboundEmail } from 'src/hooks/mutations/use-send-outbound-email'
import { useSendManualMessage } from 'src/hooks/mutations/use-send-manual-email'
import { When } from 'src/components/blocks/when'
import { Icon } from 'src/components/primitives/icon'
import { Spacer } from 'src/components/primitives/spacer'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { pluralize } from 'src/libs/pluralize'
import type { Spacing } from 'src/styles/theme/types'
import { useAtomValue, useSetAtom } from 'jotai'
import { isDialogOpenAtom, DialogId, controlDialogAtom, openAlertAtom } from 'src/stores/dialogs'
import { EditorMessageType, SequenceStepType } from 'src/libs/api/backend/sequences'
import { convertSubjectToText, parseComponentToVariable } from 'src/components/blocks/editor/extensions/variable-parser'
import { notifyAtom } from 'src/stores/notifications'

export interface WriteMessageDialogProps {
  preselectedMessageType?: EditorMessageType
  candidateJobs?: CandidateJobExpanded[]
}

export const WriteMessageDialog = ({ candidateJobs, preselectedMessageType = EditorMessageType.EMAIL }: WriteMessageDialogProps): JSX.Element => {
  const [dialogView, setDialogView] = useState<'WRITE' | 'CONFIRMATION'>('WRITE')
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.WRITE_MESSAGE), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const openAlert = useSetAtom(openAlertAtom)
  const notify = useSetAtom(notifyAtom)
  const [manualMessage, setManualMessage] = useState<EmailData & { candidateIds: string[] }>({
    subject: '',
    body: '',
    candidateIds: [],
    sendingUserId: '',
    sendingEmailAccountId: '',
    sendingEmailAlias: null,
    sendingLinkedInAccountId: null,
    recipients: candidateJobs ?? [],
    type: preselectedMessageType === EditorMessageType.LINKEDIN_MAIL ? SequenceStepType.MANUAL_LINKEDIN_MESSAGE : SequenceStepType.MANUAL_EMAIL
  })
  // const { sendEmail } = useSendOutboundEmail()
  const { sendManualMessage } = useSendManualMessage()
  const [isSendable, setIsSendable] = useState(false)

  // const selectedMessageType = useMemo(() => {
  //   if (preselectedMessageType) {
  //     return preselectedMessageType
  //   }

  //   if (manualMessage.type === SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE ||
  //       manualMessage.type === SequenceStepType.MANUAL_LINKEDIN_MESSAGE) {
  //     return EditorMessageType.LINKEDIN_MAIL
  //   }
  //   return EditorMessageType.EMAIL
  // }, [manualMessage.type, preselectedMessageType])

  useEffect(() => {
    const invalidStatus = INVALID_CANDIDATE_JOB_MESSAGEABLE_STATUSES
    const validCandidateIds = candidateJobs
      ?.filter(
        (candidateJob) =>
          candidateJob.statusDisplay?.status &&
          !invalidStatus.includes(candidateJob.statusDisplay?.status)
      )
      .map((candidateJob) => candidateJob.candidateId)
    if (validCandidateIds) {
      setManualMessage((prev) => {
        const updated = {
          ...prev,
          candidateIds: validCandidateIds
        }
        return updated
      })
    }
  }, [candidateJobs, setManualMessage])

  const { data: sessionData } = useSession()
  const { data: orgUsers } = useOrgUsersQuery()
  const userEmailAccounts = orgUsers?.flatMap((user) => user.emailAccounts) ?? []

  const getSendingAccount = (): EmailAccount => {
    return (
      userEmailAccounts.find((account) => account.userId === sessionData?.user?.id) ??
      userEmailAccounts.find(account => account.isPrimary) ??
      userEmailAccounts[0]
    )
  }

  const DIALOG_PADDING = {
    WRITE: {
      top: 0 as Spacing,
      right: 0 as Spacing,
      bottom: 0 as Spacing,
      left: 0 as Spacing
    },
    CONFIRMATION: {
      top: 24 as Spacing,
      right: 24 as Spacing,
      bottom: 24 as Spacing,
      left: 24 as Spacing
    }
  }

  const handleCloseDialog = (): void => {
    const userHasChanges = (): boolean => {
      // Ideally, we'd use something like `isEqual` here, but depending on the
      // use case and how the editor updates, it might return an empty <p> tag
      // which would return true, even if there have not been any "real" changes.
      const detectChanges = (input: string): boolean => {
        return input !== '<p></p>' && input !== '<p> </p>' && input !== ''
      }
      const bodyHasChanges = detectChanges(manualMessage.body ?? '')
      const subjectHasChanges = detectChanges(manualMessage.subject ?? '')
      return dialogView === 'WRITE' && (bodyHasChanges || subjectHasChanges)
    }

    if (userHasChanges()) {
      openAlert({
        message: 'Are you sure you want to leave this page?',
        description: 'All your changes will be lost.',
        cancelText: 'Stay on page',
        confirmText: 'Discard and leave',
        onConfirm: () => {
          controlDialog({ id: DialogId.WRITE_MESSAGE, newState: false })
        }
      })
    } else {
      controlDialog({ id: DialogId.WRITE_MESSAGE, newState: false })
    }
  }

  return (
    <Dialog.Root
      id={DialogId.WRITE_MESSAGE}
      isOpen={isDialogOpen}
      onOpenChange={() => {
        handleCloseDialog()
      }}
      $width={dialogView === 'WRITE' ? 'half' : '320px'}
      $maxWidth="640px"
      $innerPadding={DIALOG_PADDING[dialogView]}
    >
      <Dialog.Portal>
        <When condition={dialogView === 'WRITE'}>
          <Dialog.Header
            title={preselectedMessageType === EditorMessageType.LINKEDIN_MAIL ? 'New LinkedIn Message' : 'New Email'}
            onClose={() => {
              handleCloseDialog()
            }}
          />
        </When>
        <Dialog.Content>
          <When condition={dialogView === 'WRITE'}>
            <MessageComposer
              // preselectedMessageType={selectedMessageType}
              currentData={manualMessage}
              forceEditorFocus
              onSendableStateChange={(sendable) => {
                setIsSendable(sendable)
              }}
              selectableAccounts={preselectedMessageType === EditorMessageType.LINKEDIN_MAIL ? ['LINKEDIN'] : ['EMAIL']}
              onDataChanged={(data) => {
                setManualMessage((prev) => {
                  return {
                    ...prev,
                    subject: data.subject ?? '',
                    body: data.body ?? '',
                    sendingUserId: data.sendingUserId ?? getSendingAccount()?.userId,
                    sendingEmailAccountId: data.sendingEmailAccountId ?? getSendingAccount()?.id,
                    sendingEmailAlias: data.sendingEmailAlias ?? null,
                    sendingLinkedInAccountId: data.sendingLinkedInAccountId ?? null,
                    recipients: data.recipients ?? prev.recipients ?? [],
                    cc: data.cc ?? [],
                    bcc: data.bcc ?? [],
                    attachmentUploads: data.attachmentUploads ?? [],
                    type: data.type ?? SequenceStepType.AUTOMATED_EMAIL
                  }
                })
              }}
              minHeight="16rem"
              useVariables={true}
              useAttachments={true}
              leftActions={
                <Button
                  $variant="flat"
                  $colorTheme="tint"
                  $height={32}
                  disabled={!isSendable}
                  onClick={() => {
                    const parsedBody = parseComponentToVariable(manualMessage.body)
                    const cleanedSubject = convertSubjectToText(manualMessage.subject)
                    if (!manualMessage.candidateIds?.length || !manualMessage.recipients?.length) {
                      notify({
                        type: 'toast',
                        variant: 'warning',
                        position: 'bottom-right',
                        icon: 'alert-triangle',
                        autoClose: true,
                        message: 'Please select at least one candidate to send a message to'
                      })
                      return
                    }
                    sendManualMessage({
                      manualMessage: {
                        ...manualMessage,
                        candidateIds: manualMessage.candidateIds,
                        subject: cleanedSubject ?? '',
                        body: parsedBody ?? '',
                        sendingUserId: manualMessage.sendingUserId ?? getSendingAccount()?.userId,
                        sendingEmailAccountId: manualMessage.sendingEmailAccountId ?? getSendingAccount()?.id,
                        sendingEmailAlias: manualMessage.sendingEmailAlias ?? null,
                        sendingLinkedInAccountId: manualMessage.sendingLinkedInAccountId ?? null,
                        cc: manualMessage.cc ?? [],
                        bcc: manualMessage.bcc ?? [],
                        attachmentUploads: manualMessage.attachmentUploads ?? []
                      },
                      messageType: preselectedMessageType,
                      onSuccess: () => {
                        setDialogView('CONFIRMATION')
                      }
                    })
                  }}
                >
                  {manualMessage.sendingLinkedInAccountId ? 'Send InMail' : 'Send Email'}
                </Button>
              }
            />
          </When>
          <When condition={dialogView === 'CONFIRMATION'}>
            <>
              <Icon name="send" size={40} color="fgFaded10" />
              <Spacer $size={24} />
              <Caption size="LG">Done</Caption>
              <Spacer $size={16} />
              <Paragraph size="SM">
                {pluralize(manualMessage.candidateIds.length, 'message')}{' '}
                {manualMessage.candidateIds.length >= 2 ? 'were' : 'was'} sent successfully to
                your candidates.
              </Paragraph>
              <Spacer $size={24} />
              <Flex $gap={16}>
                <Button
                  $variant="fill"
                  $colorTheme="tint"
                  $width="full"
                  $height={40}
                  $align="center"
                  onClick={() => {
                    controlDialog({ id: DialogId.WRITE_MESSAGE, newState: false })
                  }}
                >
                  Close
                </Button>
              </Flex>
            </>
          </When>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
