import { Button } from 'src/components/primitives/button'
import { Icon, Icons } from 'src/components/primitives/icon'
import * as S from './hiring-for-settings-styled'
import { useGetDepartmentQuery } from 'src/hooks/queries/use-departments'
import { useProjectsQuery } from 'src/hooks/queries/use-projects'
import { Flex } from 'src/components/primitives/flex'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { groupBy, isNil } from 'lodash'
import { useSession } from 'src/hooks/use-session'
import { useDeleteProject } from 'src/hooks/mutations/use-delete-project'
import { DepartmentLogo } from 'src/components/blocks/department-logo'
import { When } from 'src/components/blocks/when'
import { useSetAtom } from 'jotai'
import { DialogId, openAlertAtom, openDialogAtom } from 'src/stores/dialogs'

export const ProjectSettings = (): JSX.Element => {
  const { org } = useSession()
  const openDialog = useSetAtom(openDialogAtom)
  const openAlert = useSetAtom(openAlertAtom)
  const { departments = [] } = useGetDepartmentQuery()
  const { data: projects = [] } = useProjectsQuery()
  const { deleteProject } = useDeleteProject()
  const { userHasViewerRole } = useSession()

  const activeProjects = projects.filter(p => !p.deleted)
  const groupedProjects = {
    org: activeProjects.filter(p => p.departmentId === null),
    ...groupBy(activeProjects.filter(p => p.departmentId !== null), 'departmentId')
  }

  return (
    <S.HiringForContainer>
      {Object.entries(groupedProjects).map(([departmentId, departmentProjects]) => (
        <S.DepartmentsListGroup key={departmentId || 'no-department'}>
          <S.DepartmentsListHeader>
            <Flex $align='center' $gap={12}>
              <DepartmentLogo $size={16} departmentId={departmentId} />
              <Caption size='SM' $color="fgSecondary">
                {departmentId === 'org'
                  ? (org?.name ?? 'No Department')
                  : departments.find(dept => dept.id === departmentId)?.name ?? 'Unknown Department'}
              </Caption>
            </Flex>
            <Button
              leadingIcon={Icons.plus}
              $variant='ghost'
              $colorTheme='tint'
              onClick={() => {
                openDialog({
                  id: DialogId.CREATE_PROJECT,
                  payload: departmentId === 'org' ? undefined : departmentId
                })
              }}
              $width={124}
              $height={24}
              $fontSize={12}
              disabled={userHasViewerRole}
            >
              New Project
            </Button>
          </S.DepartmentsListHeader>
          <S.DepartmentsList>
          <When condition={projects.length === 0}>
            <S.EmptyState>
              <Icon name={Icons.binoculars} size={24} color="fgTertiary" />
              <Caption size='SM' $color="fgSecondary">
                You don't have any projects for this department yet
              </Caption>
            </S.EmptyState>
          </When>
            <When condition={departmentProjects.length > 0}>
              {departmentProjects.map((project) => (
                <S.DepartmentDetails key={project.id}>
                  <Flex $gap={10} $align='center' $width='auto' $flex='1 1 auto'>
                    <S.Icon>
                      <Icon name={Icons.squareGanttChart} size={12} color="fgTertiary" />
                    </S.Icon>
                    <Flex $direction='column' $flex='1 1 auto'>
                      <Caption size='SM'>{project.name}</Caption>
                      <Paragraph size='XS'>{isNil(project.departmentId) ? org?.domain : departments.find(dept => dept.id === project.departmentId)?.domain}</Paragraph>
                    </Flex>
                  </Flex>
                  <Flex $width='fit-content' $gap={12}>
                    <Button
                      $width={24}
                      $height={24}
                      leadingIcon={Icons.penLine}
                      $variant='ghost'
                      $colorTheme='muted'
                      $fontSize={12}
                      ariaLabel='Edit Project'
                      onClick={() => {
                        openDialog({
                          id: DialogId.EDIT_PROJECT,
                          payload: project
                        })
                      }}
                      disabled={userHasViewerRole}
                    />
                    <Button
                      $width={24}
                      $height={24}
                      leadingIcon={Icons.trash}
                      $variant='ghost'
                      $colorTheme='muted'
                      $fontSize={12}
                      ariaLabel='Delete Project'
                      onClick={() => {
                        openAlert({
                          message: 'Are you sure you want to delete this project?',
                          confirmText: 'Delete',
                          onConfirm: () => {
                            deleteProject({
                              id: project.id,
                              deleted: !project.deleted
                            })
                          }
                        })
                      }}
                      disabled={userHasViewerRole}
                    />
                  </Flex>
                </S.DepartmentDetails>
              ))}
            </When>
          </S.DepartmentsList>
        </S.DepartmentsListGroup>
      ))}
    </S.HiringForContainer>
  )
}
