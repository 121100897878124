import { EmptyState } from 'src/components/blocks/empty-state'
import { PageHeader } from 'src/components/blocks/page-header'
import { SEO } from 'src/components/primitives/seo'

const AllJobsInboxPage = (): JSX.Element => {
  return (
    <>
      <SEO title="All Jobs • Inbox" />
      <PageHeader heading="All Jobs • Inbox" />
      <EmptyState
        $padding={{ top: 0, bottom: 24, left: 0, right: 0 }}
        heading="No Conversations"
        description="As you contact candidates, emails and LinkedIn messages will appear here in a unified team inbox."
        svg="archiveDots"
      />
    </>
  )
}

export default AllJobsInboxPage
