import { CompaniesRatings } from 'src/components/blocks/companies-ratings'
import { PageHeader } from 'src/components/blocks/page-header'
import { Button } from 'src/components/primitives/button'
import { Spacer } from 'src/components/primitives/spacer'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { SEO } from '../../../components/primitives/seo'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import { useSession } from 'src/hooks/use-session'

const CompaniesRatingsPage = (): JSX.Element => {
  const openDialog = useSetAtom(openDialogAtom)
  const { data: jobData } = useJobQuery()
  const { userHasViewerRole } = useSession()

  return (
    <>
      <PageHeader
        heading={`Companies Rating for ${jobData?.title} Job`}
        subheading="Rate companies to indicate if you'd like to see more or fewer candidates that worked at them."
        actions={
          <Button
            $variant="outline"
            $colorTheme="muted"
            $height={24}
            $fontSize={12}
            onClick={() => { openDialog({ id: DialogId.COPY_COMPANY_RATINGS }) }}
            disabled={userHasViewerRole}
          >
            Copy from Another Job
          </Button>
        }
      />
      <SEO title={'Company Ratings'} />

      <Spacer $size={20} />
      <CompaniesRatings />
    </>
  )
}

export default CompaniesRatingsPage
