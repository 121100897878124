import { motion } from 'framer-motion'
import styled from 'styled-components'

export const SurveyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
`

export const SurveyWrapperView = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 0;
  form {
    width: 100%;
  }
`

export const WhatAtsSurveyContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
`

export const HowLikelyRecommendSurveyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  max-width: 552px;
`

export const HowLikelyRecommendSurveyButtons = styled.div`
  display: flex;
  gap: 8px;
`

export const PinBenefits = styled.div`
  max-width: 600px;
  height: 220px;
`

export const PinBenefitsCard = styled(motion.div)`
  display: grid;
  grid-template-columns: minmax(0, 160px) 1fr;
  grid-column-gap: 1.5rem;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
`

export const CardVisual = styled.div`
  grid-column: 1;
  width: 100%;
  aspect-ratio: 1/0.75;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: bottom;
  }
`

export const CardContent = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
