import { NodeViewWrapper } from '@tiptap/react'
import type { NodeViewWrapperProps } from '@tiptap/react'
import { formatVariableName, EmailVariables, EmailVariable } from '../types'
import { Icon, Icons } from 'src/components/primitives/icon'
import { useMemo, useState } from 'react'
import * as RadixPopover from '@radix-ui/react-popover'
import styled, { css } from 'styled-components'
import { Caption } from 'src/components/primitives/typography'
import { Form, Textarea } from 'src/components/forms'
import { Button, Flex } from 'src/components/primitives'
import { z } from 'zod'
import { useForm } from 'src/hooks/use-form'
import { openAlertAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import { useAiGeneratedSentence } from 'src/hooks/mutations/use-ai-generated-sentence'
import { useSession } from 'src/hooks/use-session'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { isNil } from 'lodash'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { Box } from 'src/components/primitives/box'

const VariablePopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[4]};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.md};
  padding: ${({ theme }) => theme.spacing[12]};
  border-radius: ${({ theme }) => theme.spacing[4]};
  gap: ${({ theme }) => theme.spacing[8]};
  width: 32.5rem;
  textarea {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    outline: none;
  }
  div:first-child {
    margin-bottom: ${({ theme }) => theme.spacing[8]};
  }
  z-index: ${({ theme }) => theme.zindeces.dropdown};
`

const VariablePopoverTrigger = styled(RadixPopover.Trigger)`
  &[data-state='open'] {
    box-shadow: ${({ theme }) => theme.boxShadows['light-active']};
    border-radius: 4px;
  }
`

const PersonalizedText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  margin-left: 4px;
  margin-right: 4px;
`

const PersonalizedTextContainer = styled.span<{ $disabled?: boolean }>`
  cursor: pointer;
  white-space: nowrap !important;
  border-radius: ${({ theme }) => theme.spacing[4]};
  padding: ${({ theme }) => theme.spacing[0]} ${({ theme }) => theme.spacing[4]};
  background-color: ${({ theme }) => theme.colors.aiSolidTranslucent10};
  border: solid 1px ${({ theme }) => theme.colors.borderTranslucent};
  color: ${({ theme }) => theme.colors.aiSolidFg};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.aiSolidTranslucent25};
    }
  }
  ${({ $disabled }) => $disabled && css`
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  `}
`

const PersonalizedItemContainer = styled.span`
  vertical-align: middle;
`

const CustomSentencePreview = styled.div`
  background: ${({ theme }) => theme.backgrounds.aiGradientTranslucent10};
  border: 1px dashed ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: 8px;
  margin-bottom: 8px;
 `

const specialInstructionsSchema = z.object({
  specialInstructions: z.string().optional()
})

export type SpecialInstructionsSchema = z.infer<typeof specialInstructionsSchema>

export interface AiPersonalizedVariableComponentParams {
  personalizationInstructions: string
  setPersonalizationInstructions: (instructions: string | undefined) => void
}

const AiPersonalizedVariableComponent = ({
  personalizationInstructions,
  setPersonalizationInstructions
}: AiPersonalizedVariableComponentParams): JSX.Element => {
  const openAlert = useSetAtom(openAlertAtom)
  const [isOpen, setIsOpen] = useState(false)
  const [customAiSentencePreview, setCustomAiSentencePreview] = useState<string | null>(null)
  const { userHasViewerRole } = useSession()

  const { generateAiSentence, isGeneratingAiSentence } = useAiGeneratedSentence()
  const { org } = useSession()
  const { data: job } = useJobQuery()
  const { data: candidateJobs } = useCandidateJobsQuery()

  const { submit, register, setValue, formData } = useForm<SpecialInstructionsSchema>({
    schema: specialInstructionsSchema,
    initialValues: {
      specialInstructions: personalizationInstructions
    }
  })

  const [initialPersonalizationInstructions, setInitialPersonalizationInstructions] = useState<string | undefined>(personalizationInstructions)

  const handleSubmit = (formData: SpecialInstructionsSchema): void => {
    setPersonalizationInstructions(formData.specialInstructions)
    setInitialPersonalizationInstructions(formData.specialInstructions)
    setIsOpen(false)
  }

  const handleCancel = (): void => {
    setValue('specialInstructions', initialPersonalizationInstructions)
    setCustomAiSentencePreview(null)
    setIsOpen(false)
  }

  const isDirty = useMemo(() => {
    return formData.specialInstructions !== personalizationInstructions
  }, [formData.specialInstructions, personalizationInstructions])

  return (
    <RadixPopover.Root open={isOpen} onOpenChange={(value: boolean) => {
      if (!value && isDirty) {
        openAlert({
          message: 'Are you sure you want to discard your changes?',
          description: 'Your new instructions will be lost.',
          cancelText: 'Cancel',
          confirmText: 'Discard',
          onConfirm: () => {
            handleCancel()
          }
        })
      } else {
        setIsOpen(value)
      }
    }}>
      <VariablePopoverTrigger asChild disabled={userHasViewerRole}>
        <PersonalizedTextContainer
          contentEditable={false}
          $disabled={userHasViewerRole}
        >
          <PersonalizedItemContainer>
            <Icon name={Icons.sparklesSolid} size={12} color='aiSolidFg' />
          </PersonalizedItemContainer>
          <PersonalizedText>{formatVariableName(EmailVariable.AI_PERSONALIZED_SENTENCE)}</PersonalizedText>
          <PersonalizedItemContainer>
            <Icon name={Icons.chevronDown} size={12} color='aiSolidBg' />
          </PersonalizedItemContainer>
        </PersonalizedTextContainer>
      </VariablePopoverTrigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          asChild
          side='bottom'
          align='start'
          sideOffset={4}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <VariablePopoverContent>
            <Caption size='XS' $color='fgSecondary'>Special instructions</Caption>
            <Form onSubmit={submit(handleSubmit)}>
              <Textarea
                name="specialInstructions"
                label="Special instructions"
                placeholder="e.g. Mention and praise their experience working at a company related to this company or position."
                hiddenLabel
                register={register}
                autoFocus
                rows={3}
              />

              {!isNil(customAiSentencePreview) &&
                <CustomSentencePreview>
                  <Box $padding={{ top: 8, bottom: 0, left: 12, right: 12 }}>
                    <Caption size='XS' $fontWeight={400}>
                      {customAiSentencePreview}
                    </Caption>
                  </Box>
                </CustomSentencePreview>}

              <Flex $direction='row' $gap={8} $align='center' $justify='space-between'>
                <Flex $direction='row' $gap={8}>
                  <Button
                    $variant="raised"
                    $colorTheme="tint"
                    type="submit"
                    $height={24}
                    $fontSize={12}
                    disabled={!formData.specialInstructions || !isDirty}
                  >
                    Save
                  </Button>
                  <Button
                    $variant="outline"
                    $colorTheme="muted"
                    type="button"
                    $height={24}
                    $fontSize={12}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Flex>
                <Button
                  $variant="outline"
                  $colorTheme="muted"
                  type="button"
                  $height={24}
                  $width="66px"
                  $fontSize={12}
                  loading={isGeneratingAiSentence}
                  onClick={() => {
                    if (isNil(org) || isNil(job) || isNil(formData.specialInstructions)) {
                      return
                    }

                    generateAiSentence({
                      orgId: org.id,
                      jobId: job.id,
                      candidateId: candidateJobs?.at(0)?.candidateId,
                      personalizationInstructions: String(formData.specialInstructions),
                      onSuccess: (aiGeneratedSentence: string) => {
                        setCustomAiSentencePreview(aiGeneratedSentence)
                      }
                    })
                  }}
                >
                  Preview
                </Button>
              </Flex>
            </Form>
          </VariablePopoverContent>
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  )
}

export const VariableComponent = (props: NodeViewWrapperProps): JSX.Element | null => {
  const { variable, aiinstructions } = props.node.attrs

  if (!variable || !EmailVariables.includes(variable as EmailVariable)) {
    return null
  }
  if (variable === EmailVariable.AI_PERSONALIZED_SENTENCE) {
    return (
      <NodeViewWrapper className='editor-variable-block' as='span'>
        <AiPersonalizedVariableComponent
          personalizationInstructions={aiinstructions}
          setPersonalizationInstructions={(aiinstructions: string | undefined) => {
            props.updateAttributes({ aiinstructions })
          }}
        />
      </NodeViewWrapper>
    )
  }
  return (
    <NodeViewWrapper className='editor-variable-block' as='span'>
      <span
        contentEditable={false}
        className='editor-variable'
      >
        {formatVariableName(variable as EmailVariable)}
      </span>
    </NodeViewWrapper>
  )
}
