import {
  SequenceStep,
  Sidebar,
  StepsSection
} from 'src/components/blocks/sequence-editor/sequence-editor.styled'
import { CANDIDATES_PAGES_MAX_WIDTH, CONTENT_PADDING, HEADER_PADDING } from 'src/styles/constants'
import styled from 'styled-components'

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: ${CANDIDATES_PAGES_MAX_WIDTH};
  padding: ${HEADER_PADDING} ${CONTENT_PADDING};
  padding-bottom: ${CONTENT_PADDING};
`

export const SequenceContentWrapper = styled(ContentWrapper)`
  max-width: ${CANDIDATES_PAGES_MAX_WIDTH};
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const SequenceWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 1px;
  padding-bottom: ${CONTENT_PADDING};
  /* overflow: auto; */

  ${StepsSection} {
    padding: 0;
  }

  ${SequenceStep} {
    padding-top: 1px;
  }

  ${Sidebar} {
    padding-top: 1px;
    padding-bottom: 0;
  }
`
