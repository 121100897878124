import * as S from './add-candidate-form.styled'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import { Button, Flex, Spacer } from 'src/components/primitives'
import { z } from 'zod'
import { isNil } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { closeDialogAtom, DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useCreateCandidateByIdentifier } from 'src/hooks/mutations/use-create-candidate-by-identifier'
import { useCreateCandidatesFromCsv } from 'src/hooks/mutations/use-create-candidates-from-csv'
import { pluralize } from 'src/libs/pluralize'
import { useSetAtom } from 'jotai'
import { Form } from 'src/components/forms'
import { useForm } from 'src/hooks/use-form'
import type { FieldCommonProps } from 'src/components/forms/common'
import type { AddCandidatesState } from './type'
import { Checkbox } from 'src/components/forms/checkbox'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import { CreateSequenceDialogView } from 'src/constants'

const candidateDestinationSchema = z.object({
  stage: z.nativeEnum(CandidateJobStage),
  favorite: z.boolean()
})

type CandidateDestinationSchema = z.infer<typeof candidateDestinationSchema>

interface DestinationSelectionProps {
  selected: boolean
  onClick: () => void
  label: string
  description: string
}

const DestinationSelection = ({
  selected,
  onClick,
  label,
  description
}: DestinationSelectionProps): JSX.Element => {
  return (
    <S.DestinationSelection $selected={selected} onClick={onClick}>
      <Caption size="SM">{label}</Caption>
      <Paragraph size="SM">{description}</Paragraph>
    </S.DestinationSelection>
  )
}

interface AddCandidatesDestinationProps extends FieldCommonProps {
  isSequenceEmpty: boolean
}

const AddCandidatesDestination = ({
  register,
  name,
  isSequenceEmpty
}: AddCandidatesDestinationProps): JSX.Element => {
  const { onSelect, value } = register(name)
  const openDialog = useSetAtom(openDialogAtom)
  return (
    <Flex $direction="column" $gap={16} $width="100%">
      <DestinationSelection
        selected={value === CandidateJobStage.SOURCED}
        onClick={() => {
          onSelect(CandidateJobStage.SOURCED)
        }}
        label="Review in Sourcing"
        description="Add to Sourcing for a final review and approval."
      />
      <DestinationSelection
        selected={value === CandidateJobStage.PROSPECTING}
        onClick={() => {
          onSelect(CandidateJobStage.PROSPECTING)
          if (isSequenceEmpty) {
            openDialog({ id: DialogId.CREATE_SEQUENCE, payload: { defaultView: CreateSequenceDialogView.OPTIONS } })
          }
        }}
        label="Add to Outreach Sequence"
        description="Start outreach to candidates in the list"
      />
    </Flex>
  )
}

interface AddCandidateDestinationProps {
  jobId: string
  state: AddCandidatesState
}
export const AddCandidateDestination = ({
  jobId,
  state
}: AddCandidateDestinationProps): JSX.Element => {
  const { data: sequence } = useJobSequenceQuery(jobId)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const closeDialog = useSetAtom(closeDialogAtom)
  const { createCandidatesByIdentifier } = useCreateCandidateByIdentifier()
  const { createCandidatesFromCsv } = useCreateCandidatesFromCsv()

  const { register, submit } = useForm<CandidateDestinationSchema>({
    schema: candidateDestinationSchema,
    initialValues: {
      stage: CandidateJobStage.SOURCED,
      favorite: false
    }
  })

  const candidateIdentifiersCount = useMemo(() => {
    if (!isNil(state.validatedCandidateIdentifiers)) {
      const { linkedins } = state.validatedCandidateIdentifiers
      return linkedins.length
    }
    return 0
  }, [state.validatedCandidateIdentifiers])

  const handleSubmit = useCallback(async (data: CandidateDestinationSchema): Promise<void> => {
    setIsSubmitting(true)
    if (!isNil(state.uploadedFileS3Url)) {
      createCandidatesFromCsv({
        jobId,
        csvS3Key: state.uploadedFileS3Url,
        stage: data.stage,
        favorite: data.favorite,
        onError: () => {
          setIsSubmitting(false)
        },
        onSuccess: () => {
          closeDialog(DialogId.ADD_CANDIDATE)
          setIsSubmitting(false)
        }
      })
    }
    if (!isNil(state.validatedCandidateIdentifiers) && candidateIdentifiersCount > 0) {
      createCandidatesByIdentifier({
        jobId,
        linkedins: state.validatedCandidateIdentifiers.linkedins ?? [],
        stage: data.stage,
        favorite: data.favorite,
        onSuccess: () => {
          closeDialog(DialogId.ADD_CANDIDATE)
          setIsSubmitting(false)
        }
      })
    }
  }, [state.uploadedFileS3Url, state.validatedCandidateIdentifiers, candidateIdentifiersCount, createCandidatesFromCsv, jobId, closeDialog, createCandidatesByIdentifier])

  const candidatesToBeAdded = useMemo(() => {
    let total = candidateIdentifiersCount
    if (!isNil(state.validationResult)) {
      total += state.validationResult.validRows.length
    }
    return total
  }, [candidateIdentifiersCount, state.validationResult])

  return (
    <>
      <Caption size="SM">
        Where do you want to add these candidates?
      </Caption>
      <Spacer $size={24} />
      <Form onSubmit={submit(handleSubmit)}>
        <AddCandidatesDestination
          register={register}
          name="stage"
          isSequenceEmpty={isSequenceStepsEmpty(sequence)}
        />
        <Spacer $size={24} />
        <Checkbox
          label="Add all candidates to shortlist"
          register={register}
          name="favorite"
          $marginBottom={0}
          defaultChecked={false}
        />
        <S.ActionButtons>
          <Button
            type="submit"
            $variant="fill"
            $colorTheme="tint"
            $height={40}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Add {pluralize(candidatesToBeAdded, 'candidate')}
          </Button>
          <Button
            $variant="outline"
            $colorTheme="muted"
            $height={40}
            disabled={isSubmitting}
            onClick={() => {
              closeDialog(DialogId.ADD_CANDIDATE)
            }}
          >
            Cancel
          </Button>
        </S.ActionButtons>
      </Form>
    </>
  )
}
