import styled, { css } from 'styled-components'

export const ErrorDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[12]};
  padding: ${({ theme }) => theme.spacing[16]};
  border-radius: ${({ theme }) => theme.spacing[8]};
  background-color: ${({ theme }) => theme.colors.negativeTranslucent5};
  overflow-y: auto;
  max-height: 400px;
  scrollbar-width: thin;
`

export const RecommendationDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[12]};
  padding: ${({ theme }) => theme.spacing[16]};
  border-radius: ${({ theme }) => theme.spacing[8]};
  background-color: ${({ theme }) => theme.colors.tintTranslucent5};
`

export const CsvErrorList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[8]};
  padding-left: ${({ theme }) => theme.spacing[20]};
  li {
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes[12]};
  }
  li:first-letter {
    text-transform: capitalize;
    display: inline-block;
    vertical-align: center;
  }
`

export const CsvSummaryDetails = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[6]};
  padding: ${({ theme }) => theme.spacing[6]} ${({ theme }) => theme.spacing[12]};
  border-radius: ${({ theme }) => theme.spacing[6]};
  background-color: ${({ theme }) => theme.colors.positiveTranslucent5};
  margin-top: ${({ theme }) => theme.spacing[12]};
  min-height: ${({ theme }) => theme.spacing[30]};
`

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[16]};
  margin-top: ${({ theme }) => theme.spacing[24]};
`

export const DestinationSelection = styled.div<{ $selected: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing[4]};
  padding: ${({ theme }) => theme.spacing[16]};
  border-radius: ${({ theme }) => theme.spacing[6]};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  ${({ $selected, theme }) => $selected
    ? css`
      border: 1px solid ${theme.colors.bgTertiary};
      box-shadow: ${theme.boxShadows.focused};
    `
    : css`
      border: 1px solid ${theme.colors.bgSecondary};
      box-shadow: ${theme.boxShadows.sm};
    `}
`
