import * as Dialog from 'src/components/primitives/dialog'
import { Button } from 'src/components/primitives/button'
import { Flex } from 'src/components/primitives/flex'
import {
  CandidateEducationTimeline,
  CandidateEnhancedSummary,
  CandidateExperienceTimeline,
  CandidateProfileInline,
  CandidateTimeline
} from 'src/components/blocks/candidate-details'
import RouteBuilder from 'src/libs/route-builder'
import * as S from './candidate-details-dialog.styled'
import { useCandidateJobQuery } from 'src/hooks/queries/use-candidate-job'
import { CandidateEmailsList } from 'src/components/blocks/candidate-emails-list'
import { Spacer } from 'src/components/primitives'
import { CompaniesPreferencesProvider } from 'src/providers/companies-preferences'
import { useCandidateActivitiesQuery } from 'src/hooks/queries/use-candidate-activities'
import { useSession } from 'src/hooks/use-session'
import { useAtomValue, useSetAtom } from 'jotai'
import { candidateDetailsChannelAtom } from 'src/stores/websocket-channels'
import { controlDialogAtom, DialogId, isDialogOpenAtom, openDialogAtom } from 'src/stores/dialogs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryParams } from 'src/hooks/use-query-params'
import { Paragraph } from 'src/components/primitives/typography'
import { CandidatePhoneNumbersList } from 'src/components/blocks/candidate-phone-numbers-list'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { isNil } from 'lodash'
import { CandidatePhoneNumberLookupStage } from 'src/libs/api/backend/candidate_jobs'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { useInvalidateCandidateAfterPhoneLookup } from 'src/hooks/use-invalidate-candidate-after-phone-lookup'
import { PhoneNumberLookupButton } from 'src/components/blocks/phone-number-lookup-button'

interface CandidateDetailsDialogProps {
  candidateJobId: string
}

export const CandidateDetailsDialog = ({
  candidateJobId
}: CandidateDetailsDialogProps): JSX.Element => {
  const { resetParam } = useQueryParams()
  const { data: candidateJob } = useCandidateJobQuery({ candidateJobId })
  const { candidate, jobId } = candidateJob ?? {}
  const [inProgress, setInProgress] = useState<boolean | undefined>(undefined)
  const {
    data: candidateActivities
  } = useCandidateActivitiesQuery({
    candidateId: candidate?.id
  })
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.CANDIDATE_DETAILS), []))
  const openDialog = useSetAtom(openDialogAtom)
  const controlDialog = useSetAtom(controlDialogAtom)
  const { org, featureFlags, userHasViewerRole } = useSession()
  const candidateDetailsChannel = useAtomValue(candidateDetailsChannelAtom)

  useEffect(() => {
    if (isNil(inProgress) && candidateJob?.candidate.phoneNumberLookupStage) {
      // Only initialize inProgress if it's not already set
      setInProgress(candidateJob?.candidate.phoneNumberLookupStage === CandidatePhoneNumberLookupStage.IN_PROGRESS)
    }
  }, [candidateJob?.candidate.phoneNumberLookupStage, inProgress])

  useInvalidateCandidateAfterPhoneLookup({
    candidateDetailsChannel,
    candidateId: candidate?.id,
    candidateJobId,
    setInProgress
  })

  const handleOpenChange = useCallback((value: boolean) => {
    if (!value) {
      resetParam('candidate')
    }
    controlDialog({ id: DialogId.CANDIDATE_DETAILS, newState: value })
  }, [resetParam, controlDialog])

  const hasContactInfo = useMemo(() => {
    return (
      !!inProgress ||
      candidateJob?.candidate.phoneNumberLookupStage === CandidatePhoneNumberLookupStage.NOT_FOUND ||
      candidateJob?.candidate.phoneNumberLookupStage === CandidatePhoneNumberLookupStage.CREDITS_EXCEEDED ||
      (!isNil(candidateJob?.candidate.phoneNumbers) && candidateJob?.candidate.phoneNumbers.length > 0) ||
      (!isNil(candidateJob?.candidate.emails) && candidateJob?.candidate.emails.length > 0)
    )
  }, [
    inProgress,
    candidateJob?.candidate.phoneNumberLookupStage,
    candidateJob?.candidate.phoneNumbers,
    candidateJob?.candidate.emails
  ])

  const lookupPhoneNumberEnabled = useMemo(() => {
    return (
      featureFlags?.includes(FeatureFlags.PHONE_NUMBER_LOOKUP) &&
      (isNil(candidateJob?.candidate.phoneNumberLookupStage) ||
        candidateJob?.candidate.phoneNumberLookupStage === CandidatePhoneNumberLookupStage.CREDITS_EXCEEDED
      )
    )
  }, [featureFlags, candidateJob?.candidate.phoneNumberLookupStage])

  return (
    <Dialog.Root
      id="candidate-details"
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        handleOpenChange(value)
      }}
      $position="right"
      $width="560px"
      $maxWidth="560px"
      $height="almostFull"
      $innerPadding={{ top: 0, left: 0, right: 0, bottom: 0 }}
    >
      <CompaniesPreferencesProvider>
        <Dialog.Portal>
          <S.DialogHeader>
            <Flex $gap={16} $align="center">
              {/* <ButtonGroup $variant="raised" $gap={0}>
                <Button
                  ariaLabel="Previous candidate"
                  leadingIcon="chevron-up-thin"
                  $height={24}
                  $width={24}
                  $colorTheme="muted"
                />
                <Button
                  ariaLabel="Next candidate"
                  leadingIcon="chevron-down-thin"
                  $height={24}
                  $width={24}
                  $colorTheme="muted"
                />
              </ButtonGroup> */}
              <Button
                leadingIcon="inbox"
                $variant="outline"
                $colorTheme="muted"
                $height={24}
                $fontSize={12}
                href={RouteBuilder.build('JOBS_INBOX_CANDIDATE_JOBS', {
                  jobId: jobId ?? '',
                  candidateJobId
                })}
                onClick={() => {
                  handleOpenChange(false)
                }}
              >
                View on inbox
              </Button>
            </Flex>
            <Flex $gap={16} $align="center" $justify="flex-end">
              <Button
                leadingIcon="pen-line"
                $variant="ghost"
                $colorTheme="muted"
                $height={24}
                $fontSize={12}
                onClick={() => {
                  openDialog({ id: DialogId.EDIT_CANDIDATE, payload: candidateJob })
                }}
                disabled={userHasViewerRole}
              >
                Edit candidate
              </Button>
              <Button
                nested
                ariaLabel="Close"
                leadingIcon="x"
                $variant="ghost"
                $colorTheme="muted"
                $width={24}
                $height={24}
                onClick={() => {
                  handleOpenChange(false)
                }}
              />
            </Flex>
          </S.DialogHeader>
          <Dialog.Content>
            {!candidateJob || !candidate
              ? <S.DialogContent><LoadingSkeleton $variant="CandidateDetailsDialog" /></S.DialogContent>
              : <>
                  <S.DialogContent>
                    <Flex $gap={16} $direction="column">
                      <CandidateProfileInline org={org} candidateJob={candidateJob} avatarSize={40} includeSocialLinks={true} />
                      <CandidateEnhancedSummary candidateJob={candidateJob} $padding={{ top: 0, right: 0, bottom: 0, left: 0 }} />
                      {(lookupPhoneNumberEnabled || hasContactInfo) &&
                        <>
                          <Flex $direction="column" $gap={8}>
                            <Paragraph size="2XS" $color="fgTertiary">
                              CONTACT INFO
                            </Paragraph>
                            {hasContactInfo && (
                              <S.ContactInfoContainer>
                                <CandidateEmailsList candidateJob={candidateJob} />
                                <CandidatePhoneNumbersList candidateJob={candidateJob} isLookingUpPhoneNumbers={inProgress ?? false} />
                              </S.ContactInfoContainer>
                            )}
                            <PhoneNumberLookupButton
                              candidateJob={candidateJob}
                              inProgress={inProgress ?? false}
                              setInProgress={setInProgress}
                            />
                          </Flex>
                          <Spacer $size={0} />
                        </>
                      }
                      <CandidateExperienceTimeline
                        experiences={candidate.experiences}
                        $padding={{ left: 0, right: 0 }}
                      />
                      <CandidateEducationTimeline
                        educations={candidate.educations}
                        // isCollapsible
                        $padding={{ left: 0, right: 0 }}
                      />
                      <Spacer $size={0} />
                      {candidateActivities && candidateActivities.length > 0 &&
                        <CandidateTimeline
                          candidate={candidate}
                          candidateActivites={candidateActivities}
                          $padding={{ left: 0, right: 0 }}
                          candidateJob={candidateJob}
                        />
                      }
                    </Flex>
                  </S.DialogContent>
                </>
            }
          </Dialog.Content>
        </Dialog.Portal>
      </CompaniesPreferencesProvider>
    </Dialog.Root>
  )
}
