import { Flex } from 'src/components/primitives'
import type { CustomRequirementCriteria } from 'src/libs/api/backend/candidate_search'
import { CriteriaSelection } from './criteria-selection'
import * as S from './index.styled'
import { RemoveCriteria } from './remove-criteria'
import { Paragraph } from 'src/components/primitives/typography'
import { useMemo } from 'react'
import { CriteriaProperties } from '../constants'
import { Icons } from 'src/components/primitives/icon'

interface CustomRequirementRowProps {
  criteriaValue: CustomRequirementCriteria
  onUpdate: (key: string, value: string | boolean) => void
  onRemove: () => void
  disabled: boolean
  errored?: boolean
  limitedResults?: boolean
}

export const CustomRequirementRow = ({
  criteriaValue,
  onUpdate,
  onRemove,
  disabled,
  errored,
  limitedResults
}: CustomRequirementRowProps): JSX.Element => {
  const { convertedToCriterias = [], optional, requirement } = criteriaValue
  const fieldConverted = useMemo(() => {
    return convertedToCriterias && convertedToCriterias.length > 0
  }, [convertedToCriterias])
  const fieldErrored = useMemo(() => {
    if (errored) {
      return (
        <S.CustomRequirementWarning>
          <Paragraph size='XS' $color='negativeFg'>
            Criteria violates Pin guidelines
          </Paragraph>
        </S.CustomRequirementWarning>
      )
    }
    if (limitedResults) {
      return (
        <S.CustomRequirementWarning>
          <Paragraph size='XS' $color='warningFg'>
            Might reduce number of candidates considerably
          </Paragraph>
        </S.CustomRequirementWarning>
      )
    }
    if (fieldConverted) {
      return (
        <S.CustomRequirementWarning>
          <Paragraph size='XS' $color='fgSecondary'>
            We moved this criteria to the structured criteria {convertedToCriterias.map((c) => CriteriaProperties.get(c)?.label).join(', ')}
          </Paragraph>
        </S.CustomRequirementWarning>
      )
    }
    return null
  }, [convertedToCriterias, errored, fieldConverted, limitedResults])
  return (
    <Flex $gap={8} $align='flex-start'>
      <CriteriaSelection
        value={optional ?? true}
        onUpdate={onUpdate}
        disabled={disabled || fieldConverted}
      />
      <Flex $direction='column' $gap={4} $flex='1 1 0'>
        <S.CustomRequirementField $disabled={disabled || fieldConverted} $errored={errored}>
          <input
            placeholder='Custom requirement'
            value={requirement}
            onChange={(e) => {
              onUpdate('requirement', e.target.value)
            }}
            autoFocus={true}
          />
        </S.CustomRequirementField>
        {fieldErrored}
      </Flex>
      <S.RemoveCriteriaBox>
        <RemoveCriteria
          onRemove={onRemove}
          disabled={disabled}
          icon={fieldConverted ? Icons.x : Icons.trash}
        />
      </S.RemoveCriteriaBox>
    </Flex>
  )
}
