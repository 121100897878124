import { isNil } from 'lodash'
import { Button } from 'src/components/primitives'
import { Icons } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import { usePrintView } from 'src/hooks/use-print-view'

interface RemoveCriteriaProps {
  onRemove?: () => void
  disabled?: boolean
  icon?: IconName
}

export const RemoveCriteria = ({ onRemove, disabled, icon }: RemoveCriteriaProps): JSX.Element | null => {
  const isPrintView = usePrintView()
  if (isNil(onRemove) || isPrintView) {
    return null
  }
  return (
    <Button
      $variant='ghost'
      $colorTheme='muted'
      leadingIcon={icon ?? Icons.trash}
      $height={24}
      $width={24}
      $fontSize={12}
      onClick={onRemove}
      disabled={disabled}
    />
  )
}
