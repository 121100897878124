import { CriteriaKey, DEFAULT_CUSTOM_REQUIREMENT_CRITERIA } from 'src/libs/api/backend/candidate_search'
import type { Criteria, CriteriaValue } from 'src/libs/api/backend/candidate_search'
import * as S from './refinement-filter-criteria-styled'
import { Caption } from 'src/components/primitives/typography'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CriteriaRow } from './criteria-row'
import { isEmpty, isNil } from 'lodash'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Button, Flex } from 'src/components/primitives'
import { Icon, Icons } from 'src/components/primitives/icon'
import { CriteriaProperties, NewCriteriaList } from './constants'
import { removeUpdatedCriteriaAtom } from 'src/stores/job-refinement'
import { useSetAtom } from 'jotai'
import { trackEvent } from 'src/libs/track'
import { TrackingEventType } from 'src/types/track'
import { addCriteriaToOrderedGroup, removeCriteriaFromOrderedGroup } from 'src/utils/refinement-criteria'
import type { CriteriaOrderedGroup } from 'src/utils/refinement-criteria'
import type { JobSearchRefinement } from 'src/libs/api/backend/jobs'

interface RefinementFilterCriteriaProps {
  criteria: Criteria | undefined
  setCriteria: (criteria: Criteria) => void
  searchRefinement?: JobSearchRefinement
  updatedGeneratedCriteria: CriteriaKey[]
  criteriaOrder: CriteriaOrderedGroup[]
  setCriteriaOrder: (criteriaOrder: CriteriaOrderedGroup[]) => void
  disabled: boolean
  clearCriteria: () => void
}

export const RefinementFilterCriteria = ({
  criteria,
  setCriteria,
  criteriaOrder = [],
  setCriteriaOrder,
  updatedGeneratedCriteria,
  disabled,
  clearCriteria,
  searchRefinement
}: RefinementFilterCriteriaProps): JSX.Element => {
  const { blockedCriteria, criteriaImprovementToReplace } = searchRefinement ?? {}
  const [isClearing, setIsClearing] = useState(false)
  const newCriteriaRef = useRef<HTMLDivElement>(null)
  const [newlyAddedCriteria, setNewlyAddedCriteria] = useState<CriteriaKey | undefined>(undefined)
  const handleUpdate = useCallback((criteriaKey: CriteriaKey, criteriaValue: CriteriaValue): void => {
    if (!isNil(criteriaValue)) {
      setNewlyAddedCriteria(criteriaKey)
      setCriteria({ ...(criteria ?? {}), [criteriaKey]: criteriaValue })
    }
  }, [criteria, setCriteria])

  useEffect(() => {
    if (newlyAddedCriteria) {
      newCriteriaRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      setNewlyAddedCriteria(undefined)
    }
  }, [newlyAddedCriteria])

  const removeUpdatedCriteria = useSetAtom(removeUpdatedCriteriaAtom)

  const onRowClick = useCallback((criteriaKey: CriteriaKey) => {
    removeUpdatedCriteria(criteriaKey)
  }, [removeUpdatedCriteria])

  const makeCriteriaItem = useCallback((criteriaKey: CriteriaKey, icon: string | undefined): MenuItemProps | null => {
    const criteriaProperties = CriteriaProperties.get(criteriaKey)
    if (isNil(criteriaProperties)) {
      return null
    }
    const isDisabled = criteriaKey in (criteria ?? {})
    const { label, defaultValue } = criteriaProperties
    return {
      id: criteriaKey,
      title: label,
      icon,
      isDisabled,
      type: 'item',
      closeOnSelect: true,
      itemTooltip: isDisabled
        ? {
            text: 'This criteria is already set',
            position: 'right'
          }
        : criteriaProperties.tooltip,
      onSelect: () => {
        handleUpdate(criteriaKey, defaultValue)
        setCriteriaOrder(addCriteriaToOrderedGroup(criteriaOrder, criteriaKey))
      }
    }
  }, [criteria, criteriaOrder, handleUpdate, setCriteriaOrder])

  const addCriteriaItems = useMemo((): MenuItemProps[] => {
    const items: MenuItemProps[] = []

    items.push({
      id: 'ai-custom-requirement-title',
      title: 'ADVANCED CRITERIA',
      type: 'description'
    }, {
      id: 'custom-ai-question',
      icon: <Icon name={Icons.sparklesSm} color='aiSolidFg' />,
      type: 'item',
      title: 'AI Question',
      trailingTooltip: {
        text: 'Pin can interpret resumes and answer advanced questions about candidates.',
        position: 'top'
      },
      trailingIcon: <Icon name={Icons.circleHelp} color='fgTertiary' />,
      onSelect: () => {
        if (criteria && CriteriaKey.CUSTOM_REQUIREMENTS in criteria) {
          handleUpdate(
            CriteriaKey.CUSTOM_REQUIREMENTS,
            [...(criteria[CriteriaKey.CUSTOM_REQUIREMENTS] ?? []), DEFAULT_CUSTOM_REQUIREMENT_CRITERIA]
          )
        } else {
          handleUpdate(
            CriteriaKey.CUSTOM_REQUIREMENTS,
            [DEFAULT_CUSTOM_REQUIREMENT_CRITERIA]
          )
          setCriteriaOrder(addCriteriaToOrderedGroup(criteriaOrder, CriteriaKey.CUSTOM_REQUIREMENTS))
        }
        trackEvent(TrackingEventType.ADD_CUSTOM_AI_QUESTION)
      }
    }, {
      id: 'custom-criteria-separator',
      title: 'CustomCriteriaSeparator',
      type: 'separator'
    }, {
      id: 'common-criteria-title',
      title: 'COMMON CRITERIA',
      type: 'label'
    })

    NewCriteriaList.forEach((newCriteria) => {
      if (newCriteria.criteriaKey) {
        const criteriaItem = makeCriteriaItem(newCriteria.criteriaKey, newCriteria.icon)
        if (criteriaItem) {
          items.push(criteriaItem)
        }
      } else if (newCriteria.groupTitle) {
        const { groupTitle, icon, items: groupItems = [] } = newCriteria
        const subItems: MenuItemProps[] = []
        groupItems.forEach((item) => {
          if (item.criteriaKey) {
            const criteriaItem = makeCriteriaItem(item.criteriaKey as CriteriaKey, undefined)
            if (criteriaItem) {
              subItems.push(criteriaItem)
            }
          }
        })
        items.push({
          id: groupTitle,
          title: groupTitle,
          icon,
          trailingIcon: Icons.chevronRight,
          subitems: subItems
        })
      }
    })
    return items
  }, [criteria, criteriaOrder, handleUpdate, makeCriteriaItem, setCriteriaOrder])

  const handleRemove = useCallback((criteriaKey: CriteriaKey, index?: number): void => {
    if (isNil(criteriaOrder) || isNil(criteria)) {
      return
    }
    let currentCriteria = { ...criteria }
    const value = currentCriteria[criteriaKey]
    let deletingCriteria = true
    if (Array.isArray(value) && !isNil(index)) {
      const updatedCriteriaValue = value.filter((_, i) => i !== index)
      if (updatedCriteriaValue.length === 0) {
        const { [criteriaKey]: _removedCriteria, ...updatedCriteria } = currentCriteria
        currentCriteria = updatedCriteria
      } else {
        deletingCriteria = false
        currentCriteria = {
          ...currentCriteria,
          [criteriaKey]: updatedCriteriaValue
        }
      }
    } else {
      const { [criteriaKey]: _removedCriteria, ...updatedCriteria } = currentCriteria
      currentCriteria = updatedCriteria
    }
    setCriteria(currentCriteria)
    if (deletingCriteria) {
      setCriteriaOrder(removeCriteriaFromOrderedGroup(criteriaOrder, criteriaKey))
    }
  }, [criteria, criteriaOrder, setCriteria, setCriteriaOrder])

  return (
    <S.Wrapper>
      <Flex $direction='column'>
        {criteria && criteriaOrder?.map((orderedGroup) => {
          const { groupKey, orderedCriteria } = orderedGroup
          if (orderedCriteria.length === 0) {
            return <Fragment key={groupKey} />
          }
          return (
            <S.CriteriaGroup key={groupKey}>
              <S.CriteriaGroupHeader>
                <Caption as='h5' size='2XS' $color='fgTertiary'>{groupKey}</Caption>
              </S.CriteriaGroupHeader>
              {orderedCriteria.map((criteriaKey) => {
                return (
                  <div
                    ref={criteriaKey === newlyAddedCriteria ? newCriteriaRef : undefined}
                    key={criteriaKey}
                  >
                    <CriteriaRow
                      criteriaKey={criteriaKey}
                      criteriaValue={criteria[criteriaKey]}
                      onCriteriaUpdate={handleUpdate}
                      onCriteriaRemove={handleRemove}
                      updated={updatedGeneratedCriteria.includes(criteriaKey)}
                      disabled={disabled}
                      onRowClick={onRowClick}
                      criteriaLimitations={{
                        blockedCriteria,
                        criteriaImprovementToReplace
                      }}
                    />
                  </div>
                )
              })}
            </S.CriteriaGroup>
          )
        })}
      </Flex>
      <S.ActionsBar>
        <Dropdown
          $minWidth='15rem'
          $maxHeight='auto'
          $maxWidth='20rem'
          disabled={disabled}
          side='right'
          alignOffset={-62}
          trigger={
            <Button
              nested
              $variant='outline'
              $height={24}
              $fontSize={12}
              trailingIcon={Icons.chevronDownSmall}
              disabled={disabled}
            >
              Add Criteria
            </Button>
          }
          items={addCriteriaItems}
          onCloseAutoFocus={(event: Event) => {
            event.preventDefault()
          }}
        />
        {isClearing
          ? <Flex $align='center' $gap={16} $width='auto'>
              <Caption size='SM' $fontWeight={400}>Clear all criterias?</Caption>
              <Flex $align='center' $gap={8} $width='auto'>
                <Button
                  $variant='fill'
                  $colorTheme='negative'
                  $height={24}
                  $fontSize={12}
                  onClick={() => {
                    clearCriteria()
                    setIsClearing(false)
                  }}
                >
                  Clear
                </Button>
                <Button
                  $variant='outline'
                  $height={24}
                  $fontSize={12}
                  onClick={() => {
                    setIsClearing(false)
                  }}
                >
                  Cancel
                </Button>
              </Flex>
            </Flex>
          : <Button
              $variant='ghost'
              $height={24}
              $fontSize={12}
              trailingIcon={Icons.trash}
              disabled={isEmpty(criteria) || disabled}
              onClick={() => {
                setIsClearing(true)
              }}
            >
              Clear all
            </Button>
        }
      </S.ActionsBar>
    </S.Wrapper>
  )
}
