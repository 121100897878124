import { SEO } from '../../../components/primitives/seo'
import { EmpySequenceState } from 'src/components/blocks/empty-state/empty-sequence-state'

const JobCandidatesCreateSequencePage = (): JSX.Element => {
  return (
    <>
      <SEO title="Create Sequence" />
      <EmpySequenceState />
    </>
  )
}

export default JobCandidatesCreateSequencePage
