import { Flex } from 'src/components/primitives/flex'
import * as S from './sequence-editor.styled'
import { IfElse } from '../if-else'
import type { EmailSequenceStep } from 'src/models/sequence'
import { Paragraph } from 'src/components/primitives/typography'
import { useSession } from 'src/hooks/use-session'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { SequenceStepType } from 'src/libs/api/backend/sequences'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Spinner } from 'src/components/primitives/spinner'
import { When } from '../when'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

interface StepSelectorProps {
  currentStep: number
  step: EmailSequenceStep
  stepDescriptionText: string
  stepTypeDisplay: React.ReactNode
  subject?: string | null
  placeholder?: string | null
  onStepClick: (position: number) => void
  initialStepsAreGenerating: boolean
  reorderingDisabled?: boolean
}

export const StepSelector = ({
  currentStep,
  step,
  stepDescriptionText,
  stepTypeDisplay,
  subject,
  placeholder,
  onStepClick,
  initialStepsAreGenerating,
  reorderingDisabled
}: StepSelectorProps): JSX.Element => {
  const { featureFlags, userHasViewerRole } = useSession()

  const { attributes, listeners, setNodeRef, transition, transform, isDragging } = useSortable({ id: step.id ?? step.position.toString() })

  const reorderStyle = {
    transform: CSS.Transform.toString(transform),
    left: isDragging ? 6 : 0,
    opacity: isDragging ? 0.5 : 1,
    zIndex: isDragging ? 2 : 1,
    transition
  }

  return (
    <S.StepSelector
      ref={setNodeRef}
      style={reorderStyle}
      $isGenerating={false}
      $isCurrentStep={currentStep === step.position}
      onClick={() => {
        onStepClick(step.position)
      }}
      aria-label={`Select outreach step with the subject: ${step.subject} at position ${step.position}`}
    >
      {
        featureFlags?.includes(FeatureFlags.SEQUENCE_STEP_REORDERING) && (
          <S.StepSelectorDragHandle {...attributes} {...listeners} $disabled={reorderingDisabled || userHasViewerRole}>
            <Icon name={Icons.gripVertical} size={12} color="fgTertiary" />
          </S.StepSelectorDragHandle>
        )
      }
      <Flex $gap={4} $direction="column">
        <IfElse
          condition={initialStepsAreGenerating}
          ifNode={<Paragraph size="XS">Generating...</Paragraph>}
          elseNode={
            <Flex $gap={8} $align="center" $justify="space-between">
              <Paragraph size="XS" $whiteSpace="nowrap">
                {stepDescriptionText}
              </Paragraph>
              {featureFlags?.includes(FeatureFlags.LINKEDIN_CONNECTION) && (
                <Flex $gap={4} $align="center" $justify="flex-end" $width="40px">
                  {
                    step.position > 0 &&
                    step.type !== SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE &&
                    step.type !== SequenceStepType.MANUAL_TASK &&
                    step.subject === null && <Icon name={Icons.cornerDownRight} size={12} color="fgTertiary" />
                  }
                  {stepTypeDisplay}
                </Flex>
              )}
            </Flex>
          }
        />
        <IfElse
          condition={
            step.type === SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE ||
            subject === '' ||
            placeholder === ''
          }
          ifNode={
            <Paragraph size="XS" $color="fgTertiary" $whiteSpace="nowrap">
              {step.type === SequenceStepType.MANUAL_TASK
                ? '(No Title)'
                : '(No Subject)'}
            </Paragraph>
          }
          elseNode={
            <S.StepSelectorSubject>
              <Paragraph size="XS" $color="fgPrimary" $whiteSpace="nowrap" $ellipsis>
                {subject}
              </Paragraph>
            </S.StepSelectorSubject>
          }
        />
      </Flex>
      <When condition={initialStepsAreGenerating}>
        <S.StepSelectorSpinner>
          <Spinner />
        </S.StepSelectorSpinner>
      </When>
    </S.StepSelector>
  )
}
