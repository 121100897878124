import { Button } from 'src/components/primitives/button'
import { Editor } from '../editor'
import * as S from './inbox-comment-editor.styled'
import { useState } from 'react'
import type { Editor as TiptapEditor } from '@tiptap/react'
import { When } from '../when'
import { useSession } from 'src/hooks/use-session'

interface InboxCommentEditorProps {
  variant?: 'PLAIN' | 'GHOST'
  onCancel?: () => void
  onAddComment?: () => void
  onDataChanged?: (data: string) => void
  onEditorHeightChange?: (height: number) => void
  confirmText?: string
  initialContent?: string
  forceEditorFocus?: boolean
  actions?: boolean
}

export const InboxCommentEditor = ({
  variant = 'PLAIN',
  onDataChanged,
  onEditorHeightChange,
  onCancel,
  onAddComment,
  confirmText,
  initialContent,
  forceEditorFocus = true,
  actions = true
}: InboxCommentEditorProps): JSX.Element => {
  const [editor, setEditor] = useState<TiptapEditor | null>(null)
  const [isFocused, setIsFocused] = useState(forceEditorFocus)
  const { userHasViewerRole } = useSession()

  return (
    <S.InboxCommentEditor $isFocused={isFocused} $variant={variant}>
      <S.EditorWrapper>
        <Editor
          contentType="html"
          onDataChanged={(data) => {
            if (onDataChanged) {
              onDataChanged(data)
            }
          }}
          $minHeight="3rem"
          $maxHeight="10rem"
          $editorHeight='fit-content'
          onEditorHeightChange={onEditorHeightChange}
          forceEditorFocus={forceEditorFocus}
          setEditor={setEditor}
          initialContent={initialContent}
          onFocusChange={setIsFocused}
        />
      </S.EditorWrapper>
      <When condition={actions}>
        <S.Actions>
          {onCancel && (
            <Button
              $variant="outline"
              $colorTheme="muted"
              $height={24}
              $fontSize={12}
              onClick={() => {
                if (onCancel) {
                  onCancel()
                }
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            $variant="raised"
            $colorTheme="tint"
            $height={24}
            $fontSize={12}
            onClick={() => {
              if (onAddComment) {
                onAddComment()
                editor?.commands.setContent('')
              }
            }}
            disabled={userHasViewerRole}
          >
            {confirmText ?? 'Add comment'}
          </Button>
        </S.Actions>
      </When>
    </S.InboxCommentEditor>
  )
}
