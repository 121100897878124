import { isNil } from 'lodash'
import type { ButtonStyleProps, Variant } from 'src/components/primitives/button'
import { Button } from 'src/components/primitives/button'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Icons } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import { useAddCandidateToSequence } from 'src/hooks/mutations/use-add-candidate-to-sequence'
import { useRejectCandidate } from 'src/hooks/mutations/use-reject-candidate'
import { usePauseCandidateSequence } from 'src/hooks/mutations/use-pause-candidate-job-sequence'
import type { CandidateJobExpanded, CandidateJobStatusDisplay } from 'src/libs/api/backend/candidate_jobs'
import { acknowledgableCandidateJobStatuses, CandidateJobRejectionReason, CandidateJobStage, CandidateJobStatus, CandidateJobStatusType } from 'src/libs/api/backend/candidate_jobs'
import RouteBuilder from 'src/libs/route-builder'
import { useLocation } from 'react-router-dom'
import { useHireCandidate } from 'src/hooks/mutations/use-hired-candidate'
import { useUndoStageTransition } from 'src/hooks/mutations/use-undo-stage-transition'
import { useSession } from 'src/hooks/use-session'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { useQueryParams } from 'src/hooks/use-query-params'
import { SettingParams } from 'src/components/dialogs/default-settings-dialog'
import { formatDistanceToNow } from 'date-fns'
import { useSetAtom } from 'jotai'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useCanTriggerEmailSequence } from 'src/hooks/use-available-credits'
import { useAcknowledgeSequenceableErrors } from 'src/hooks/mutations/use-acknowledge-sequenceable-errors'
import type { WriteMessageDialogProps } from 'src/components/dialogs/write-message-dialog'
// import { useSetCandidateSequenceResponded } from 'src/hooks/mutations/use-set-candidate-sequence-responded'

interface GetStatusReturnType {
  icon: IconName
  triggerTitle: string
  colorTheme?: ButtonStyleProps['$colorTheme']
  actions?: MenuItemProps[]
}

const getColorThemeForStatus = (statusDisplay: CandidateJobStatusDisplay | null): ButtonStyleProps['$colorTheme'] => {
  switch (statusDisplay?.type) {
    case CandidateJobStatusType.SUCCESS:
      return 'positive'
    case CandidateJobStatusType.WARNING:
      return 'warning'
    case CandidateJobStatusType.INFO:
      return 'tint'
    case CandidateJobStatusType.PENDING:
      return 'muted'
    case CandidateJobStatusType.ERROR:
      return 'negative'
    default:
      return 'muted'
  }
}

const getIconForStatus = (statusDisplay: CandidateJobStatusDisplay | null): IconName => {
  switch (statusDisplay?.status) {
    case CandidateJobStatus.HIRED:
      return Icons.heartHandshake
    case CandidateJobStatus.REJECTED:
      return Icons.archive
    case CandidateJobStatus.PREVIOUSLY_BOUNCED:
    case CandidateJobStatus.PREVIOUSLY_UNSUBSCRIBED:
    case CandidateJobStatus.PREVIOUSLY_MESSAGED:
      return Icons.mailWarning
    case CandidateJobStatus.BOUNCED:
    case CandidateJobStatus.EMAIL_NOT_FOUND:
      return Icons.mailX
    case CandidateJobStatus.MISSING_EMAIL:
    case CandidateJobStatus.MISSING_LINKEDIN:
    case CandidateJobStatus.SEARCHING_FOR_EMAIL:
      return Icons.mailQuestion
    case CandidateJobStatus.IN_SEQUENCE:
      return Icons.mails
    case CandidateJobStatus.INTERESTED:
    case CandidateJobStatus.RESPONDED:
      return Icons.mailCheck
    case CandidateJobStatus.WAITING_ON_CANDIDATE_TO_RESPOND:
    case CandidateJobStatus.CANDIDATE_WAITING_FOR_RESPONSE:
      return Icons.messagesSquare
    case CandidateJobStatus.CANDIDATE_UNRESPONSIVE:
      return Icons.sleepSnooze
    default:
      return Icons.info
  }
}

const getStatus = (status: CandidateJobStatusDisplay | null | undefined): GetStatusReturnType => {
  switch (status?.status) {
    case CandidateJobStatus.BOUNCED: {
      return {
        icon: getIconForStatus(status ?? null),
        triggerTitle: status.title,
        colorTheme: getColorThemeForStatus(status),
        actions: [
          {
            title: 'Edit email',
            icon: Icons.mailPlus
          }
        ]
      }
    }
    default: {
      return {
        icon: getIconForStatus(status ?? null),
        triggerTitle: status?.title ?? '',
        colorTheme: getColorThemeForStatus(status ?? null)
      }
    }
  }
}

interface SourcedStatusDropdownProps {
  candidateJob?: CandidateJobExpanded
  $variant?: Variant
}

export const SourcedStatusDropdown = ({
  candidateJob,
  $variant = 'ghost'
}: SourcedStatusDropdownProps): JSX.Element => {
  const { rejectCandidate } = useRejectCandidate()
  const { addCandidateToSequence } = useAddCandidateToSequence()
  const { setParam } = useQueryParams()

  const openDialog = useSetAtom(openDialogAtom)

  const handleReject = (rejectionReason?: CandidateJobRejectionReason): void => {
    if (!candidateJob?.id) {
      return
    }
    rejectCandidate({ candidateJobIds: [candidateJob.id], rejectionReason })
  }

  const { org, featureFlags, userHasViewerRole } = useSession()

  const canTriggerAddToOureach = useCanTriggerEmailSequence()

  return (
    <Dropdown
      trigger={
        <Button
          nested
          $variant={$variant}
          $colorTheme="muted"
          $fontSize={12}
          $height={24}
          leadingIcon="sparkles"
          trailingIcon="chevron-down"
        >
          Sourced
        </Button>
      }
      items={[
        {
          id: 'add-to-sequence',
          title: 'Add to outreach',
          icon: Icons.mailPlus,
          onSelect: () => {
            if (!canTriggerAddToOureach) {
              openDialog({ id: DialogId.INSUFFICIENT_CREDITS })
              return
            }
            if (!candidateJob?.id) {
              return
            }
            addCandidateToSequence([candidateJob.id])
          },
          isDisabled: userHasViewerRole,
          isSelectable: !userHasViewerRole
        },
        {
          id: 'archive-candidate-separator',
          title: 'ArchiveSeparator',
          type: 'separator'
        },
        {
          id: 'archive-candidate',
          title: 'Archive candidate',
          icon: Icons.trash,
          variant: 'negative',
          onSelect: () => {
            handleReject()
          },
          isDisabled: userHasViewerRole,
          isSelectable: !userHasViewerRole
        },
        ...(!featureFlags?.includes(FeatureFlags.ATS_INTEGRATION))
          ? []
          : [{
              id: 'export-to-ats-separator',
              title: 'ArchiveSeparator',
              type: 'separator'
            },
            {
              id: 'export-to-ats',
              title: 'Export to ATS',
              icon: Icons.share,
              // isDisabled: isNil(candidateJob?.mergeApplicationId) || isNil(org?.mergeAtsIntegration),
              onSelect: () => {
                if (isNil(org?.mergeAtsIntegration)) {
                  setParam('settings', SettingParams.ATS)
                  openDialog({ id: DialogId.DEFAULT_SETTINGS })
                } else {
                  openDialog({ id: DialogId.EXPORT_CANDIDATES, payload: { candidateJobs: [candidateJob] } })
                }
              },
              isDisabled: userHasViewerRole,
              isSelectable: !userHasViewerRole
            }]
      ]}
      menuPosition="end"
      size="small"
    />
  )
}

interface SequenceStatusDropdownProps {
  candidateJob?: CandidateJobExpanded
  $variant?: Variant
  enabledSendEmail?: boolean
}

export const SequenceStatusDropdown = ({
  candidateJob,
  $variant = 'ghost',
  enabledSendEmail = true
}: SequenceStatusDropdownProps): JSX.Element => {
  const openDialog = useSetAtom(openDialogAtom)
  const { rejectCandidate } = useRejectCandidate()
  const { setCandidateSequencePause } = usePauseCandidateSequence()
  // const { setCandidateSequenceResponded } = useSetCandidateSequenceResponded()
  const { org, featureFlags, userHasViewerRole } = useSession()
  const { setParam, getParam } = useQueryParams()
  const candidateIdParams = getParam('candidate')
  const { acknowledgeErrors } = useAcknowledgeSequenceableErrors()

  const handleReject = (rejectionReason?: CandidateJobRejectionReason): void => {
    if (!candidateJob?.id) {
      return
    }
    rejectCandidate({ candidateJobIds: [candidateJob.id], rejectionReason })
  }

  if (!candidateJob) {
    return <></>
  }

  const status = candidateJob?.candidateSequence?.statusDisplay
  const statusPayload = getStatus(status)
  const hasAcknowledgeableErrors = acknowledgableCandidateJobStatuses.includes(status?.status ?? CandidateJobStatus.IN_SEQUENCE)

  const items: MenuItemProps[] = []
  if (enabledSendEmail) {
    items.push({
      id: 'write-message',
      title: 'Write message',
      icon: Icons.mail,
      isDisabled: userHasViewerRole,
      isSelectable: !userHasViewerRole,
      onSelect: () => {
        const payload: WriteMessageDialogProps = { candidateJobs: [candidateJob] }
        openDialog({ id: DialogId.WRITE_MESSAGE, payload })
      }
    },
    {
      title: 'Separator',
      type: 'separator'
    })
  }

  if (status && [CandidateJobStatus.EMAIL_NOT_FOUND, CandidateJobStatus.MISSING_EMAIL, CandidateJobStatus.BOUNCED].includes(status?.status)) {
    let actions: MenuItemProps[] = []
    let rejectionReason = CandidateJobRejectionReason.OTHER

    if (
      status?.status === CandidateJobStatus.EMAIL_NOT_FOUND ||
      status?.status === CandidateJobStatus.MISSING_EMAIL
    ) {
      actions = [
        {
          id: 'add-email',
          title: 'Add email',
          icon: Icons.mailPlus,
          onSelect: () => {
            openDialog({ id: DialogId.EDIT_CANDIDATE, payload: candidateJob })
          },
          isDisabled: userHasViewerRole,
          isSelectable: !userHasViewerRole
        }
      ]
      rejectionReason = CandidateJobRejectionReason.MISSING_EMAIL
    }

    if (status?.status === CandidateJobStatus.BOUNCED) {
      actions = [
        {
          id: 'edit-email',
          title: 'Edit email',
          isDisabled: userHasViewerRole,
          isSelectable: !userHasViewerRole,
          icon: Icons.mail,
          onSelect: () => {
            openDialog({ id: DialogId.EDIT_CANDIDATE, payload: candidateJob })
          }
        }
      ]
      rejectionReason = CandidateJobRejectionReason.UNREACHABLE
    }

    return (
      <Dropdown
        trigger={
          <Button
            nested
            $variant={$variant}
            $colorTheme="negative"
            $fontSize={12}
            $height={24}
            leadingIcon={statusPayload.icon}
            trailingIcon="chevron-down"
          >
            {statusPayload.triggerTitle}
          </Button>
        }
        items={[
          ...actions,
          {
            id: 'archive-candidate-separator',
            title: 'ArchiveSeparator',
            type: 'separator'
          },
          {
            id: 'archive-candidate',
            title: 'Archive candidate',
            icon: Icons.trash,
            variant: 'negative',
            onSelect: () => {
              handleReject(rejectionReason)
            },
            isDisabled: userHasViewerRole,
            isSelectable: !userHasViewerRole
          },
          {
            id: 'archive-separator',
            title: 'ArchiveSeparator',
            type: 'separator'
          },
          ...(!candidateIdParams)
            ? [{
                id: 'candidate-details',
                title: 'Candidate Details',
                icon: Icons.userCircle,
                onSelect: () => {
                  openDialog({ id: DialogId.CANDIDATE_DETAILS, payload: candidateJob.id })
                }
              }]
            : [],
          ...(!featureFlags?.includes(FeatureFlags.ATS_INTEGRATION))
            ? []
            : [{
                id: 'export-to-ats',
                title: 'Export to ATS',
                icon: Icons.share,
                // isDisabled: isNil(candidateJob?.mergeApplicationId),
                onSelect: () => {
                  if (isNil(org?.mergeAtsIntegration)) {
                    setParam('settings', SettingParams.ATS)
                    openDialog({ id: DialogId.DEFAULT_SETTINGS })
                  } else {
                    openDialog({ id: DialogId.EXPORT_CANDIDATES, payload: { candidateJobs: [candidateJob] } })
                  }
                },
                isDisabled: userHasViewerRole,
                isSelectable: !userHasViewerRole
              }],
          ...(hasAcknowledgeableErrors)
            ? [
                {
                  id: 'acknowledge-errors-separator',
                  title: 'Separator',
                  type: 'separator'
                },
                {
                  id: 'acknowledge-errors',
                  title: 'Clear candidate from sequence',
                  icon: Icons.mailMinus,
                  onSelect: () => {
                    acknowledgeErrors({ candidateJobs: [candidateJob] })
                  }
                }
              ]
            : []
        ]}
        menuPosition="end"
        size="small"
      />
    )
  }

  return (
    <Dropdown
      trigger={
        <Button
          nested
          $variant={$variant}
          $colorTheme={statusPayload.colorTheme}
          $fontSize={12}
          $height={24}
          leadingIcon={statusPayload.icon}
          trailingIcon="chevron-down"
        >
          {statusPayload.triggerTitle}
        </Button>
      }
      items={[
        ...(candidateJob?.statusDisplay?.subtitle
          ? [
              {
                title: null,
                subTitle: candidateJob.statusDisplay.subtitle,
                isSelectable: false
              },
              {
                title: 'Separator',
                type: 'separator'
              }
            ]
          : []),
        ...items,
        {
          id: 'resume-pause-sequence',
          title: candidateJob?.candidateSequence?.paused
            ? 'Resume outreach'
            : 'Pause outreach',
          icon: candidateJob?.candidateSequence?.paused
            ? Icons.playCircle
            : Icons.pauseCircle,
          variant: 'tint',
          onSelect: () => {
            const candidateJobId = candidateJob?.id
            if (isNil(candidateJobId)) {
              return
            }
            setCandidateSequencePause({ candidateJobIds: [candidateJobId], pause: !candidateJob?.candidateSequence?.paused })
          },
          isDisabled: userHasViewerRole,
          isSelectable: !userHasViewerRole
        },
        // ...(!candidateJob?.candidateSequence?.responded
        //   ? [{
        //       id: 'responded-sequence',
        //       title: 'Set as responded',
        //       icon: Icons.checkCircle,
        //       variant: 'positive',
        //       onSelect: () => {
        //         setCandidateSequenceResponded({ candidateJobIds: [candidateJob.id] })
        //       }
        //     }] as MenuItemProps[] // Typescript nonsense
        //   : [{
        //       id: 'responded-interested',
        //       title: 'Set as interested',
        //       icon: Icons.checkCircle,
        //       variant: 'positive',
        //       onSelect: () => {
        //         setCandidateSequenceResponded({ candidateJobIds: [candidateJob.id], interested: true })
        //       }
        //     }] as MenuItemProps[]),
        {
          id: 'archive-candidate',
          title: 'Archive candidate',
          icon: Icons.trash,
          variant: 'negative',
          onSelect: () => {
            handleReject()
          },
          isDisabled: userHasViewerRole,
          isSelectable: !userHasViewerRole
        },
        ...(candidateIdParams && featureFlags?.includes(FeatureFlags.ATS_INTEGRATION)
          ? [{
              id: 'archive-separator',
              title: 'ArchiveSeparator',
              type: 'separator'
            }]
          : []),
        ...(!candidateIdParams)
          ? [{
              id: 'candidate-details',
              title: 'Candidate Details',
              icon: Icons.userCircle,
              onSelect: () => {
                openDialog({ id: DialogId.CANDIDATE_DETAILS, payload: candidateJob?.id })
              }
            }]
          : [],
        ...(!featureFlags?.includes(FeatureFlags.ATS_INTEGRATION))
          ? []
          : [{
              id: 'export-to-ats',
              title: 'Export to ATS',
              icon: Icons.share,
              // isDisabled: isNil(candidateJob?.mergeApplicationId),
              onSelect: () => {
                if (isNil(org?.mergeAtsIntegration)) {
                  setParam('settings', SettingParams.ATS)
                  openDialog({ id: DialogId.DEFAULT_SETTINGS })
                } else {
                  openDialog({ id: DialogId.EXPORT_CANDIDATES, payload: { candidateJobs: [candidateJob] } })
                }
              },
              isDisabled: userHasViewerRole,
              isSelectable: !userHasViewerRole
            }]
      ]}
      menuPosition="end"
      size="small" />
  )
}

interface CommunicatingStatusDropdownProps {
  candidateJob?: CandidateJobExpanded
  $variant?: Variant
  enabledSendEmail?: boolean
}

export const CommunicatingStatusDropdown = ({
  candidateJob,
  $variant = 'ghost',
  enabledSendEmail = true
}: CommunicatingStatusDropdownProps): JSX.Element => {
  const { pathname } = useLocation()
  const { rejectCandidate } = useRejectCandidate()
  const { hireCandidate } = useHireCandidate()
  const { org, featureFlags, userHasViewerRole } = useSession()
  const openDialog = useSetAtom(openDialogAtom)
  const { setParam, getParam } = useQueryParams()
  const candidateIdParams = getParam('candidate')

  if (!candidateJob) {
    return <></>
  }

  const handleReject = (rejectionReason?: CandidateJobRejectionReason): void => {
    if (!candidateJob?.id) {
      return
    }
    rejectCandidate({ candidateJobIds: [candidateJob.id], rejectionReason })
  }

  const handleHire = (): void => {
    if (!candidateJob?.id) {
      return
    }
    hireCandidate({ candidateJobIds: [candidateJob.id] })
  }

  const hasInboxLink = !pathname.includes('inbox')

  let dropdownItems: MenuItemProps[] = []

  if (hasInboxLink) {
    dropdownItems = [{
      id: 'open-in-inbox',
      title: 'Open in Inbox',
      icon: Icons.inbox,
      href: RouteBuilder.build('JOBS_INBOX_CANDIDATE_JOBS', {
        jobId: candidateJob.jobId,
        candidateJobId: candidateJob?.id
      })
    },
    ...(!enabledSendEmail)
      ? [{
          id: 'archive-separator',
          title: 'ArchiveSeparator',
          type: 'separator'
        }]
      : []
    ]
  }

  if (enabledSendEmail) {
    dropdownItems.push({
      id: 'write-message',
      title: 'Write message',
      icon: Icons.mail,
      onSelect: () => {
        const payload: WriteMessageDialogProps = { candidateJobs: [candidateJob] }
        openDialog({ id: DialogId.WRITE_MESSAGE, payload })
      },
      isDisabled: userHasViewerRole,
      isSelectable: !userHasViewerRole
    },
    {
      id: 'open-in-inbox-separator',
      title: 'ArchiveSeparator',
      type: 'separator'
    })
  }

  const status = candidateJob?.statusDisplay
  const statusPayload = getStatus(status)

  return (
    <Dropdown
      trigger={<Button
        nested
        $variant={$variant}
        $colorTheme={statusPayload?.colorTheme}
        $fontSize={12}
        $height={24}
        leadingIcon={statusPayload?.icon}
        trailingIcon="chevron-down"
      >
        {statusPayload.triggerTitle}
      </Button>}
      items={[
        ...(candidateJob?.candidate?.lastCommunicatedAt)
          ? [{
              title: null,
              subTitle: `Last communicated ${formatDistanceToNow(candidateJob?.candidate?.lastCommunicatedAt ?? '', { addSuffix: true })}`,
              isSelectable: false
            },
            {
              title: 'Separator',
              type: 'separator'
            }]
          : [],
        ...dropdownItems,
        {
          id: 'hired-candidate',
          title: 'Hired candidate',
          icon: Icons.fileSignature,
          variant: 'positive',
          onSelect: () => {
            handleHire()
          },
          isDisabled: userHasViewerRole,
          isSelectable: !userHasViewerRole
        },
        {
          id: 'archive-candidate',
          title: 'Archive candidate',
          icon: Icons.trash,
          variant: 'negative',
          onSelect: () => {
            handleReject()
          },
          isDisabled: userHasViewerRole,
          isSelectable: !userHasViewerRole
        },
        {
          id: 'archive-separator',
          title: 'ArchiveSeparator',
          type: 'separator'
        },
        ...(!candidateIdParams)
          ? [{
              id: 'candidate-details',
              title: 'Candidate Details',
              icon: Icons.userCircle,
              onSelect: () => {
                openDialog({ id: DialogId.CANDIDATE_DETAILS, payload: candidateJob.id })
              }
            }]
          : [],
        ...(!featureFlags?.includes(FeatureFlags.ATS_INTEGRATION))
          ? []
          : [{
              id: 'export-to-ats',
              title: 'Export to ATS',
              icon: Icons.share,
              // isDisabled: isNil(candidateJob?.mergeApplicationId) || isNil(org?.mergeAtsIntegration),
              onSelect: () => {
                if (isNil(org?.mergeAtsIntegration)) {
                  setParam('settings', SettingParams.ATS)
                  openDialog({ id: DialogId.DEFAULT_SETTINGS })
                } else {
                  openDialog({ id: DialogId.EXPORT_CANDIDATES, payload: { candidateJobs: [candidateJob] } })
                }
              },
              isDisabled: userHasViewerRole,
              isSelectable: !userHasViewerRole
            }]
      ]}
      menuPosition="end"
      size="small"
    />
  )
}

interface RejectedStatusDropdownProps {
  candidateJob?: CandidateJobExpanded
  $variant?: Variant
}

export const RejectedStatusDropdown = ({
  candidateJob,
  $variant = 'ghost'
}: RejectedStatusDropdownProps): JSX.Element => {
  const { pathname } = useLocation()
  const { undoStageTransition } = useUndoStageTransition({ isRestoringCandidates: true })
  const { org, featureFlags, userHasViewerRole } = useSession()
  const openDialog = useSetAtom(openDialogAtom)
  const { setParam, getParam } = useQueryParams()
  const candidateIdParams = getParam('candidate')

  if (!candidateJob) {
    return <></>
  }

  const handleUndo = (): void => {
    if (!candidateJob?.id) {
      return
    }
    undoStageTransition([candidateJob.id])
  }

  const hasInboxLink = !pathname.includes('inbox')

  let dropdownItems: MenuItemProps[] = []

  if (hasInboxLink) {
    dropdownItems = [{
      id: 'open-in-inbox',
      title: 'Open in Inbox',
      icon: Icons.inbox,
      href: RouteBuilder.build('JOBS_INBOX_CANDIDATE_JOBS', {
        jobId: candidateJob.jobId,
        candidateJobId: candidateJob?.id
      })
    },
    {
      id: 'open-in-inbox-separator',
      title: 'ArchiveSeparator',
      type: 'separator'
    }]
  }

  const status = candidateJob?.statusDisplay
  const statusPayload = getStatus(status)

  return (
    <Dropdown
      trigger={<Button
        nested
        $variant={$variant}
        $colorTheme={statusPayload?.colorTheme}
        $fontSize={12}
        $height={24}
        leadingIcon={statusPayload?.icon}
        trailingIcon="chevron-down"
        disabled={userHasViewerRole}
      >
        {
          candidateJob?.rejectionReason === CandidateJobRejectionReason.OTHER
            ? 'Archived'
            : statusPayload?.triggerTitle
        }
      </Button>}
      items={[
        {
          title: null,
          subTitle: `Archived ${formatDistanceToNow(candidateJob?.updatedAt ?? '', { addSuffix: true })}`,
          isSelectable: false
        },
        {
          title: 'Separator',
          type: 'separator'
        },
        ...dropdownItems,
        {
          id: 'archive-candidate',
          title: 'Undo Archive',
          icon: Icons.undo,
          variant: 'warning',
          onSelect: () => {
            handleUndo()
          },
          isDisabled: userHasViewerRole,
          isSelectable: !userHasViewerRole
        },
        {
          id: 'archive-separator',
          title: 'ArchiveSeparator',
          type: 'separator'
        },
        ...(!candidateIdParams)
          ? [{
              id: 'candidate-details',
              title: 'Candidate Details',
              icon: Icons.userCircle,
              onSelect: () => {
                openDialog({ id: DialogId.CANDIDATE_DETAILS, payload: candidateJob.id })
              }
            }]
          : [],
        ...(!featureFlags?.includes(FeatureFlags.ATS_INTEGRATION))
          ? []
          : [{
              id: 'export-to-ats',
              title: 'Export to ATS',
              icon: Icons.share,
              // isDisabled: isNil(candidateJob?.mergeApplicationId),
              onSelect: () => {
                if (isNil(org?.mergeAtsIntegration)) {
                  setParam('settings', SettingParams.ATS)
                  openDialog({ id: DialogId.DEFAULT_SETTINGS })
                } else {
                  openDialog({ id: DialogId.EXPORT_CANDIDATES, payload: { candidateJobs: [candidateJob] } })
                }
              },
              isDisabled: userHasViewerRole,
              isSelectable: !userHasViewerRole
            }]
      ]}
      menuPosition="end"
      size="small"
      disabled={userHasViewerRole}
    />
  )
}

interface CandidateJobStatusDropdownProps {
  candidateJob?: CandidateJobExpanded
  $variant?: Variant
}

export const CandidateJobStatusDropdown = ({
  candidateJob,
  $variant = 'flat'
}: CandidateJobStatusDropdownProps): JSX.Element => {
  if (!candidateJob) {
    return <></>
  }

  const statusPayload = getStatus(candidateJob.statusDisplay)

  if (candidateJob.stage === CandidateJobStage.SOURCED) {
    return <SourcedStatusDropdown candidateJob={candidateJob} $variant={$variant} />
  } else if (candidateJob.stage === CandidateJobStage.PROSPECTING) {
    return <SequenceStatusDropdown candidateJob={candidateJob} $variant={$variant} />
  } else if (candidateJob.stage === CandidateJobStage.COMMUNICATING) {
    return <CommunicatingStatusDropdown candidateJob={candidateJob} $variant={$variant} />
  } else if (candidateJob.stage === CandidateJobStage.REJECTED) {
    return <RejectedStatusDropdown candidateJob={candidateJob} $variant={$variant} />
  } else {
    return (
      <Button
        $variant={$variant}
        $colorTheme={statusPayload.colorTheme}
        $fontSize={12}
        $height={24}
        leadingIcon={statusPayload.icon}
      >
        {statusPayload.triggerTitle}
      </Button>
    )
  }
}
