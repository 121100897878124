import { CandidatesPageHeader } from 'src/components/blocks/candidates-page-header'
import { CandidatesAllTable } from 'src/components/tables/candidates-all-table/candidates-all-table'
import { CandidatesTablePagesContentInner } from './candidates.styled'
import { When } from 'src/components/blocks/when'
import { EmptyStateArchivedJob } from 'src/components/blocks/empty-state-archived-job'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { SEO } from '../../../components/primitives/seo'
import { useSetAtom } from 'jotai'
import { openDialogAtom, DialogId } from 'src/stores/dialogs'
import { ExportCandidatesDropdown } from 'src/components/blocks/export-candidates-dropdown'
import { CandidateExportFileType } from 'src/libs/api/backend/candidate_jobs'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { Spacer } from 'src/components/primitives/spacer'
import { useSession } from 'src/hooks/use-session'
// import { CandidatesInSequenceStats } from 'src/components/blocks/candidates-in-sequence-stats'

interface Props {
  heading?: string
}

const JobCandidatesOverviewPage = ({ heading = 'All Candidates' }: Props): JSX.Element => {
  const openDialog = useSetAtom(openDialogAtom)
  const { data: job } = useJobQuery()
  const { isPending, data: candidateJobs } = useCandidateJobsQuery({
    hideDataWarehouseSourcedCandidates: true
  })
  const { userHasViewerRole } = useSession()

  const showHeader = !isPending && (candidateJobs?.length ?? 0) > 0

  return (
    <>
      <SEO title="All Candidates" />
      <CandidatesTablePagesContentInner>
        <When condition={showHeader}>
          <CandidatesPageHeader
            heading={heading}
            actions={[
              {
                children: 'Add Candidate',
                icon: 'plus',
                $colorTheme: 'tint',
                onClick: () => {
                  openDialog({ id: DialogId.ADD_CANDIDATE })
                },
                disabled: userHasViewerRole
              }
            ]}
            context={<ExportCandidatesDropdown exportOptions={[CandidateExportFileType.CSV]} />}
          />
        </When>
        <When condition={!showHeader}>
          <Spacer $size={20} />
        </When>
        <When condition={Boolean(job?.deleted)}>
          <EmptyStateArchivedJob />
        </When>
        <When condition={!job?.deleted}>
          <CandidatesAllTable job={job}/>
        </When>
      </CandidatesTablePagesContentInner>
    </>
  )
}

export default JobCandidatesOverviewPage
