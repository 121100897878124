import { Button, Flex } from 'src/components/primitives'
import * as S from './close-pin-account-feedback.styled'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Box } from 'src/components/primitives/box'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Form, RadioGroup, Textarea } from 'src/components/forms'
import { useForm } from 'src/hooks/use-form'
import { useCallback, useState } from 'react'
import { Checkbox } from 'src/components/forms/checkbox'
import { closePinAccountFeedbackParser } from 'src/libs/api/backend/orgs'
import type { ClosePinAccountFeedbackArgs } from 'src/libs/api/backend/orgs'
import { useDeleteOrg } from 'src/hooks/mutations/use-delete-org'
import { isNil } from 'lodash'
import { usePauseSubscription } from 'src/hooks/mutations/use-pause-subscription'

enum ClosePinAccountFeedbackReason {
  ThePriceAintRight = 'Price isn’t right for me or my company',
  NotEnoughCandidates = 'Couldn’t find candidates that matched my criteria',
  SimilarApp = 'I’m using or will be using another similar app',
  Other = 'Other reason'
}

interface ClosePinAccountFeedbackForm extends ClosePinAccountFeedbackArgs {}

interface ClosePinAccountFeedbackProps {
  onClose: () => void
  expired: boolean
  subscribed: boolean
  paused?: boolean
}
export const ClosePinAccountFeedback = ({ onClose, expired, subscribed, paused }: ClosePinAccountFeedbackProps): JSX.Element => {
  const [pausingSubscription, setPausingSubscription] = useState(false)
  const [closingAccount, setClosingAccount] = useState(false)
  const { pauseSubscription } = usePauseSubscription()
  const { deleteOrg } = useDeleteOrg()
  const { register, formData, submit } = useForm<ClosePinAccountFeedbackForm>({
    schema: closePinAccountFeedbackParser,
    initialValues: {
      reason: ClosePinAccountFeedbackReason.Other,
      feedbackCall: true,
      reasonDetails: null
    }
  })

  const handleSubmit = useCallback(() => {
    setClosingAccount(true)
    if (!formData.reason || isNil(formData.feedbackCall)) {
      return
    }
    deleteOrg({
      reason: formData.reason as string,
      feedbackCall: Boolean(formData.feedbackCall),
      reasonDetails: formData.reasonDetails ? formData.reasonDetails as string : null,
      onSuccess: () => {
        onClose()
        setClosingAccount(false)
      },
      onError: () => {
        setClosingAccount(false)
      }
    })
  }, [formData, deleteOrg, onClose])

  return (
    <Flex $direction='column' $gap={40}>
      <Button
        $variant='outline'
        $colorTheme='tint'
        $height={40}
        leadingIcon={Icons.chevronLeft}
        onClick={onClose}
      >
        {expired ? 'Back to Offer' : 'Continue using Pin'}
      </Button>
      {subscribed && (
        <S.PauseSubscriptionBox>
          <Box $display='flex' $flexDirection='column' $gap={8} $padding={{ top: 0, bottom: 0, left: 4, right: 4 }} $flex='0 0 auto'>
            <Caption size='MD'>Not hiring right now?</Caption>
            <Paragraph>Pause your subscription until you’re ready to start hiring again.</Paragraph>
          </Box>
          <S.WarningBlock>
            <S.Icon>
              <Icon name={Icons.alertTriangle} color='warningFg' />
            </S.Icon>
            <Caption as='span' size='SM' $fontWeight={400}>
              When you pause your subscription:
              <ul>
                <li>You won’t be charged until you’re ready to unpause.</li>
                <li>All your job positions and outreach will be paused.</li>
                <li>You won’t need to setup everything again when you’re ready to come back.</li>
              </ul>
            </Caption>
          </S.WarningBlock>
          <Button
            $width='fit-content'
            $variant='fill'
            $colorTheme='tint'
            $height={40}
            onClick={() => {
              setPausingSubscription(true)
              pauseSubscription({
                onSuccess: () => {
                  setPausingSubscription(false)
                  onClose()
                },
                onError: () => {
                  setPausingSubscription(false)
                }
              })
            }}
            disabled={pausingSubscription || paused}
          >
            {paused ? 'Subscription paused' : 'Pause Subscription'}
          </Button>
        </S.PauseSubscriptionBox>
      )}
      <S.FeedbackBlock $boxed={!expired}>
        <Box $display='flex' $flexDirection='column' $gap={8} $padding={{ top: 0, bottom: 0, left: 4, right: 4 }} $flex='0 0 auto'>
          <Caption size='MD'>Close Pin account</Caption>
          <Paragraph>We're sorry if Pin hasn't lived to your expectations.<br/>Please help us by describing why you won't be using Pin for your recruiting needs.</Paragraph>
        </Box>
        <Form onSubmit={submit(handleSubmit)}>
          <Flex $direction='column' $gap={24} $width='100%'>
            <S.RadioGroupContainer>
              <RadioGroup
                name='reason'
                register={register}
                hiddenLabel
                variant='bullets'
                options={[
                  { title: ClosePinAccountFeedbackReason.ThePriceAintRight, value: ClosePinAccountFeedbackReason.ThePriceAintRight },
                  { title: ClosePinAccountFeedbackReason.NotEnoughCandidates, value: ClosePinAccountFeedbackReason.NotEnoughCandidates },
                  { title: ClosePinAccountFeedbackReason.SimilarApp, value: ClosePinAccountFeedbackReason.SimilarApp },
                  { title: ClosePinAccountFeedbackReason.Other, value: ClosePinAccountFeedbackReason.Other }
                ]}
                $marginBottom={0}
              />
            </S.RadioGroupContainer>
            {formData.reason === ClosePinAccountFeedbackReason.Other && (
              <Textarea
                name='reasonDetails'
                register={register}
                hiddenLabel
                placeholder='Please specify a reason or provide feedback.'
                $marginBottom={0}
                rows={5}
                autoFocus={!subscribed}
                $width='100%'
              />
            )}
            <Checkbox
              name='feedbackCall'
              register={register}
              label='I’m open to a 15 minute feedback call with the Pin team.'
              $marginBottom={0}
              defaultChecked={true}
            />
            <S.WarningBlock>
              <S.Icon>
                <Icon name={Icons.alertTriangle} color='warningFg' />
              </S.Icon>
              <Caption as='span' size='SM' $fontWeight={400}>
                When you close your account:
                <ul>
                  <li>Your Pin data will be lost.</li>
                  <li>You won’t be eligible for a new trial in a new account.</li>
                </ul>
              </Caption>
            </S.WarningBlock>
            <Button
              $variant='outline'
              $colorTheme='negative'
              $height={40}
              type='submit'
              disabled={closingAccount}
            >
              Send feedback and close account
            </Button>
          </Flex>
        </Form>
      </S.FeedbackBlock>
    </Flex>
  )
}
