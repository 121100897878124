import { Form } from 'src/components/forms'
import { Select } from 'src/components/forms/select'
import { Flex } from 'src/components/primitives/flex'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { useForm } from 'src/hooks/use-form'
import { EmailSearchType, EmailSearchTypeLabel } from 'src/libs/api/backend/jobs'
import { z } from 'zod'
import * as S from './email-lookup-settings.styled'
import { useSession } from 'src/hooks/use-session'

const emailLookUpSchema = z.object({
  emailSearchType: z.nativeEnum(EmailSearchType)
})
type EmailLookUpForm = z.infer<typeof emailLookUpSchema>

interface EmailLookUpSettingsProps {
  onChange: (emailSearch: EmailSearchType) => void
}

export const EmailLookUpSettings = ({ onChange }: EmailLookUpSettingsProps): JSX.Element => {
  const { isPending, data: emailSequence } = useJobSequenceQuery()
  const { userHasViewerRole } = useSession()
  const { register, submit } = useForm<EmailLookUpForm>({
    schema: emailLookUpSchema
  })

  const handleSubmit = (data: EmailLookUpForm): void => {
    console.log(data)
  }

  return (
    <>
      <Flex $align="center" $justify="space-between">
        <Flex $direction="column" $gap={2}>
          <Caption size="SM">Email lookups</Caption>
          <Paragraph size="XS" $color="fgSecondary">
            Select which type of email address to find while sourcing.
          </Paragraph>
        </Flex>
        <S.FormWrapper>
          <Form onSubmit={submit(handleSubmit)}>
            <Select
              placeholder={emailSequence?.emailSearch ? EmailSearchTypeLabel[emailSequence.emailSearch] : 'Select email search type'}
              showIcons={false}
              hiddenLabel={true}
              $marginBottom={0}
              defaultValue={emailSequence?.emailSearch}
              loading={isPending}
              disabled={userHasViewerRole}
              items={[
                {
                  value: EmailSearchType.WORK_EMAIL_SEARCH,
                  title: EmailSearchTypeLabel[EmailSearchType.WORK_EMAIL_SEARCH]
                },
                {
                  value: EmailSearchType.PERSONAL_EMAIL_SEARCH,
                  title: EmailSearchTypeLabel[EmailSearchType.PERSONAL_EMAIL_SEARCH]
                }
              ]}
              onValueChange={(value) => {
                if (userHasViewerRole) {
                  return
                }
                if (value) {
                  onChange(value as EmailSearchType)
                }
              }}
              name="emailSearchType"
              register={register}
              $fontSize={12}
            />
          </Form>
        </S.FormWrapper>
      </Flex>
    </>
  )
}
