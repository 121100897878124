import { debounce, isNil } from 'lodash'
import { DayAvailabilityPicker } from './day-availability-picker'
import * as S from './week-availability-picker.styled'
import { useEffect, useMemo, useState } from 'react'

interface WeekAvailabilityPickerProps {
  defaultAvailability?: number[]
  onAvailabilityChange: (updated: number[]) => void
  disabled?: boolean
}

export const WeekAvailabilityPicker = ({ defaultAvailability, onAvailabilityChange, disabled }: WeekAvailabilityPickerProps): JSX.Element => {
  const [dailyAvailability, setDailyAvailability] = useState<number[][] | undefined>(undefined)
  // const isInitialLoad = useRef(true)

  const debouncedUpdate = useMemo(() => {
    return debounce(onAvailabilityChange, 300)
  }, [onAvailabilityChange])

  useEffect(() => {
    if (isNil(dailyAvailability) && defaultAvailability) {
      const convertedDays: number[][] = Array.from({ length: 7 }, () => [])
      const sortedDefaultAvailability = defaultAvailability.sort((a, b) => a - b)
      sortedDefaultAvailability.forEach((hour: number) => {
        const day = Math.floor(hour / 24)
        const hourOfDay = hour % 24
        convertedDays[day].push(hourOfDay)
      })
      setDailyAvailability(convertedDays)
    }
  }, [dailyAvailability, defaultAvailability])

  return (
    <S.Hours $disabled={disabled}>
      {Array.from({ length: 7 }, (_, day) => (
        <DayAvailabilityPicker
          key={day}
          day={day}
          defaultAvailability={dailyAvailability?.[day]}
          onAvailabilityChange={(updated) => {
            setDailyAvailability((prev) => {
              if (isNil(prev)) {
                return prev
              }
              const updatedAvailability = [...prev]
              updatedAvailability[day] = updated
              debouncedUpdate(updatedAvailability.flat())
              return updatedAvailability
            })
          }}
        />
      ))}
    </S.Hours>
  )
}
