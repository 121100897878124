import { Caption } from 'src/components/primitives/typography'
import * as S from './automate-info-banner.styled'
import { Icon, Icons } from 'src/components/primitives/icon'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useUpdateJobSearchRefinement } from 'src/hooks/mutations/use-update-job-search-refinement'
import { Button } from 'src/components/primitives'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { useSetAtom } from 'jotai'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import { useSession } from 'src/hooks/use-session'

interface AutomateInfoBannerProps {
  autoApproveIsEnabled: boolean
  disabled?: boolean
  disabledTooltip?: string
}

export const AutomateInfoBanner = ({ autoApproveIsEnabled, disabled = false, disabledTooltip }: AutomateInfoBannerProps): JSX.Element => {
  const { jobId } = useParams()
  const { data: jobData } = useJobQuery()
  const { jobSearchRefinementId } = useParams()
  const { updateJobSearchRefinement } = useUpdateJobSearchRefinement()
  const openDialog = useSetAtom(openDialogAtom)
  const { data: sequence } = useJobSequenceQuery()
  const { userHasViewerRole } = useSession()
  const jobHasSequence = !isSequenceStepsEmpty(sequence)

  const handleUpdateJobRefinement = useCallback((isEnabled: boolean): void => {
    updateJobSearchRefinement({
      jobId: jobId ?? '',
      jobSearchRefinementId: jobSearchRefinementId ?? '',
      automateAutoApproveCandidates: isEnabled
    })
  }, [updateJobSearchRefinement, jobId, jobSearchRefinementId])

  if (jobData?.deleted) {
    return <></>
  }

  if (!autoApproveIsEnabled) {
    return (
      <Button
        $variant="outline"
        $colorTheme="muted"
        $height={24}
        $fontSize={12}
        leadingIcon={Icons.sparkles}
        onClick={() => {
          if (jobHasSequence) {
            openDialog({ id: DialogId.ENABLE_AUTO_OUTREACH })
          } else {
            openDialog({ id: DialogId.CREATE_SEQUENCE })
          }
        }}
        disabled={disabled || userHasViewerRole}
        tooltip={{
          text: disabled ? disabledTooltip : undefined,
          position: 'top'
        }}
      >
        Auto-outreach
      </Button>
    )
  }

  return (
    <S.ToggleWrapper
      onClick={() => {
        handleUpdateJobRefinement(!autoApproveIsEnabled)
      }}
    >
      <Icon name={Icons.sparkles} size={12} />
      <Caption size="XS">Auto-outreach</Caption>
      <S.ToggleButton $isChecked={autoApproveIsEnabled}>
        <span>{autoApproveIsEnabled ? 'ON' : 'OFF'}</span>
      </S.ToggleButton>
    </S.ToggleWrapper>
  )
}
