import { useEffect } from 'react'
import 'driver.js/dist/driver.css'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import { productTour, ProductTourStep } from 'src/libs/product-tour'
import { ProductTourStyles } from './product-tour.styled'
import { useLocalStorage } from 'usehooks-ts'
import { LocalStorageKey } from 'src/constants'
import CONFIG from 'src/config'
import { useSession } from 'src/hooks/use-session'
import { useLocation } from 'react-router-dom'
import { useUpdateUserTrackingAndStatuses } from 'src/hooks/mutations/use-update-user-tracking-and-statuses'
import { ProductTourStatus } from 'src/libs/api/backend/me'
import { trackEvent } from 'src/libs/track'
import { TrackingEventType } from 'src/types/track'

export const ProductTourProvider = (): JSX.Element => {
  const openDialog = useSetAtom(openDialogAtom)
  const { user } = useSession()
  const userAccountIsTooOldForProductTour = user?.createdAt && new Date(user.createdAt) < new Date('2025-01-20')
  const { pathname } = useLocation()
  // This is being set to true in sourcing.tsx once sourced candidates are loaded
  const [appIsReadyForProductTour] = useLocalStorage(LocalStorageKey.APP_IS_READY_FOR_PRODUCT_TOUR, false)
  const { updateUserTrackingAndStatuses } = useUpdateUserTrackingAndStatuses()

  useEffect(() => {
    if (user?.productTourStatus !== ProductTourStatus.NOT_STARTED || userAccountIsTooOldForProductTour || !pathname.startsWith('/jobs')) {
      return
    }

    if (!appIsReadyForProductTour) {
      return
    }

    openDialog({ id: DialogId.WELCOME_TO_PIN })
    productTour.setConfig({
      showButtons: ['next', 'previous'],
      nextBtnText: 'Next',
      prevBtnText: 'Previous',
      onPopoverRender: (popover) => {
        const bookADemoFooter = document.createElement('div')
        bookADemoFooter.className = 'book-a-demo-footer'

        // Attributes to make button a trigger for Cal.com
        bookADemoFooter.setAttribute('data-cal-namespace', CONFIG.CAL_NAMESPACE)
        bookADemoFooter.setAttribute('data-cal-link', CONFIG.CAL_LINK)
        bookADemoFooter.setAttribute('data-cal-config', '{"layout":"month_view"}')

        const bookADemoButton = document.createElement('button')

        const calendarIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
        calendarIcon.setAttribute('width', '13')
        calendarIcon.setAttribute('height', '12')
        calendarIcon.setAttribute('viewBox', '0 0 13 12')
        calendarIcon.setAttribute('fill', 'none')
        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
        path.setAttribute('fill-rule', 'evenodd')
        path.setAttribute('clip-rule', 'evenodd')
        path.setAttribute('d', 'M4.5 0.5C4.77614 0.5 5 0.723858 5 1V1.5H8V1C8 0.723858 8.22386 0.5 8.5 0.5C8.77614 0.5 9 0.723858 9 1V1.5H10C10.8284 1.5 11.5 2.17157 11.5 3V10C11.5 10.8284 10.8284 11.5 10 11.5H3C2.17157 11.5 1.5 10.8284 1.5 10V3C1.5 2.17157 2.17157 1.5 3 1.5H4V1C4 0.723858 4.22386 0.5 4.5 0.5ZM4 2.5H3C2.72386 2.5 2.5 2.72386 2.5 3V4.5H10.5V3C10.5 2.72386 10.2761 2.5 10 2.5H9V3C9 3.27614 8.77614 3.5 8.5 3.5C8.22386 3.5 8 3.27614 8 3V2.5H5V3C5 3.27614 4.77614 3.5 4.5 3.5C4.22386 3.5 4 3.27614 4 3V2.5ZM10.5 5.5H2.5V10C2.5 10.2761 2.72386 10.5 3 10.5H10C10.2761 10.5 10.5 10.2761 10.5 10V5.5Z')
        path.setAttribute('fill', '#114FD7')

        calendarIcon.appendChild(path)

        // Add SVG and text to button
        bookADemoButton.appendChild(calendarIcon)
        const textNode = document.createTextNode('Book A Demo')
        bookADemoButton.appendChild(textNode)

        bookADemoButton.className = 'book-a-demo-button'
        bookADemoButton.onclick = () => {
          console.log('Custom button clicked!')
        }
        bookADemoFooter.appendChild(bookADemoButton)
        popover.footer.appendChild(bookADemoFooter)
      },
      onNextClick: () => {
        productTour.moveNext()
        if (!productTour.hasNextStep()) {
          updateUserTrackingAndStatuses({ productTourStatus: ProductTourStatus.COMPLETED })
          trackEvent(TrackingEventType.PRODUCT_TOUR_COMPLETED)
        }
      }
    })

    productTour.setSteps([
      {
        element: `#${ProductTourStep.CANDIDATE_ACTIONS}`,
        popover: {
          title: 'Outreach or Decline sourced candidates to fine-tune results.',
          side: 'bottom'
        }
      },
      {
        element: `#${ProductTourStep.SEARCH_CRITERIA}`,
        popover: {
          title: 'Modify your search criteria at any moment.',
          side: 'bottom'
        }
      },
      {
        element: `#${ProductTourStep.MENU_SOURCING}`,
        popover: {
          title: 'Create unlimited searches with different criteria.',
          side: 'right'
        }
      },
      {
        element: `#${ProductTourStep.MENU_OUTREACH}`,
        popover: {
          title: 'Pin can help you create a custom email sequence and reach out to candidates.',
          side: 'right'
        }
      },
      {
        element: `#${ProductTourStep.MENU_INBOX}`,
        popover: {
          title: 'All emails and LinkedIn messages with candidates are centralized in one place.',
          side: 'right'
        }
      },
      {
        element: `#${ProductTourStep.MENU_REPORTS}`,
        popover: {
          title: 'As you progress, all your team stats can be found in reports.',
          side: 'right'
        }
      },
      {
        element: `#${ProductTourStep.MENU_JOB_SETTINGS}`,
        popover: {
          title: 'Configure the specifics of your job position.',
          side: 'right'
        }
      },
      {
        element: `#${ProductTourStep.MENU_ACCOUNT_SETTINGS}`,
        popover: {
          title: 'Manage your account settings such as team members, billing or connected email or LinkedIn accounts.',
          side: 'top'
        }
      }
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.productTourStatus, userAccountIsTooOldForProductTour, pathname, appIsReadyForProductTour])

  return <ProductTourStyles />
}
