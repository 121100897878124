import * as S from './index.styled'
import type { CriteriaKey, CriteriaValue, SchoolCompanyCriteria } from 'src/libs/api/backend/candidate_search'
import { CriteriaPill } from './criteria-pill'
import { RemoveCriteria } from './remove-criteria'
import { useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { useSchoolSuggestion } from 'src/hooks/queries/use-school-suggestion'
import { SuggestionInput } from './suggestion-input'
import type { SchoolSuggestion } from 'src/libs/api/backend/typeaheads'
import { CriteriaProperties } from '../constants'

interface SchoolRowProps {
  criteriaKey: CriteriaKey
  criteriaValue: SchoolCompanyCriteria[]
  onCriteriaUpdate: (criteriaKey: CriteriaKey, criteriaValue: CriteriaValue) => void
  onCriteriaRemove: (criteriaKey: CriteriaKey) => void
  disabled: boolean
}

export const SchoolRow = ({
  criteriaKey,
  criteriaValue,
  onCriteriaUpdate,
  onCriteriaRemove,
  disabled
}: SchoolRowProps): JSX.Element => {
  const [query, setQuery] = useState<string | undefined>(undefined)
  const setQueryDebounced = useMemo(() => {
    return debounce(setQuery, 300)
  }, [])
  const [schoolIcons, setSchoolIcons] = useState<Record<string, string>>({})
  const { isPending, data: schoolSuggestions } = useSchoolSuggestion({ query })
  const type = useMemo(() => {
    return CriteriaProperties.get(criteriaKey)?.type ?? 'school'
  }, [criteriaKey])
  return (
    <S.RowContainer>
      <SuggestionInput
        isLoading={isPending}
        disabled={disabled}
        placeholder={criteriaValue.length === 0 ? 'Add school' : undefined}
        triggerSearch={setQueryDebounced}
        suggestions={schoolSuggestions ?? []}
        onSelect={(suggestion) => {
          onCriteriaUpdate(
            criteriaKey,
            [...criteriaValue, {
              name: suggestion.name,
              linkedin: (suggestion as SchoolSuggestion).linkedin ?? undefined,
              domain: (suggestion as SchoolSuggestion).domain ?? undefined,
              logoUrl: (suggestion as SchoolSuggestion).logoUrl ?? undefined,
              optional: false,
              negative: false
            }]
          )
          if ('logoUrl' in suggestion && suggestion.logoUrl) {
            setSchoolIcons({
              ...schoolIcons,
              [suggestion.name]: suggestion.logoUrl
            })
          }
        }}
        items={
          <>
            {criteriaValue.map((schoolValue, index) => {
              return (
                <CriteriaPill
                  key={index}
                  value={{
                    label: schoolValue.name ?? '',
                    icon: schoolValue.logoUrl
                      ? schoolValue.logoUrl
                      : schoolValue.name
                        ? schoolIcons[schoolValue.name]
                        : undefined,
                    optional: schoolValue.optional ?? true,
                    negative: schoolValue.negative ?? false
                  }}
                  type={type}
                  onUpdate={(key: string, value: boolean | number | null) => {
                    const updatedCriteriaValue = [...criteriaValue]
                    updatedCriteriaValue[index] = {
                      ...updatedCriteriaValue[index],
                      [key]: value
                    }
                    onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
                  }}
                  onRemove={() => {
                    const updatedCriteriaValue = criteriaValue.filter((_, i) => i !== index)
                    onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
                  }}
                />
              )
            })}
          </>
        }
      />
      <RemoveCriteria
        onRemove={() => {
          onCriteriaRemove(criteriaKey)
        }}
        disabled={disabled}
      />
    </S.RowContainer>
  )
}
