import { useEffect, useRef, useState } from 'react'
import * as RadixSelect from '@radix-ui/react-select'
import { FieldLabel } from '../field-label'
import { FieldError } from '../field-error'
import type { FieldCommonProps } from '../common'
import { FormElement } from '../form-element'
import { Icon } from 'src/components/primitives/icon'
import { Avatar } from 'src/components/primitives/avatar'
import * as S from './select.styled'
import { When } from 'src/components/blocks/when'
import { isNil } from 'lodash'
import { Spinner } from 'src/components/primitives/spinner'
import { Flex } from 'src/components/primitives/flex'
import { Paragraph } from 'src/components/primitives/typography'
import { Spacer } from 'src/components/primitives/spacer'
import type { FontSize } from 'src/styles/theme/types'

export interface SelectItem {
  value: string
  title: string
  image?: JSX.Element
  trailingIcon?: JSX.Element
  disabled?: boolean
}

interface CreateItem {
  value: string
  title: string
  onClick: () => void
}

interface SelectProps extends FieldCommonProps {
  placeholder: string
  defaultValue?: string
  items: SelectItem[]
  createItem?: CreateItem
  createItemIsSticky?: boolean
  emptyStateText?: string
  showIcons?: boolean
  $maxHeight?: number
  $height?: number
  $fontSize?: FontSize
  onValueChange?: (value: string) => void
  onReset?: () => void
  disabled?: boolean
  loading?: boolean
  tooltip?: string
  allowFilter?: boolean
}

export const Select = ({
  placeholder,
  name,
  label,
  hiddenLabel = false,
  description,
  defaultValue,
  items,
  showIcons = true,
  register,
  $marginBottom = 16,
  $maxHeight,
  $height,
  $fontSize = 14,
  createItem,
  createItemIsSticky = false,
  emptyStateText,
  onValueChange = undefined,
  onReset = undefined,
  disabled = false,
  loading = false,
  tooltip,
  allowFilter = false
}: SelectProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const [defaultSelected, setDefaultSelected] = useState(defaultValue)
  const [renderItems, setRenderItems] = useState<SelectItem[]>([])
  const [filterValue, setFilterValue] = useState('')
  const [lockedPosition, setLockedPosition] = useState<'top' | 'bottom' | null>(null)
  const { onSelect, error, value } = register(name)

  const filterInputEl = useRef<HTMLInputElement>(null)
  const selectContentEl = useRef<HTMLDivElement>(null)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault()
    setFilterValue(event.target.value)
  }

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    event.stopPropagation()
    if (event.key === 'ArrowDown' && renderItems.length > 0) {
      event.preventDefault()
      const firstItem = selectContentEl?.current?.querySelector('[data-radix-collection-item]')
      if (firstItem) {
        (firstItem as HTMLElement).focus()
      }
    }
  }

  const handleItemKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    event.stopPropagation()
    if (event.key === 'Backspace' && filterInputEl.current) {
      event.preventDefault()
      filterInputEl.current.focus()
    }
  }

  useEffect(() => {
    if (isOpen && allowFilter) {
      setTimeout(() => {
        if (filterInputEl.current) {
          filterInputEl.current.focus()
        }
      }, 100)
    }
  }, [isOpen, allowFilter])

  useEffect(() => {
    if (defaultValue) {
      setDefaultSelected(defaultValue)
      onSelect(defaultValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  useEffect(() => {
    if (filterValue.trim().length >= 2) {
      const filteredItems = items.filter(item => {
        if (item.disabled) {
          return false
        }
        return item.title.toLowerCase().includes(filterValue.toLowerCase())
      })
      setRenderItems(filteredItems)
    } else {
      setRenderItems(items)
    }
  }, [filterValue, items])

  return (
    <FormElement $marginBottom={$marginBottom}>
      <RadixSelect.Root
        open={isOpen}
        disabled={disabled}
        onOpenChange={(open) => {
          setIsOpen(open)
          if (!open) {
            setFilterValue('')
          }
          if (open && allowFilter) {
            setLockedPosition(null)
            setTimeout(() => {
              const content = selectContentEl.current
              if (content) {
                const isTop = content.getAttribute('data-side') === 'top'
                setLockedPosition(isTop ? 'top' : 'bottom')
              }
            }, 0)
            if (filterInputEl.current) {
              filterInputEl.current.focus()
            }
          }
        }}
        onValueChange={(value: string) => {
          onSelect(value)

          if (!isNil(onValueChange)) {
            onValueChange(value)
          }
        }}
        defaultValue={defaultSelected}
        value={value as string}
      >
        {
          !hiddenLabel && (
            <S.Label>
              <FieldLabel
                label={label}
                htmlFor={name}
                hiddenLabel={hiddenLabel}
                description={description}
                marginBottom={0}
                tooltip={tooltip}
              />
              {onReset && typeof value === 'string' && value && (
                <S.ResetButton type="button" onClick={onReset}>
                  Clear
                </S.ResetButton>
              )}
            </S.Label>
          )
        }
        <S.Trigger aria-label={placeholder} $isLoading={loading} $fontSize={$fontSize} $height={$height} $disabled={disabled}>
          <RadixSelect.Value placeholder={placeholder} />
          {loading
            ? <Spinner />
            : <S.TriggerIcon>
                <Icon name="chevrons-up-down" size={12} />
              </S.TriggerIcon>
          }
        </S.Trigger>
        <RadixSelect.Portal>
          <S.Content position="popper" sideOffset={6} ref={selectContentEl} side={lockedPosition ?? undefined}>
            <S.Viewport $maxHeight={$maxHeight} $hasStickyItem={createItemIsSticky} $hasFilter={allowFilter}>
              <When condition={allowFilter}>
                <S.Searchbar>
                  <Icon name="search" size={16} color="fgSecondary" />
                  <input
                    ref={filterInputEl}
                    placeholder='Filter'
                    value={filterValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                  />
                </S.Searchbar>
              </When>
              <RadixSelect.Group>
                {renderItems?.map((item) => (
                  <S.Item
                    key={item.value}
                    value={item.value}
                    disabled={item.disabled}
                    $hasStickyItem={createItemIsSticky}
                    $fontSize={$fontSize}
                    $hasFilter={allowFilter}
                    onKeyDown={handleItemKeyDown}
                  >
                    <S.ItemMain>
                      <When condition={showIcons}>
                        {item.image ?? <Avatar initials={item.title} $size={20} $shape="soft" />}
                      </When>
                      <RadixSelect.ItemText asChild>
                        <S.ItemText $fontSize={$fontSize}>{item.title}</S.ItemText>
                      </RadixSelect.ItemText>
                    </S.ItemMain>
                    {item.trailingIcon}
                  </S.Item>
                ))}
                {!renderItems.length && emptyStateText && (
                  <S.EmptyState>
                  <Flex $gap={8} $direction="column" $align="center">
                    <Icon name="binoculars" size={24} color="fgTranslucent10" />
                    <Paragraph size="SM" $color="fgSecondary" $align="center">
                      {emptyStateText}
                    </Paragraph>
                    <Spacer $size={16} />
              </Flex>
                  </S.EmptyState>
                )}
              </RadixSelect.Group>
              {!createItemIsSticky && createItem?.title
                ? (
                    <S.CreateItemSection>
                      <S.CreateItemButton
                        onClick={() => {
                          createItem.onClick()
                          setIsOpen(false)
                        }}
                        $fontSize={$fontSize}
                      >
                        <S.CreateItemButtonIcon>
                          <Icon name="plus" color="tintBg" />
                        </S.CreateItemButtonIcon>
                        {createItem.title}
                      </S.CreateItemButton>
                    </S.CreateItemSection>
                  )
                : null}
              <When condition={createItemIsSticky}>
                <S.StickyItem>
                    <S.CreateItemButton
                      onClick={() => {
                        createItem?.onClick()
                        setIsOpen(false)
                      }}
                    >
                      <S.CreateItemButtonIcon>
                        <Icon name="plus" color="tintBg" />
                      </S.CreateItemButtonIcon>
                      {createItem?.title}
                    </S.CreateItemButton>
                </S.StickyItem>
              </When>
            </S.Viewport>
          </S.Content>
        </RadixSelect.Portal>
      </RadixSelect.Root>
      {error ? <FieldError id={`${name}-error`}>{error}</FieldError> : null}
    </FormElement>
  )
}
