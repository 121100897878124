import type { ButtonHTMLAttributes, AnchorHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { getVariantStyles } from '../button/utils'
import type { DoubleButtonStyleProps } from './double-button'

interface DoubleButtonProps extends DoubleButtonStyleProps {
  $hasNoSecondaryAction?: boolean
  $disabled?: boolean
}

type ButtonOrAnchorProps = ButtonHTMLAttributes<HTMLButtonElement> &
AnchorHTMLAttributes<HTMLAnchorElement>

export const DoubleButton = styled.div<DoubleButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: ${({ theme, $height }) => theme.spacing[$height ?? 48]};
  /* 0.375rem: padding right, 16px: button width, 8px: gap */
  padding: 0 calc(0.375rem + 16px + 8px) 0 0.625rem;
  ${({ $hasNoSecondaryAction }) => $hasNoSecondaryAction && 'padding-right: 0.625rem;'}
    ${({ theme, $colorTheme, $variant }) =>
    getVariantStyles($variant ?? 'raised', $colorTheme ?? 'normal', theme)}
`

export const Primary = styled.button.attrs<{ as?: React.ElementType }>({})<DoubleButtonProps & ButtonOrAnchorProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
  width: auto;
  min-width: ${({ $minWidth }) => $minWidth};
  height: 100%;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  white-space: nowrap;
  cursor: pointer;
  ${({ theme, $colorTheme, $variant }) =>
    getVariantStyles($variant ?? 'raised', $colorTheme ?? 'normal', theme)}
  background-color: transparent;
  border: none;
  box-shadow: none;
  svg {
    margin-left: ${({ $hasNoSecondaryAction }) => $hasNoSecondaryAction ? '0' : 'calc(8px + 16px)'};
  }
  ${({ $disabled }) => $disabled && css`
    pointer-events: none;
    opacity: 0.5;
    @media (hover: hover) {
      &:hover {
        background-color: transparent !important;
      }
    }
  `}
`

export const SecondaryWrapper = styled.div<{ $disabled?: boolean }>`
  position: absolute;
  bottom: 4px;
  right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $disabled }) => $disabled && css`
    pointer-events: none;
  `}
`

export const Secondary = styled.button`
  all: unset;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  border: solid 1px red;
`
