import { Form, ImageUpload, Input } from 'src/components/forms'
import { useForm } from 'src/hooks/use-form'
import { useClearbit } from 'src/hooks/queries/use-clearbit'
import { useAvatarQuery } from 'src/hooks/queries/use-avatar'
import { useCallback, useEffect, useState } from 'react'
import { useSession } from 'src/hooks/use-session'
import { isNil } from 'lodash'
import { FormActionButtons } from '../form-action-buttons'
import { z } from 'zod'

const companyProfileSchema = z.object({
  companyUrl: z.string(),
  companyName: z.string().min(1, { message: 'Required' }),
  companyMission: z.string().nullish(),
  companyLogo: z.union([z.instanceof(File), z.string()]).nullish(),
  companyLocation: z.string().nullish(),
  companyIndustries: z.array(z.object({
    title: z.string(),
    value: z.string()
  })).nullish()
})

export type CompanyProfileFormData = z.infer<typeof companyProfileSchema>

const initialValues = {
  companyName: '',
  companyUrl: ''
}

interface CompanyProfileFormProps {
  onSubmit?: (data: CompanyProfileFormData) => void
  submitButtonText?: string
  disableSubmit?: boolean
}

export const CompanyProfileForm = ({ onSubmit, submitButtonText = 'Continue', disableSubmit = false }: CompanyProfileFormProps): JSX.Element => {
  const {
    user,
    emailAccountAccessTokens,
    org,
    orgLogoUrl,
    status: sessionQueryStatus,
    userHasViewerRole
  } = useSession()

  const [url, setUrl] = useState<string | null>(null)
  const [photoUrl, setPhotoUrl] = useState<string | null>(null)

  const { isPending: clearbitDataIsLoading, data: clearbitData } = useClearbit({ url })
  const { isPending: photoIsLoading, data: photoData } = useAvatarQuery({ url: photoUrl })

  const { submit, errors, register, setValue, formData } = useForm<CompanyProfileFormData>({
    schema: companyProfileSchema,
    initialValues
  })

  useEffect(() => {
    if (sessionQueryStatus === 'success' && org) {
      setValue('companyUrl', org.domain)
      setValue('companyName', org.name)
      if (!isNil(orgLogoUrl)) {
        setPhotoUrl(orgLogoUrl)
      }
    } else if (clearbitData?.success && sessionQueryStatus !== 'pending') {
      setValue('companyUrl', clearbitData.companyUrl)
      setValue('companyName', clearbitData.companyName)
      setPhotoUrl(clearbitData.logoUrl)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionQueryStatus, clearbitData, org, orgLogoUrl])

  useEffect(() => {
    if (!isNil(emailAccountAccessTokens) && emailAccountAccessTokens[0]?.email) {
      const domain = emailAccountAccessTokens[0].email.split('@')[1]
      setUrl(domain)
      setValue('companyUrl', domain)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAccountAccessTokens])

  useEffect(() => {
    if (!isNil(photoData)) {
      // console.log({ photoData })
      setValue('companyLogo', photoData)
    }
  }, [photoData]) // eslint-disable-line react-hooks/exhaustive-deps -- setValue causes an infinite loop

  const handleSubmit = useCallback((data: CompanyProfileFormData): void => {
    if (isNil(user)) {
      return
    }
    if (onSubmit) {
      onSubmit(data)
    }
  }, [onSubmit, user])

  return (
    <Form onSubmit={submit(handleSubmit)} errors={errors}>
      <Input
        icon="globe"
        hiddenLabel
        label="Team website"
        placeholder="example.com"
        name="companyUrl"
        isLoading={clearbitDataIsLoading || (!!photoUrl && photoIsLoading)}
        isDisabled
        register={register}
      />
      <Input
        label="Org name"
        placeholder="e.g. RecruitCo"
        name="companyName"
        register={register}
        isDisabled={userHasViewerRole}
      />
      <ImageUpload
        $previewSize={64}
        $previewShape="rounded"
        label="Org logo"
        name="companyLogo"
        register={register}
        value={(formData as CompanyProfileFormData).companyLogo}
        $marginBottom={0}
        disabled={userHasViewerRole}
      />
      <FormActionButtons saveText={submitButtonText} disabled={disableSubmit || userHasViewerRole} />
    </Form>
  )
}
