import { Form } from 'src/components/forms/form'
import * as S from './create-new-job-form.styled'
import { Select } from 'src/components/forms/select'
import type { SelectItem } from 'src/components/forms/select'
import { useGetDepartmentQuery } from 'src/hooks/queries/use-departments'
import { useForm } from 'src/hooks/use-form'
import { isNil } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DepartmentLogo } from '../department-logo'
import { useSession } from 'src/hooks/use-session'
import { Avatar } from 'src/components/primitives/avatar'
import { Input } from 'src/components/forms/input'
import { Textarea } from 'src/components/forms/textarea'
import { Button } from 'src/components/primitives/button'
import { Spacer } from 'src/components/primitives/spacer'
import { FieldLabel } from 'src/components/forms/field-label'
import { When } from '../when'
import { Flex } from 'src/components/primitives/flex'
import { Icon } from 'src/components/primitives/icon'
import { Combobox } from 'src/components/forms/combobox'
import type { ComboboxOption } from 'src/components/forms/combobox'
import { useJobListings } from 'src/hooks/queries/use-job-listings'
import { newJobParser } from 'src/libs/api/backend/jobs'
import type { NewJob } from 'src/libs/api/backend/jobs'
import type { JobBoardListing } from 'src/libs/api/backend/external_job_listings'
import { Spinner } from 'src/components/primitives/spinner'
import RouteBuilder from 'src/libs/route-builder'
import { useNavigate } from 'react-router-dom'
import { useCreateNewJob } from 'src/hooks/mutations/use-create-new-job'
import type { ExtractJobRequirementsInput } from 'src/libs/api/backend/gpt'
import { Caption } from 'src/components/primitives/typography'
import { FieldError } from 'src/components/forms/field-error'
import { useProjectsQuery } from 'src/hooks/queries/use-projects'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { useSetAtom } from 'jotai'
import { closeDialogAtom, DialogId, openDialogAtom } from 'src/stores/dialogs'
import { REGEX_URL } from 'src/libs/regex'
import { pluralize } from 'src/libs/pluralize'
import { useOnboardingSteps } from 'src/hooks/use-onboarding-steps'
import { subMinutes } from 'date-fns'

enum FormView {
  START = 'START',
  IMPORT_FROM_LINK = 'IMPORT_FROM_LINK',
  GENERATING = 'GENERATING'
}

interface CreateNewJobFormProps {
  redirectType?: 'onboarding' | 'jobs'
  onCreateJob?: () => void
}

interface JobPostLinkType {
  jobPostUrl: string | null
  inputIsFocused: boolean
  isImporting: boolean
  isError: boolean
  importedJob: ExtractJobRequirementsInput | null
}

const ORG_SELECT_VALUE = '<<org>>'

export const CreateNewJobForm = ({
  redirectType = 'jobs',
  onCreateJob
}: CreateNewJobFormProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formView, setFormView] = useState<FormView>(FormView.START)
  const [isLoadingJobsForSelectedDepartment, setIsLoadingJobsForSelectedDepartment] = useState(true)
  const { data: projects } = useProjectsQuery()
  const [allImportedJobListings, setAllImportedJobListings] = useState<JobBoardListing[] | null>(null)
  const [importedJobsByDepartment, setImportedJobsByDepartment] = useState<ComboboxOption[] | null>(null)
  const [jobToImportFromLink, setJobToImportFromLink] = useState<JobPostLinkType>({
    jobPostUrl: null,
    inputIsFocused: false,
    isImporting: false,
    isError: false,
    importedJob: null
  })

  const navigate = useNavigate()
  const { createNewJob } = useCreateNewJob()
  const openDialog = useSetAtom(openDialogAtom)
  const closeDialog = useSetAtom(closeDialogAtom)
  const { departments } = useGetDepartmentQuery()
  const { org, orgLogoUrl, featureFlags, userHasViewerRole } = useSession()
  const { nextStep: nextOnboardingStep } = useOnboardingSteps()

  const importLinkInputEl = useRef<HTMLInputElement>(null)

  const { submit, register, setValue, formData, reset } = useForm<NewJob>({
    schema: newJobParser,
    initialValues: {
      departmentId: ORG_SELECT_VALUE
    }
  })

  const isFormValid = useMemo(() => {
    return (
      !isNil(formData.title) &&
      formData.title !== '' &&
      !isNil(formData.description) &&
      formData.description !== ''
    )
  }, [formData.title, formData.description])

  const {
    data: importedJobs,
    isError: importedJobsError
  } = useJobListings({
    url: jobToImportFromLink?.jobPostUrl,
    domain: departments?.find((department) => department.id === formData?.departmentId)?.domain ?? org?.domain
  })

  // This is called when selecting a job from the dropdown listing
  // all imported jobs from all departments
  const handlePopulateFormFromImportedJobListing = useCallback(async (value: string): Promise<void> => {
    const matchingJob = allImportedJobListings?.find((job) => job.url === value || job.title === value)
    if (matchingJob) {
      setValue('title', matchingJob.title)
      setValue('description', matchingJob.content)
      setFormView(FormView.START)
      setJobToImportFromLink((prev) => ({
        ...prev,
        isError: false
      }))
    } else {
      // Reset description
      setValue('description', '')
      setJobToImportFromLink((prev) => ({
        ...prev,
        isError: true
      }))
    }
  }, [allImportedJobListings, setValue])

  useEffect(() => {
    if (formData?.title && REGEX_URL.test(String(formData.title))) {
      setJobToImportFromLink(prev => ({
        ...prev,
        jobPostUrl: String(formData.title),
        isError: false
      }))
    }
  }, [formData.title])

  // This hook does the main preparation work
  // * Prepares automatically imported jobs to be searchable in Combobox
  // * Updates the state when user imports a single job from a job board link (e.g. Greenhouse)
  //   so we then can let the backend extract the relevant information from it
  useEffect(() => {
    if (importedJobs) {
      if (!isNil(jobToImportFromLink?.jobPostUrl)) {
        const foundListing = importedJobs?.[0]
        if (foundListing) {
          setValue('title', foundListing.title)
          setJobToImportFromLink((prev) => ({
            ...prev,
            isError: false,
            importedJob: foundListing,
            jobPostUrl: null
          }))
        }
      } else {
        setIsLoadingJobsForSelectedDepartment(true)
        setAllImportedJobListings(importedJobs)

        const selectedDepartment =
          departments?.find((department) => department.id === formData?.departmentId)?.domain ??
          org?.domain

        const selectedDepartmentName = departments?.find((department) => department.id === formData?.departmentId)?.name ?? org?.name

        const jobsByName = importedJobs
          .filter((job) => job.domain === selectedDepartment)
          .reduce<Record<string, JobBoardListing[]>>((acc, job) => {
          const name = job.name ?? ''
          acc[name] = acc[name] ? [...acc[name], job] : [job]
          return acc
        }, {})

        const comboboxOptions: ComboboxOption[] = Object.values(jobsByName).flatMap(
          (jobList: JobBoardListing[]) =>
            jobList.map((job) => {
              const value = job.url && job.url.trim() !== '' ? job.url : job.title
              return {
                value,
                name: job.title,
                nameContext: job.location,
                trailingAction: (
                  <Button
                    nested
                    $variant="ghost"
                    $colorTheme="tint"
                    $height={18}
                    $width={50}
                    $align="center"
                    $fontSize={12}
                    onClick={(event) => {
                      event.stopPropagation()
                      void handlePopulateFormFromImportedJobListing(value)
                    }}
                  >
                    Import
                  </Button>
                )
              }
            })
        )
        // We want to store this to not being replaced when a user
        // enters a url to import a job from, otherwise the Combobox
        // would then be empty.
        setImportedJobsByDepartment([
          ...(comboboxOptions.length > 0
            ? [{
                type: 'label' as const,
                name: `${selectedDepartmentName}'s ${pluralize(comboboxOptions.length, 'Position', { hideCount: true })}`,
                value: 'combobox-label'
              }]
            : []),
          ...comboboxOptions.slice(0, 300)
        ])
      }
    }
  }, [importedJobs, departments, jobToImportFromLink?.jobPostUrl, formData?.departmentId, org?.domain, setValue, handlePopulateFormFromImportedJobListing, org?.name])

  useEffect(() => {
    if (isNil(importedJobs) && importedJobsError) {
      setJobToImportFromLink(prev => ({
        ...prev,
        isError: true,
        isImporting: false,
        jobPostUrl: null,
        importedJob: null
      }))
      setFormView(FormView.IMPORT_FROM_LINK)
    }
  }, [importedJobs, importedJobsError])

  useEffect(() => {
    if (!isNil(importedJobsByDepartment) && importedJobsByDepartment.length > 0) {
      setTimeout(() => {
        setIsLoadingJobsForSelectedDepartment(false)
      }, 100)
    }
  }, [importedJobsByDepartment])

  const departmentItems = useMemo((): SelectItem[] => {
    if (isNil(org)) {
      return []
    }

    const orgListItem = {
      value: ORG_SELECT_VALUE,
      title: `${org.name} • ${org.domain}`,
      image: (
        <Avatar $type="logo" $size={16} $border={false} photoUrl={orgLogoUrl} initials={org.name} />
      )
    }
    const deptsListItems =
      departments
        ?.filter((department) => !department.deleted)
        .map((dept) => ({
          value: dept.id,
          title: `${dept.name} • ${dept.domain}`,
          image: <DepartmentLogo $size={16} departmentId={dept.id} />
        })) ?? []

    return [orgListItem, ...deptsListItems]
  }, [org, departments, orgLogoUrl])

  const handleCreateJob = useCallback(async (formData: NewJob): Promise<void> => {
    setIsSubmitting(true)
    const newJob = {
      ...formData,
      departmentId: formData.departmentId === ORG_SELECT_VALUE ? null : formData.departmentId,
      projectId: formData.projectId ?? null,
      title: formData.title ?? '',
      diversitySearch: false,
      locations: formData?.locations,
      jobBoardDescription: jobToImportFromLink?.importedJob?.content ?? '',
      workspace: formData?.workspace
    }
    createNewJob({
      job: newJob,
      onSuccess: (createdJob) => {
        let redirectRoute = RouteBuilder.build('JOBS_CANDIDATES', { jobId: createdJob.id })
        if (redirectType === 'onboarding') {
          redirectRoute = nextOnboardingStep?.href ?? RouteBuilder.build('ONBOARDING_CONNECTED_ACCOUNTS')
        }
        closeDialog(DialogId.CREATE_NEW_JOB)
        navigate(redirectRoute)
        setIsSubmitting(false)
        onCreateJob?.()
      }
    })
  }, [jobToImportFromLink?.importedJob?.content, createNewJob, redirectType, closeDialog, navigate, onCreateJob, nextOnboardingStep?.href])

  useEffect(() => {
    if (formView === 'IMPORT_FROM_LINK') {
      setTimeout(() => {
        if (importLinkInputEl.current) {
          importLinkInputEl.current.focus()
        }
      }, 20)
    }
  }, [formView])

  const handleImportJobFromLink = useCallback(async (): Promise<void> => {
    if (importLinkInputEl.current) {
      setValue('description', '')
      setValue('title', importLinkInputEl.current.value)
      setFormView(FormView.GENERATING)
      setJobToImportFromLink((prev) => ({
        ...prev,
        isImporting: true,
        jobPostUrl: importLinkInputEl?.current?.value ?? null
      }))
    }
  }, [importLinkInputEl, setValue])

  // Called when a user pastes a url into the description / import field
  useEffect(() => {
    const prepareJobDataFromLink = async (): Promise<void> => {
      if (jobToImportFromLink.importedJob) {
        setValue('title', jobToImportFromLink.importedJob.title)
        setValue('description', jobToImportFromLink.importedJob.content)
        setFormView(FormView.START)
        setJobToImportFromLink((prev) => ({
          ...prev,
          isImporting: false
        }))
      }
    }
    void prepareJobDataFromLink()
  }, [jobToImportFromLink.importedJob, setValue])

  const showComboboxWithImportedJobs = useMemo(
    () => (importedJobsByDepartment?.length ?? 0) >= 1 && !jobToImportFromLink.isImporting,
    [importedJobsByDepartment, jobToImportFromLink.isImporting]
  )

  useEffect(() => {
    if (projects) {
      const newestProject = [...projects]
        .sort((a, b) => new Date(b.createdAt ?? 0).getTime() - new Date(a.createdAt ?? 0).getTime())[0]

      if (newestProject && new Date(newestProject.createdAt ?? '') > subMinutes(new Date(), 3)) {
        setTimeout(() => {
          setValue('projectId', newestProject.id)
        }, 100)
      }
    }
  }, [projects, setValue])

  const projectSelectBoxItems = useMemo((): SelectItem[] => {
    if (!projects) {
      return []
    }
    return projects
      .filter(project => {
        if (project.deleted) {
          return false
        }
        if (formData.departmentId === ORG_SELECT_VALUE) {
          // If org is selected
          return isNil(project.departmentId) && project.orgId === org?.id
        } else {
          // If a department is selected
          return !isNil(project.departmentId) && project.departmentId === formData.departmentId
        }
      })
      .map((project) => {
        return {
          value: project.id,
          title: project.name
        }
      })
  }, [projects, formData.departmentId, org?.id])

  return (
    <S.Wrapper>
      <S.Inner>
        <Form onSubmit={submit(handleCreateJob)}>
          <Flex $align="center" $gap={24}>
            <Select
              name="departmentId"
              label="Hiring for"
              placeholder="Select an organization or department"
              tooltip="Specify the company for better search results. The company's name can be hidden when creating a company."
              defaultValue={formData.departmentId ? String(formData.departmentId) : undefined}
              items={departmentItems}
              $maxHeight={406}
              createItem={{
                value: 'new-company',
                title: 'New Company',
                onClick: () => {
                  openDialog({
                    id: DialogId.CREATE_DEPARTMENT,
                    payload: {
                      setDepartmentId: (deptId: string): void => {
                        setValue('departmentId', deptId)
                      }
                    }
                  })
                }
              }}
              createItemIsSticky={departmentItems?.length >= 8}
              register={register}
              $marginBottom={32}
              onValueChange={(value) => {
                setValue('departmentId', value)
                setValue('projectId', null)
              }}
            />
            {
              featureFlags?.includes(FeatureFlags.PROJECTS) && (
                <Select
                  name="projectId"
                  label="Project"
                  placeholder="Optional"
                  tooltip="Create projects to organize your job positions internally by team or department"
                  items={projectSelectBoxItems}
                  createItem={{
                    value: 'new-project',
                    title: 'New Project',
                    onClick: () => {
                      openDialog({
                        id: DialogId.CREATE_PROJECT,
                        payload: formData?.departmentId === ORG_SELECT_VALUE ? undefined : formData?.departmentId
                      })
                    }
                  }}
                  onReset={() => { setValue('projectId', null) }}
                  showIcons={false}
                  emptyStateText="You don't have any projects for this department yet"
                  $marginBottom={32}
                  register={register}
                />
              )
            }
          </Flex>
          <S.JobTitleHeader>
            <Caption size="SM" $color="fgSecondary">
              {formView === FormView.IMPORT_FROM_LINK ? 'Job Listing Link' : 'Position Title'}
            </Caption>
            <When condition={formView === FormView.START}>
              <Button
                $variant="ghost"
                $colorTheme="tint"
                $height={24}
                $fontSize={12}
                leadingIcon="link"
                onClick={() => {
                  setFormView(FormView.IMPORT_FROM_LINK)
                }}
              >
                Import from link
              </Button>
            </When>
          </S.JobTitleHeader>
          <When condition={formView === FormView.IMPORT_FROM_LINK || jobToImportFromLink.isImporting}>
            <S.ImportJobInput $isLoading={false} $isDisabled={false} $isError={jobToImportFromLink?.isError}>
              <Icon name="link" size={14} color="fgSecondary" />
              <input
                ref={importLinkInputEl}
                disabled={jobToImportFromLink.isImporting}
                // placeholder="Link from a job posting listing"
                placeholder="https://"
                onFocus={() => {
                  setJobToImportFromLink(prev => ({
                    ...prev,
                    inputIsFocused: true
                  }))
                }}
                onBlur={() => {
                  setJobToImportFromLink(prev => ({
                    ...prev,
                    inputIsFocused: false
                  }))
                }}
              />
              <Flex $gap={4} $align="center" $justify="flex-end" $width="auto">
                <Button
                  $variant="flat"
                  $colorTheme="muted"
                  $height={28}
                  $fontSize={12}
                  onClick={() => {
                    setFormView(FormView.START)
                    setValue('title', '')
                    setJobToImportFromLink((prev) => ({
                      ...prev,
                      isError: false,
                      isImporting: false
                    }))
                  }}
                >
                  Cancel
                </Button>
                <Button
                  $variant="raised"
                  $colorTheme="tint"
                  $height={28}
                  $width={52}
                  $fontSize={12}
                  onClick={handleImportJobFromLink}
                  loading={jobToImportFromLink.isImporting}
                  disabled={jobToImportFromLink.isImporting}
                  $align="center"
                >
                  {jobToImportFromLink.isImporting ? <Spinner /> : 'Import'}
                </Button>
              </Flex>
            </S.ImportJobInput>
          </When>
          <When condition={(formView === FormView.START) && !showComboboxWithImportedJobs}>
            <Input
              name="title"
              placeholder="e.g. Software Engineer"
              label="Job title"
              hiddenLabel
              register={register}
              $marginBottom={jobToImportFromLink?.isError ? 0 : 32}
            />
          </When>
          <When condition={(formView === FormView.START || formView === FormView.GENERATING) && showComboboxWithImportedJobs}>
            <Combobox
              name="title"
              acceptInputAsValue
              label="Job title"
              hiddenLabel
              placeholder="e.g. Software Engineer"
              defaultValue={formData?.title as string[]}
              options={importedJobsByDepartment ?? []}
              register={register}
              onOptionSelect={(value: string) => {
                void handlePopulateFormFromImportedJobListing(value)
              }}
              $marginBottom={jobToImportFromLink?.isError ? 0 : 32}
              $itemHeight={32}
              isLoading={isLoadingJobsForSelectedDepartment || formView === FormView.GENERATING}
            />
          </When>
          <When condition={jobToImportFromLink?.isError && !jobToImportFromLink?.isImporting}>
            <FieldError>Unable to import job listing. Please check if the link is correct and try again.</FieldError>
            <Spacer $size={32} />
          </When>
          <S.DescriptionFieldLabel $isDisabled={jobToImportFromLink.inputIsFocused}>
            <FieldLabel label="Candidate requirements or Job description" />
          </S.DescriptionFieldLabel>
          <S.DescriptionField $isDisabled={jobToImportFromLink.inputIsFocused}>
            <Textarea
              rows={14}
              label="Candidate requirements or Job description"
              hiddenLabel
              name="description"
              placeholder="Paste your full job posting here. Include specific requirements like location, technical skills, years of experience, etc. The more detailed and objective your requirements, the better Pin can match candidates."
              register={register}
              $marginBottom={0}
              isDisabled={formView === FormView.GENERATING || jobToImportFromLink.inputIsFocused}
            />
          </S.DescriptionField>
          <Spacer $size={48} />
          <Flex $align="center" $gap={8}>
            <Button
              type="submit"
              $variant="fill"
              $colorTheme="tint"
              $height={40}
              loading={isSubmitting}
              disabled={!isFormValid || isSubmitting || jobToImportFromLink.isError || userHasViewerRole}
            >
              Create Job Position
            </Button>
            <Button
              type="button"
              $variant="outline"
              $colorTheme="muted"
              $height={40}
              onClick={() => {
                setFormView(FormView.START)
                setJobToImportFromLink({
                  isImporting: false,
                  isError: false,
                  inputIsFocused: false,
                  importedJob: null,
                  jobPostUrl: null
                })
                reset()
              }}
            >
              Reset
            </Button>
          </Flex>
          <Spacer $size={48} />
        </Form>
      </S.Inner>
    </S.Wrapper>
  )
}
